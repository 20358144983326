export interface TagSelectOption {
  value: string;
  label: string;
}

export interface TagBase {
  id: string;
  label: string;
}

export const convertTagToSelectOption = (tag: TagBase): TagSelectOption => ({
  value: tag.id,
  label: tag.label,
});
