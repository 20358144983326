import { useAirModal } from '@air/provider-modal';

import { RemoveAssetsFromBoardModal } from '~/components/Modals/RemoveClipsFromBoardModal/RemoveAssetsFromBoardModal';

export const useRemoveAssetsFromBoardModal = () => {
  const [showRemoveAssetsFromBoardModal] = useAirModal(RemoveAssetsFromBoardModal);

  return {
    showRemoveAssetsFromBoardModal,
  };
};
