import { dropdownMenuItem } from '@air/primitive-dropdown-menu/src/DropdownMenuItem';
import { Switch } from '@air/primitive-switch';
import { memo } from 'react';

import { VISIBILITY_TOGGLE_HEADER, VISIBILITY_TOGGLE_SWITCH } from '~/constants/testIDs';

interface ViewVisibilityHeaderToggleProps {
  id: string;
  label: string;
  onToggle: () => void;
  isToggleOn: boolean;
}
export const ViewVisibilityHeaderToggle = memo(
  ({ id, label, onToggle, isToggleOn }: ViewVisibilityHeaderToggleProps) => {
    return (
      <button
        className={dropdownMenuItem({ size: 'small', className: 'w-full' })}
        data-testid={`${VISIBILITY_TOGGLE_HEADER}-${id}`}
        data-id={id}
        onClick={onToggle}
        type="button"
      >
        <div className="flex min-w-0 grow truncate text-14">{label}</div>
        <div className="ml-2 shrink-0">
          <Switch
            aria-readonly
            checked={isToggleOn}
            data-id={id}
            data-testid={VISIBILITY_TOGGLE_SWITCH}
            id={id}
            name={id}
          />
        </div>
      </button>
    );
  },
);

ViewVisibilityHeaderToggle.displayName = 'ViewVisibilityHeaderToggle';
