import { Clip } from '@air/api/types';
import React, { memo } from 'react';

import { ClipThumbnail } from '~/components/Shared/ClipList/ClipItem/ClipThumbail';
import { ClipIconSize } from '~/types/clip-icon-size';

export interface AssetCellThumbnailProps
  extends Pick<Clip, 'status' | 'ext' | 'type' | 'assets' | 'title' | 'importedName'> {
  description: string;
}

export const AssetCellThumbnail = memo(({ type, ext, status, assets, description }: AssetCellThumbnailProps) => {
  return (
    <ClipThumbnail
      ext={ext}
      status={status}
      type={type}
      borderRadius={2}
      description={description}
      src={assets.image}
      iconSize={ClipIconSize.small}
      hideText={true}
      style={{
        padding: 0,
      }}
    />
  );
});

AssetCellThumbnail.displayName = 'AssetCellThumbnail';
