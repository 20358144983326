import { Tags } from '@air/api';
import { SortByOption as TagsSortByOption } from '@air/api/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { getPrivateTagsKey } from '~/constants/react-query-keys';
import { useFetchAnyWorkspacePermissions } from '~/hooks/useFetchAnyWorkspacePermissions';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { canSeeWorkspaceTags } from '~/utils/permissions/workspacePermissions';

export const usePrivateTags = ({
  search,
  sortBy = 'label',
  limit = 10,
  isTagsSelect = false,
}: {
  isTagsSelect?: boolean;
  search: string;
  sortBy?: TagsSortByOption;
  limit?: number;
}) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const { data: workspacePermissions } = useFetchAnyWorkspacePermissions();
  const canSeeTags = canSeeWorkspaceTags(workspacePermissions);

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
    queryKey: getPrivateTagsKey({
      workspaceId,
      isTagsSelect,
      sortBy,
      search,
    }),
    queryFn: ({ pageParam }) => {
      return Tags.list({
        workspaceId: workspaceId!,
        options: {
          cursor: pageParam || undefined,
          limit,
          sortBy,
          search,
        },
      });
    },
    enabled: !!workspaceId && canSeeTags,
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor || null,
    initialPageParam: '',
  });

  const loadNextPage = useCallback(() => {
    if (!!data && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [data, fetchNextPage, hasNextPage, isFetchingNextPage]);

  return {
    data: data?.pages.flatMap((items) => items.data.tags),
    hasMore: !!hasNextPage,
    loadNextPage,
    isLoadingMore: isFetchingNextPage,
    isLoading,
  };
};
