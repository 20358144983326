import { tailwindMerge } from '@air/tailwind-variants';
import React, { ComponentPropsWithoutRef, memo } from 'react';
import isEqual from 'react-fast-compare';

import { getMinTableContentWidth } from '~/components/TableView/types';
import { activeTableColumnsSelector } from '~/store/configViews/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export type TableViewRowProps = ComponentPropsWithoutRef<'div'>;

export const TABLE_ROW_LEFT_PADDING = 16;

export const DesktopTableRowContainer = memo(({ className, style, ...restProps }: TableViewRowProps) => {
  const activeTableColumns = useAirSelector(activeTableColumnsSelector);

  return (
    <div
      className={tailwindMerge(
        'flex-1 [&_.table-cell]:px-4 [&_.table-cell]:py-2 [&_.tableRow]:-mr-4 [&_.tableRow]:rounded',
        className,
      )}
      style={{
        minWidth: getMinTableContentWidth(activeTableColumns),
        marginLeft: `-${TABLE_ROW_LEFT_PADDING}px`,
        ...style,
      }}
      {...restProps}
    />
  );
}, isEqual);

DesktopTableRowContainer.displayName = 'TableViewRow';
