import classNames from 'classnames';
import { memo } from 'react';

import { CustomFieldDisplay } from '~/components/CustomFieldDisplay';
import { AssignedCustomFieldValue } from '~/swr-hooks/customFields/types';

interface CustomFieldsDisplay {
  className?: React.ComponentProps<'button'>['className'];
  style?: React.CSSProperties;
  customFields: AssignedCustomFieldValue[];
  onClick?: () => void;
  shouldShowLabelInline?: boolean;
}
export const CustomFieldsDisplay = memo(
  ({ className, customFields, onClick, shouldShowLabelInline, style }: CustomFieldsDisplay) => {
    return (
      <button
        data-testid="CUSTOM_FIELDS_INDICATOR"
        className={classNames('pointer-events-auto max-w-full text-left hover:bg-transparent', className)}
        disabled={!onClick}
        onClick={onClick}
        style={style}
      >
        {customFields.map((cfValue) => (
          <CustomFieldDisplay shouldShowLabelInline={shouldShowLabelInline} key={cfValue.id} cfValue={cfValue} />
        ))}
      </button>
    );
  },
);

CustomFieldsDisplay.displayName = 'CustomFieldsDisplay';
