import { memo, ReactNode, useCallback } from 'react';
import { useToggle } from 'react-use';

import { ViewVisibilityHeaderToggle } from '../shared/ViewVisibilityHeaderToggle';

interface ViewVisibilitySectionProps {
  toggles: ReactNode;
  onToggleShowingOptions: (nextIsFieldVisible: boolean) => void;
  id: string;
  label: string;
  initialShowOptions: boolean;
}
export const ViewVisibilitySection = memo(
  ({ toggles, onToggleShowingOptions, id, label, initialShowOptions }: ViewVisibilitySectionProps) => {
    const [showOptions, setShowOptions] = useToggle(initialShowOptions);

    const onToggle = useCallback(() => {
      onToggleShowingOptions(!showOptions);
      setShowOptions(!showOptions);
    }, [showOptions, setShowOptions, onToggleShowingOptions]);

    return (
      <>
        <ViewVisibilityHeaderToggle id={id} label={label} onToggle={onToggle} isToggleOn={showOptions} />
        {showOptions && (
          <>
            <div className="my-2 h-px w-full bg-grey-5" />
            <div className="flex flex-col gap-1">{toggles}</div>
          </>
        )}
      </>
    );
  },
);

ViewVisibilitySection.displayName = 'ViewVisibilitySection';
