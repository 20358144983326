import { Search } from '@air/next-icons';
import { ComboboxOptionText } from '@reach/combobox';

import { SearchSuggestionContainer } from '~/components/SearchBar/Suggestion/SearchSuggestionContainer';

interface SearchSuggestionProps {
  value: string;
  onClick: (text: string) => void;
  index: number;
}

export const TextSuggestion = ({ value, onClick, index }: SearchSuggestionProps) => (
  <SearchSuggestionContainer className="px-1 py-1.5" index={index} value={value} onClick={() => onClick(value)}>
    <Search className="mr-3 h-5 w-4 text-grey-9 [&>path]:stroke-2" />
    <div className="truncate">
      <ComboboxOptionText />
    </div>
  </SearchSuggestionContainer>
);
