import { Clips } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const getPrivateClipMetadata = (clipId: string) => ['PRIVATE_CLIP_METADATA', { clipId }];

export const usePrivateClipMetadata = ({ clipId }: { clipId: string }) => {
  const { currentWorkspace } = useCurrentWorkspace();

  return useQuery({
    queryKey: getPrivateClipMetadata(clipId),

    queryFn: () => {
      invariant(currentWorkspace, 'currentWorkspace is not defined');

      // This request is paginated, but there are only 55 supported fields (state for 2/23/24);
      // Asset modal presents some fields on info panel - with pagination we may not have them in the first page
      return Clips.listMetadata({
        clipId,
        workspaceId: currentWorkspace.id,
        limit: 100,
      });
    },
    enabled: !!currentWorkspace && !!clipId,
    staleTime: Infinity,
  });
};
