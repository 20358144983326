import { memo } from 'react';

import { BulkEditDateValueCustomFieldModal } from '~/components/Modals/BulkEditCustomFieldModal/BulkEditDateValueCustomFieldModal';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

import { BulkEditCustomFieldModalProps } from './types';

export type PublicBulkEditDateValueCustomFieldModalProps = Omit<BulkEditCustomFieldModalProps, 'onSave'>;

export const PublicBulkEditDateValueCustomFieldModal = memo(
  (props: AirModalProps<PublicBulkEditDateValueCustomFieldModalProps>) => {
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

    return (
      <BulkEditDateValueCustomFieldModal
        {...props}
        onSave={async (params) => {
          await updateItemsCustomFieldValue({
            ...params,
            trackingLocation: 'context-menu-bulk-edit',
            workspaceId: undefined,
          });
        }}
      />
    );
  },
);
PublicBulkEditDateValueCustomFieldModal.displayName = 'PublicBulkEditDateValueCustomFieldModal';
