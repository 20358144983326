import { ScopedPermissions } from '@air/api';
import { createContext, memo, ReactNode, useContext, useMemo } from 'react';

type PublicPermissionsProviderContextValue =
  | {
      permissions: ScopedPermissions;
    }
  | undefined;

const PublicPermissionsProviderContext = createContext<PublicPermissionsProviderContextValue>(undefined);

export type PublicPermissionsProviderProps = PublicPermissionsProviderContextValue & {
  children: ReactNode;
  permissions: ScopedPermissions;
};

export const PublicPermissionsProvider = memo(({ children, permissions }: PublicPermissionsProviderProps) => {
  const value = useMemo(() => ({ permissions }), [permissions]);

  return (
    <PublicPermissionsProviderContext.Provider value={value}>{children}</PublicPermissionsProviderContext.Provider>
  );
});

PublicPermissionsProvider.displayName = 'PublicPermissionsProvider';

export function usePublicPermissions() {
  const context = useContext(PublicPermissionsProviderContext);

  if (!context) {
    throw 'PublicPermissionsProviderContext used outside of PublicPermissionsProvider';
  }

  return context;
}
