import {
  AirActionTrackingLocation,
  useTrackChangedCustomFieldDateValue,
  useTrackChangedCustomFieldPlainTextValue,
  useTrackChangedCustomFieldValue,
  useTrackChangedCustomFieldValues,
} from '@air/analytics';
import {
  AssetCustomFieldValueListResponse,
  Assets,
  BoardCustomFieldValueListResponse,
  Boards,
  ShortUrl,
} from '@air/api';
import { Board, Clip, CustomFieldTypeName } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { useCallback } from 'react';

import { getAssetCustomFieldsKey, getPrivateBoardCustomFieldsKey } from '~/constants/react-query-keys';
import {
  updateBoardCustomFieldsWithValue,
  updateClipCustomFieldsWithValue,
  UpdateItemCustomFieldValueParams,
} from '~/utils/CustomFields';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import {
  useRemoveAssetsWithoutCFFromFilteredViews,
  useUpdateAssetsInAllViews,
} from '~/utils/mutateUtils/GalleryAssets';
import { useUpdateBoardsInAllViews } from '~/utils/mutateUtils/GalleryBoards';
import { getBoardIdFromPath, getShortIdFromPath } from '~/utils/PathUtils';

export type UpdateItemsCustomFieldValueParams = UpdateItemCustomFieldValueParams & {
  boardIds?: Board['id'][];
  assetIds?: Clip['assetId'][];
  trackingLocation: AirActionTrackingLocation;
  workspaceId: string | undefined;
};

export const useUpdateItemsCustomFieldValue = () => {
  const { updateBoardsInAllViews } = useUpdateBoardsInAllViews();
  const { updateAssetsInAllViews } = useUpdateAssetsInAllViews();
  const { removeAssetsWithoutCFFromFilteredViews } = useRemoveAssetsWithoutCFFromFilteredViews();
  const { showToast } = useToasts();
  const queryClient = useQueryClient();
  const { trackChangedCustomFieldDateValue } = useTrackChangedCustomFieldDateValue();
  const { trackChangedCustomFieldPlainTextValue } = useTrackChangedCustomFieldPlainTextValue();
  const { trackChangedCustomFieldValue } = useTrackChangedCustomFieldValue();
  const { trackChangedCustomFieldValues } = useTrackChangedCustomFieldValues();

  const updateItemsCustomFieldValue = useCallback(
    async ({
      boardIds,
      assetIds,
      baseCustomField,
      nextDateValue,
      nextValue,
      nextValues,
      multiValueIdsToRemove,
      multiValuesToAdd,
      nextPlainTextValue,
      trackingLocation,
      workspaceId,
    }: UpdateItemsCustomFieldValueParams) => {
      const centralizedBoardId = getBoardIdFromPath(window.location.pathname);
      const shortId = getShortIdFromPath(window.location.pathname);
      const isOnPublicRoute = !!shortId;

      const clipCustomFieldsMutation =
        (assetId: Clip['assetId']) =>
        (
          currentClipCustomFieldsResponse?: AssetCustomFieldValueListResponse,
        ): AssetCustomFieldValueListResponse | undefined => {
          if (!currentClipCustomFieldsResponse) return undefined;
          const nextClipCustomFieldResponse = produce(currentClipCustomFieldsResponse, (draft) => {
            const nextCustomFields =
              updateClipCustomFieldsWithValue({
                assetId,
                customFieldValues: currentClipCustomFieldsResponse.data,
                baseCustomField,
                nextValue,
                nextValues,
                multiValueIdsToRemove,
                multiValuesToAdd,
                nextPlainTextValue,
                nextDateValue,
              }) || [];
            draft.data = nextCustomFields;
            draft.total = nextCustomFields.length;
          });
          return nextClipCustomFieldResponse;
        };

      const invalidatedKeys: QueryKey[] = [];
      assetIds?.forEach((assetId) => {
        const assetCustomFieldsKey = getAssetCustomFieldsKey(assetId);
        invalidatedKeys.push(assetCustomFieldsKey);
        // optimistically update individual asset custom field values response
        queryClient.setQueryData<AssetCustomFieldValueListResponse | undefined>(
          assetCustomFieldsKey,
          clipCustomFieldsMutation(assetId),
        );
      });
      boardIds?.forEach((boardId) => {
        const boardCustomFieldsKey = getPrivateBoardCustomFieldsKey(boardId);
        invalidatedKeys.push(boardCustomFieldsKey);
        // optimistically update individual board custom field values response

        queryClient.setQueryData<BoardCustomFieldValueListResponse | undefined>(
          boardCustomFieldsKey,
          (boardCustomFields) => {
            if (!boardCustomFields) return undefined;
            const nextClipCustomFieldResponse = produce(boardCustomFields, (draft) => {
              const nextCustomFields =
                updateBoardCustomFieldsWithValue({
                  boardId: boardId,
                  customFieldValues: boardCustomFields.data,
                  baseCustomField,
                  nextValue,
                  nextValues,
                  multiValueIdsToRemove,
                  multiValuesToAdd,
                  nextPlainTextValue,
                  nextDateValue,
                }) || [];
              draft.data = nextCustomFields;
              draft.total = nextCustomFields.length;
            });
            return nextClipCustomFieldResponse;
          },
        );
      });
      const updateClipCustomFieldsFn = (clip: Clip): Clip =>
        produce(clip, (draft) => {
          draft.customFields = updateClipCustomFieldsWithValue({
            assetId: clip.assetId,
            customFieldValues: clip.customFields,
            baseCustomField,
            nextValue,
            nextValues,
            multiValueIdsToRemove,
            multiValuesToAdd,
            nextPlainTextValue,
            nextDateValue,
          });
        });

      if (assetIds?.length) {
        await updateAssetsInAllViews({
          assetIdentifiers: assetIds,
          boardId: centralizedBoardId,
          updateFn: updateClipCustomFieldsFn,
        });

        removeAssetsWithoutCFFromFilteredViews({
          assetIds,
          customFieldId: baseCustomField.id,
        });
      }

      const updateBoardCustomFieldsFn = (board: Board): Board =>
        produce(board, (draft) => {
          draft.customFields = updateBoardCustomFieldsWithValue({
            boardId: board.id,
            customFieldValues: board.customFields,
            baseCustomField,
            nextValue,
            nextValues,
            multiValueIdsToRemove,
            multiValuesToAdd,
            nextPlainTextValue,
            nextDateValue,
          });
        });
      if (boardIds?.length) {
        await updateBoardsInAllViews({
          boardIds,
          parentBoardId: centralizedBoardId,
          updateFn: updateBoardCustomFieldsFn,
        });
      }

      let multiUpdatedIds: string[] = [];

      try {
        if (baseCustomField.type.title === CustomFieldTypeName.multiSelect) {
          multiUpdatedIds =
            (multiValuesToAdd?.length && multiValuesToAdd?.map((i) => i.id)) || multiValueIdsToRemove || [];
          if (isOnPublicRoute) {
            if (shortId) {
              if (assetIds?.length) {
                await ShortUrl.setAssetCustomFieldValues({
                  shortId,
                  customFieldId: baseCustomField.id,
                  assetIds,
                  valueIdsToAdd: multiValuesToAdd?.length ? multiUpdatedIds : undefined,
                  valueIdsToRemove: !multiValuesToAdd?.length ? multiUpdatedIds : undefined,
                });
              }

              if (boardIds?.length) {
                await ShortUrl.setBoardCustomFieldValues({
                  shortId,
                  customFieldId: baseCustomField.id,
                  boardIds,
                  valueIdsToAdd: multiValuesToAdd?.length ? multiUpdatedIds : undefined,
                  valueIdsToRemove: !multiValuesToAdd?.length ? multiUpdatedIds : undefined,
                });
              }
            }
          } else {
            if (!workspaceId) {
              throw new Error('No workspaceId found');
            }

            if (assetIds?.length) {
              await Assets.setCustomFieldValues({
                customFieldId: baseCustomField.id,
                assetIds,
                valueIdsToAdd: multiValuesToAdd?.length ? multiUpdatedIds : undefined,
                valueIdsToRemove: !multiValuesToAdd?.length ? multiUpdatedIds : undefined,
                workspaceId,
              });
            }

            if (boardIds?.length) {
              await Boards.setCustomFieldValues({
                customFieldId: baseCustomField.id,
                boardIds,
                valueIdsToAdd: multiValuesToAdd?.length ? multiUpdatedIds : undefined,
                valueIdsToRemove: !multiValuesToAdd?.length ? multiUpdatedIds : undefined,
                workspaceId,
              });
            }
          }
        } else {
          if (isOnPublicRoute) {
            if (shortId) {
              if (assetIds?.length) {
                await ShortUrl.setAssetCustomFieldValue({
                  shortId,
                  customFieldId: baseCustomField.id,
                  assetIds,
                  dateValue: nextDateValue,
                  plainTextValue: nextPlainTextValue,
                  valueId: nextValue?.id,
                });
              }

              if (boardIds?.length) {
                await ShortUrl.setBoardCustomFieldValue({
                  shortId,
                  customFieldId: baseCustomField.id,
                  boardIds,
                  dateValue: nextDateValue,
                  plainTextValue: nextPlainTextValue,
                  valueId: nextValue?.id,
                });
              }
            }
          } else {
            if (!workspaceId) {
              throw new Error('No workspaceId found');
            }

            if (assetIds?.length) {
              await Assets.setCustomFieldValue({
                customFieldId: baseCustomField.id,
                assetIds,
                dateValue: nextDateValue,
                plainTextValue: nextPlainTextValue,
                valueId: nextValue?.id,
                workspaceId,
              });
            }

            if (boardIds?.length) {
              await Boards.setCustomFieldValue({
                customFieldId: baseCustomField.id,
                boardIds,
                dateValue: nextDateValue,
                plainTextValue: nextPlainTextValue,
                valueId: nextValue?.id,
                workspaceId,
              });
            }
          }
        }

        Promise.all(invalidatedKeys.map((key) => queryClient.invalidateQueries({ queryKey: key })));
        switch (baseCustomField.type.title) {
          case CustomFieldTypeName.date: {
            trackChangedCustomFieldDateValue({
              type: CustomFieldTypeName.date,
              location: trackingLocation,
              customFieldId: baseCustomField.id,
              dateValue: nextDateValue,
            });
            break;
          }
          case CustomFieldTypeName.plainText: {
            trackChangedCustomFieldPlainTextValue({
              type: CustomFieldTypeName.plainText,
              location: trackingLocation,
              customFieldId: baseCustomField.id,
            });
            break;
          }
          case CustomFieldTypeName.singleSelect: {
            trackChangedCustomFieldValue({
              location: trackingLocation,
              customFieldId: baseCustomField.id,
              valueId: nextValue?.id || null,
            });
            break;
          }
          case CustomFieldTypeName.multiSelect: {
            trackChangedCustomFieldValues({
              location: trackingLocation,
              customFieldId: baseCustomField.id,
              valueIds: multiUpdatedIds,
              actionType: multiValuesToAdd ? 'add' : 'remove',
            });
            break;
          }
        }
      } catch (error) {
        reportErrorToBugsnag({
          error,
          context: 'Setting custom field value(s)',
          metadata: {
            assetIds,
            boardIds,
            trackingLocation,
            multiValuesToAdd,
            multiValueIdsToRemove,
            nextValue,
            nextValues,
            nextPlainTextValue,
            nextDateValue,
            customFieldId: baseCustomField.id,
            boardId: centralizedBoardId,
          },
        });
        showToast('There was an error while saving custom fields. Please refresh the page fo corrected data.');
      }
    },
    [
      queryClient,
      updateAssetsInAllViews,
      removeAssetsWithoutCFFromFilteredViews,
      updateBoardsInAllViews,
      showToast,
      trackChangedCustomFieldDateValue,
      trackChangedCustomFieldPlainTextValue,
      trackChangedCustomFieldValue,
      trackChangedCustomFieldValues,
    ],
  );

  return {
    updateItemsCustomFieldValue,
  };
};
