import { AssetCustomFieldValue, BoardCustomFieldValue } from '@air/api/types';
import { EditableText, EditableTextProps } from '@air/zephyr-editable-text';
import { memo } from 'react';

import Linkify from '~/components/Linkify';
import { CUSTOM_FIELD_PLAIN_TEXT } from '~/constants/testIDs';

export interface CustomFieldPlainTextProps extends Omit<EditableTextProps, 'onSubmit' | 'label' | 'value'> {
  field: BoardCustomFieldValue | AssetCustomFieldValue;
  onBlur?: () => void;
  onSubmit: ((value: string) => void) | undefined;
}

export const CustomFieldPlainText = memo(({ field, onBlur, onSubmit, ...rest }: CustomFieldPlainTextProps) => {
  if (onSubmit) {
    return (
      <EditableText
        {...rest}
        data-testid={`${CUSTOM_FIELD_PLAIN_TEXT}-${field.name}`}
        formatValue={(value) => <Linkify>{value}</Linkify>}
        label={field.name}
        onEditingStateChange={(isEditing: boolean) => {
          if (!isEditing) {
            onBlur?.();
          }
        }}
        onReset={onBlur}
        onSubmit={(values) => {
          const plainTextValue = values['editable-text-value'];
          if (plainTextValue === field.plainTextValue) return;

          onSubmit(plainTextValue);
        }}
        placeholder="None"
        tx={{
          width: '100%',
          background: 'var(--colors-grey1)',
          EditableTextButton: {
            display: 'inline-block',
          },
        }}
        value={field.plainTextValue || ''}
        variant="text-ui-14"
      />
    );
  }
  return (
    <Linkify>
      <div
        data-testid="READONLY_CUSTOM_FIELD_PLAIN_TEXT"
        data-fieldname={field.name}
        className="whitespace-pre-wrap break-normal px-2 py-1 text-14 leading-normal text-grey-11"
      >
        {field.plainTextValue || 'None'}
      </div>
    </Linkify>
  );
});

CustomFieldPlainText.displayName = 'CustomFieldPlainText';
