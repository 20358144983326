import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UploadedDateFilter } from '~/components/Filters/DateFilter/UploadedDateFilter';
import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import {
  removeFilterAction,
  setUploadedDateFilterEndDateAction,
  setUploadedDateFilterStartDateAction,
  setUploadedDateFilterTypeAction,
} from '~/store/filters/actions';
import {
  uploadedDateFilterDateTypeSelector,
  uploadedDateFilterEndDateSelector,
  uploadedDateFilterStartDateSelector,
} from '~/store/filters/selectors';
import { DateRangeType, SelectedFilter } from '~/store/filters/types';

export type UploadedDateFilterCardProps = BaseFilterCardProps;

export const UploadedDateFilterCard = memo((props: UploadedDateFilterCardProps) => {
  const dispatch = useDispatch();
  const dateType = useSelector(uploadedDateFilterDateTypeSelector);
  const startDate = useSelector(uploadedDateFilterStartDateSelector);
  const endDate = useSelector(uploadedDateFilterEndDateSelector);

  const onDateTypeChange = useCallback(
    (type: DateRangeType) => {
      dispatch(setUploadedDateFilterTypeAction({ type }));
    },
    [dispatch],
  );

  const onStartDateChange = useCallback(
    (startDate?: Date) => {
      dispatch(setUploadedDateFilterStartDateAction({ startDate }));
    },
    [dispatch],
  );

  const onEndDateChange = useCallback(
    (endDate?: Date) => {
      dispatch(setUploadedDateFilterEndDateAction({ endDate }));
    },
    [dispatch],
  );

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
  };

  return (
    <FilterCard currentFilter="dateUploaded" onRemoveFilter={onRemoveFilter} {...props}>
      <UploadedDateFilter
        startDate={startDate}
        endDate={endDate}
        dateType={dateType}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
        onTypeChange={onDateTypeChange}
      />
    </FilterCard>
  );
});

UploadedDateFilterCard.displayName = 'UploadedDateFilterCard';
