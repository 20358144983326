import { memo } from 'react';

import { DateFilter } from '~/components/Filters/DateFilter/DateFilter';

import { CreatedDateFilterProps } from './CreatedDateFilter';

export type UploadedDateFilterProps = CreatedDateFilterProps;

export const UploadedDateFilter = memo(
  ({ startDate, endDate, dateType, onStartDateChange, onEndDateChange, onTypeChange }: UploadedDateFilterProps) => {
    return (
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        selectedType={dateType}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
        onTypeChange={onTypeChange}
      />
    );
  },
);

UploadedDateFilter.displayName = 'UploadedDateFilter';
