import { AirActionTrackingLocation } from '@air/analytics';
import { Clip } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { useToasts } from '@air/provider-toast';
import { differenceBy } from 'lodash';
import Link from 'next/link';
import pluralize from 'pluralize';
import React, { useState } from 'react';

import { PrivateTagsSelect } from '~/components/TagsSelect/PrivateTagsSelect';
import { TagBase } from '~/components/TagsSelect/utils';
import { Routes } from '~/constants/routes';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useAddTagsToClips } from '~/swr-hooks/tags/useAddTagsToClips';
import { useRemoveTagsFromClips } from '~/swr-hooks/tags/useRemoveTagsFromClips';
import { insertIf } from '~/utils/insertIf';
import {
  useRemoveAssetsWithoutTagsFromFilteredViews,
  useUpdateAssetsInAllViews,
} from '~/utils/mutateUtils/GalleryAssets';
import { getBoardIdFromPath } from '~/utils/PathUtils';

export interface EditClipsTagsModalProps {
  clipIds: Clip['id'][];
  trackLocation: AirActionTrackingLocation;
  initialTags: TagBase[];
}

export const EditClipsTagsModal = ({
  onClose,
  clipIds,
  trackLocation,
  initialTags,
}: AirModalProps<EditClipsTagsModalProps>) => {
  const { showToast } = useToasts();
  const { addTagsToClips } = useAddTagsToClips();
  const { removeTagsFromClips } = useRemoveTagsFromClips();
  const { updateAssetsInAllViews } = useUpdateAssetsInAllViews();
  const { removeAssetsWithoutTagsFromFilteredViews } = useRemoveAssetsWithoutTagsFromFilteredViews();
  const [saving, setSaving] = useState(false);
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });

  const [editedTags, setEditedTags] = useState<TagBase[]>(initialTags);

  const handleSubmit = async () => {
    setSaving(true);

    try {
      const tagsToAdd = differenceBy(editedTags, initialTags, 'id');
      const tagsToRemove = differenceBy(initialTags, editedTags, 'id');

      await Promise.all([
        ...insertIf(tagsToAdd.length > 0, () => [addTagsToClips({ tags: tagsToAdd, clipIds, trackLocation })]),
        ...insertIf(tagsToRemove.length > 0, () => [
          removeTagsFromClips({ tags: tagsToRemove, clipIds, trackLocation }),
        ]),
      ]);

      const tagsToRemoveIds = tagsToRemove.map(({ id }) => id);

      updateAssetsInAllViews({
        assetIdentifiers: clipIds,
        identifier: 'id',
        boardId: getBoardIdFromPath(window.location.pathname),
        updateFn: (clip) => {
          const filteredClipTags = clip.tags.filter(({ id }) => !tagsToRemoveIds.includes(id));
          clip.tags = [
            ...filteredClipTags,
            ...tagsToAdd
              .filter((tag) => !clip.tags.find((t) => t.id === tag.id))
              .map((tag) => ({ ...tag, isDemo: false })),
          ];
          return clip;
        },
      });

      removeAssetsWithoutTagsFromFilteredViews({
        clipIds,
        changedTags: [...tagsToAdd, ...tagsToRemove],
      });

      showToast(`Tags saved on ${pluralize('asset', clipIds.length, true)}`);
      onClose();
    } catch (_error) {
      setSaving(false);
      showToast('An error occurred - please try again later');
    }
  };

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  return (
    <Modal data-testid="EDIT_CLIPS_TAGS_MODAL" isOpen onDismiss={onClose}>
      <div className="flex flex-col gap-6">
        <header className="flex justify-between gap-4">
          <ModalTitle>Edit tags</ModalTitle>
          <ModalCloseButton className="shrink-0" onClick={onClose} />
        </header>
        <div className="text-16 text-grey-11">
          Add or remove tags from the {pluralize('item', clipIds.length)} you’ve selected. Manage your workspace’s tags
          in{' '}
          <Link href={Routes.workspaceAdmin.tags} target="_blank">
            settings
          </Link>
          .
        </div>
        <PrivateTagsSelect
          size="extra-large"
          selectedTags={editedTags}
          onSelectedTagsChanged={setEditedTags}
          canCreateNewTags
        />
        <footer className="flex justify-end gap-3">
          <Button
            data-testid="CANCEL_CLIPS_TAGS_CHANGE_BUTTON"
            appearance="ghost"
            color="grey"
            onClick={onClose}
            size="large"
          >
            Cancel
          </Button>
          <Button
            data-testid="SAVE_CLIPS_TAGS_BUTTON"
            onClick={handleSubmit}
            isLoading={saving}
            disabled={saving || editedTags.some(({ id }) => !id)}
            appearance="filled"
            color="blue"
            size="large"
          >
            Save
          </Button>
        </footer>
      </div>
    </Modal>
  );
};
