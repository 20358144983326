import { Board, SearchSavedFiltersResponse } from '@air/api/types';
import { forwardRef, memo, PropsWithChildren, useCallback } from 'react';

import { AssetsSearchSuggestions } from '~/components/AssetsSearchBar/AssetsSearchSuggestions';
import { RenderSuggestions, SearchBar, SearchBarHandle, SearchBarProps } from '~/components/SearchBar/SearchBar';
import { BoardSuggestionType } from '~/components/SearchBar/types';

interface AssetsSearchBarProps
  extends Omit<SearchBarProps, 'renderSuggestions' | 'onSuggestionEnterClick' | 'hasSuggestions'> {
  savedFilterSuggestions?: SearchSavedFiltersResponse[];
  onSavedFilterClick?: (savedFilter: SearchSavedFiltersResponse) => void;
  onBoardClick: (board: BoardSuggestionType) => void;
  textSuggestions?: string[];
  boardSuggestions?: Pick<Board, 'id' | 'title' | 'ancestors'>[];
}

const _AssetsSearchBar = forwardRef<SearchBarHandle, PropsWithChildren<AssetsSearchBarProps>>(
  (
    {
      boardSuggestions = [],
      textSuggestions = [],
      savedFilterSuggestions = [],
      onSavedFilterClick,
      onBoardClick,
      ...props
    }: PropsWithChildren<AssetsSearchBarProps>,
    forwardedRef,
  ) => {
    const renderSuggestions = useCallback<RenderSuggestions>(
      ({ applyTextSearch, onClick, value }) => (
        <AssetsSearchSuggestions
          onBoardClick={(board) => {
            onClick(board.title, 'board');
            onBoardClick(board);
          }}
          onSavedFilterClick={(savedFilter) => {
            onClick(savedFilter.name, 'savedFilter');
            onSavedFilterClick?.(savedFilter);
          }}
          onTextClick={(text) => {
            applyTextSearch(text);
          }}
          textSuggestions={textSuggestions}
          savedFilterSuggestions={savedFilterSuggestions}
          boardsSectionTitle={value ? 'Jump to' : 'Recent boards'}
          savedFiltersSectionTitle={value ? 'Jump to' : 'Recent saved filters'}
          boardSuggestions={boardSuggestions.slice(0, 5)}
        />
      ),
      [boardSuggestions, onBoardClick, onSavedFilterClick, savedFilterSuggestions, textSuggestions],
    );

    const handleSuggestionsEnterClick = useCallback<Required<SearchBarProps>['handleSuggestionEnterClick']>(
      (_text, suggestion) => {
        const boardId = suggestion?.dataset['boardId'];
        const savedFilterId = suggestion?.dataset['savedFilterId'];
        if (!!boardId) {
          const clickedBoard = boardSuggestions.find(({ id }) => id === boardId);
          if (clickedBoard) {
            onBoardClick(clickedBoard);
            return true;
          }
        } else if (!!savedFilterId) {
          const clickedSavedFilter = savedFilterSuggestions.find(({ id }) => id === savedFilterId);
          if (clickedSavedFilter) {
            onSavedFilterClick?.(clickedSavedFilter);
            return true;
          }
        }

        return false;
      },
      [boardSuggestions, onBoardClick, onSavedFilterClick, savedFilterSuggestions],
    );

    return (
      <SearchBar
        {...props}
        ref={forwardedRef}
        hasSuggestions={!!(textSuggestions.length || boardSuggestions.length || savedFilterSuggestions.length)}
        handleSuggestionEnterClick={handleSuggestionsEnterClick}
        renderSuggestions={renderSuggestions}
      />
    );
  },
);

_AssetsSearchBar.displayName = 'AssetsSearchBar';

export const AssetsSearchBar = memo(_AssetsSearchBar) as typeof _AssetsSearchBar;

AssetsSearchBar.displayName = 'AssetsSearchBar';
