import { memo } from 'react';

import { BulkEditDateValueCustomFieldModal } from '~/components/Modals/BulkEditCustomFieldModal/BulkEditDateValueCustomFieldModal';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

import { BulkEditCustomFieldModalProps } from './types';

export type PrivateBulkEditDateValueCustomFieldModalProps = Omit<BulkEditCustomFieldModalProps, 'onSave'>;

export const PrivateBulkEditDateValueCustomFieldModal = memo(
  (props: AirModalProps<PrivateBulkEditDateValueCustomFieldModalProps>) => {
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

    return (
      <BulkEditDateValueCustomFieldModal
        {...props}
        onSave={async (params) => {
          await updateItemsCustomFieldValue({
            ...params,
            trackingLocation: 'context-menu-bulk-edit',
            workspaceId,
          });
        }}
      />
    );
  },
);
PrivateBulkEditDateValueCustomFieldModal.displayName = 'PrivateBulkEditDateValueCustomFieldModal';
