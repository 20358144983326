import { useTrackHideField, useTrackShowField } from '@air/analytics';
import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';

import { setFieldsVisibilityAction, setSortingFieldAction } from '~/store/configViews/actions';
import { currentSortFieldNameSelector } from '~/store/configViews/selectors';
import { ConfigViewOption } from '~/store/configViews/types';
import { useAirStore } from '~/utils/ReduxUtils';

export const useToggleViewVisibilityMenuOptions = () => {
  const dispatch = useDispatch();
  const store = useAirStore();
  const { trackShowField } = useTrackShowField();
  const { trackHideField } = useTrackHideField();

  const toggleViewVisibilityMenuOptions = useCallback(
    (options: ConfigViewOption[], nextIsFieldVisible: boolean) => {
      const sort = currentSortFieldNameSelector(store.getState());

      batch(() => {
        if (options.some((opt) => opt.isVisible && opt.fieldName === sort)) {
          dispatch(setSortingFieldAction({ sortingFieldName: 'name' }));
        }
        dispatch(
          setFieldsVisibilityAction({
            fields: options.map((option) => ({ name: option.fieldName, customFieldId: option.customFieldId })),
            nextIsFieldVisible,
            defaultToSavedVisibility: true,
          }),
        );
      });

      const type = options[0]?.isCustomField ? 'custom-field-section' : 'meta-data-section';

      if (nextIsFieldVisible) {
        trackShowField({ type });
      } else {
        trackHideField({ type });
      }
    },
    [dispatch, store, trackHideField, trackShowField],
  );

  return {
    toggleViewVisibilityMenuOptions,
  };
};
