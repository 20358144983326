import { AirActionTrackingLocation } from '@air/analytics';
import { Clip, ShortUrlObjectType, ShortUrlResponse } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { formatFullName } from '@air/utilities';
import { memo, useCallback } from 'react';

import { SharePrivateClipModal } from '~/components/SharePrivateClipModal/SharePrivateClipModal';
import { SharePrivateClipRegenerate } from '~/components/SharePrivateClipModal/SharePrivateClipRegenerate';
import { EditableSharePrivateLink } from '~/components/SharePrivateContentModal/EditableSharePrivateLink';
import { useShowTurnOnShareLinkNotifications } from '~/hooks/turnOnEmailShareNotificationsToast/useShowTurnOnShareLinkNotifications';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';

interface EditableClipSharePrivateLinkProps {
  boardContext: string | undefined;
  clip: Pick<Clip, 'id' | 'assetVersionCount' | 'ext'>;
  searchParams?: URLSearchParams;
  shortIdInfo: ShortUrlResponse;
  trackLocation: AirActionTrackingLocation;
}

export const EditableClipSharePrivateLink = memo(
  ({ clip, trackLocation, shortIdInfo, boardContext, searchParams }: EditableClipSharePrivateLinkProps) => {
    const { showTurnOnShareLinkNotifications } = useShowTurnOnShareLinkNotifications();
    const [showRegenerateModal] = useAirModal(SharePrivateClipRegenerate);
    const [showShareClipModal, hideModal] = useAirModal(SharePrivateClipModal);
    const { members } = useWorkspaceMembers();
    const createdBy = members?.allMembers.find((member) => member.accountId === shortIdInfo.createdBy);

    const onRegenerateClick = useCallback(() => {
      hideModal();

      const showSharePrivateClipModal = () => {
        showShareClipModal({
          clip,
          boardContext,
          trackLocation,
        });
      };

      showRegenerateModal({
        clip,
        shortIdInfo,
        trackLocation,
        boardContext,
        onManageNotificationsClick: hideModal,
        onRegenerated: () => {
          showSharePrivateClipModal();
          showTurnOnShareLinkNotifications({
            onManageClick: hideModal,
          });
        },
        onCancel: showSharePrivateClipModal,
      });
    }, [
      boardContext,
      clip,
      hideModal,
      shortIdInfo,
      showRegenerateModal,
      showShareClipModal,
      showTurnOnShareLinkNotifications,
      trackLocation,
    ]);

    return (
      <EditableSharePrivateLink
        createdAt={shortIdInfo.createdAt}
        createdBy={createdBy && formatFullName(createdBy.firstName, createdBy.lastName)}
        objectId={clip.id}
        objectType={ShortUrlObjectType.clip}
        onRegenerate={onRegenerateClick}
        searchParams={searchParams}
        shareId={shortIdInfo.id}
        trackLocation={trackLocation}
      />
    );
  },
);

EditableClipSharePrivateLink.displayName = 'EditableClipSharePrivateLink';
