import { PublicClip } from '@air/api';
import { Clip, ClipStatus } from '@air/api/types';
import { NetworkStatusInfo } from '@air/classes-network-status-info';
import { convertUnknownToError, reportErrorToBugsnag } from '@air/utils-error';
import { lazy, memo, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AssetGalleryCardThumbnailImage } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCardThumbnailImage';
import { SelectedGalleryCardOverlay } from '~/components/Gallery/GalleryCard/SelectedGalleryCardOverlay';
import { getPreferenceClassName, useThumbnailPreference } from '~/hooks/useThumbnailPreference';
import { CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';
import { currentThumbnailPreferenceSelector } from '~/store/configViews/selectors';
import ClipUtils, { clearThumbnailCache } from '~/utils/ClipUtils';

const ClipPreview = lazy(async () => {
  try {
    const loadedModule = await import('~/components/Shared/ClipList/ClipItem/ClipPreview');
    return { default: loadedModule.ClipPreview };
  } catch (error) {
    if (NetworkStatusInfo.isOnline) {
      reportErrorToBugsnag({
        error: convertUnknownToError(error),
        context: 'Failed to lazy load ClipPreview',
      });
    }
    return Promise.resolve({ default: () => <div /> });
  }
});

export interface AssetGalleryCardThumbnailProps {
  clip: Clip | PublicClip;
  isHovering: boolean;
  isSelected: boolean;
  width: number;
  height: number;
}

const _AssetGalleryCardThumbnail = ({
  clip,
  isHovering,
  isSelected,
  width,
  height,
}: AssetGalleryCardThumbnailProps) => {
  const videoSrc = clip.assets.previewVideo;
  const playbackRate = ClipUtils.determinePlaybackRate(clip.duration ?? 0, videoSrc ?? '');
  const vertical = clip.status === ClipStatus.transcoded && !ClipUtils.isLandscape(clip.rotation);
  const { getPreference } = useThumbnailPreference();
  const boardId = useSelector(CentralizedBoardSelectors.boardId);
  const configViewThumbnailPreference = useSelector(currentThumbnailPreferenceSelector);

  const thumbnailPreference = useMemo(() => {
    return configViewThumbnailPreference ?? getPreference(boardId);
  }, [boardId, getPreference, configViewThumbnailPreference]);

  const CardThumbnail = useMemo(
    () => (
      <AssetGalleryCardThumbnailImage
        {...clip}
        className={getPreferenceClassName(thumbnailPreference)}
        animatedSrc={clip.type === 'animated' && isHovering && videoSrc ? videoSrc : ''}
        imgixParams={{
          fit: thumbnailPreference === 'default' ? 'crop' : 'clip',
        }}
        src={clearThumbnailCache({
          clipExt: clip.ext,
          clipStatus: clip.status,
          imageSrc: clip.assets.image,
          clipUpdatedAt: clip.updatedAt,
        })}
        width={width}
        height={height}
      />
    ),
    [clip, isHovering, videoSrc, thumbnailPreference, width, height],
  );

  return (
    <>
      <div className="absolute inset-0 flex overflow-hidden rounded">
        {CardThumbnail}
        {isSelected && <SelectedGalleryCardOverlay />}
      </div>
      {!!videoSrc && clip.type === 'video' && !ClipUtils.isProcessing(clip.status) && isHovering && (
        <Suspense fallback={CardThumbnail}>
          <ClipPreview vertical={vertical} playbackRate={playbackRate} status={clip.status} src={videoSrc} />
        </Suspense>
      )}
    </>
  );
};

export const AssetGalleryCardThumbnail = memo(_AssetGalleryCardThumbnail) as typeof _AssetGalleryCardThumbnail;
