import { AirActionTrackingLocation, useTrackRemovedTagsFromAssets } from '@air/analytics';
import { Tags } from '@air/api';
import { Clip } from '@air/api/types';
import { useCallback } from 'react';

import { TagBase } from '~/components/TagsSelect/utils';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

interface RemoveTagsFromClipsParams {
  tags: TagBase[];
  clipIds: Clip['id'][];
  trackLocation: AirActionTrackingLocation;
}

export const useRemoveTagsFromClips = () => {
  const { trackRemovedTagsFromAssets } = useTrackRemovedTagsFromAssets();
  const { currentWorkspace } = useCurrentWorkspace();

  const removeTagsFromClips = useCallback(
    async ({ tags, clipIds, trackLocation }: RemoveTagsFromClipsParams) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      try {
        await Tags.removeFromClips({ workspaceId, idsToRemove: tags.map(({ id }) => id), clipIds });
        trackRemovedTagsFromAssets({
          assets: clipIds,
          tags: tags.map((tag) => ({ ...tag, isDemo: false })),
          location: trackLocation,
        });
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to remove tags from clips',
          metadata: {
            data: {
              tags,
              clipIds,
            },
          },
        });
      }
    },
    [trackRemovedTagsFromAssets, currentWorkspace?.id],
  );

  return { removeTagsFromClips };
};
