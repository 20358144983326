import { TagListResponse as ApiTagListResponse } from '@air/api/types';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { keyBy } from 'lodash';
import { useCallback } from 'react';

import { getPrivateTagsKey } from '~/constants/react-query-keys';
import { TagsListResponse } from '~/store/selectedTags/types';

export const getUpdatedTags =
  (newTags: { [key: string]: Partial<ApiTagListResponse> }) =>
  (data?: InfiniteData<TagsListResponse>): InfiniteData<TagsListResponse> | undefined => {
    if (!data) return;

    return produce(data, (draft) => {
      draft.pages.forEach((page) => {
        page.data.tags.forEach((tag) => {
          if (newTags[tag.id]) {
            Object.assign(tag, newTags[tag.id]);
          }
        });
      });
    });
  };

export const getFilteredTags =
  (tagIdsToFilter: ApiTagListResponse['id'][]) =>
  (data?: InfiniteData<TagsListResponse>): InfiniteData<TagsListResponse> | undefined => {
    if (!data) return;

    return produce(data, (draft) => {
      draft?.pages.forEach((tagsResponse) => {
        tagsResponse.data.tags = tagsResponse.data.tags.filter(({ id }) => !tagIdsToFilter.includes(id));
      });
    });
  };

export const addTags =
  (newTags: ApiTagListResponse[]) =>
  (data?: InfiniteData<TagsListResponse>): InfiniteData<TagsListResponse> | undefined => {
    if (!data) return;
    return produce(data, (tagsResponse) => {
      if (tagsResponse.pages[0]?.data) {
        tagsResponse.pages[0].data.tags.unshift(...newTags);
      } else {
        tagsResponse.pages[0] = {
          data: {
            total: newTags.length,
            tags: newTags,
          },
          pagination: {
            hasMore: false,
            cursor: '',
          },
        };
      }
    });
  };

const useSetTagsQueriesData = () => {
  const queryClient = useQueryClient();
  const setTagQueriesData = useCallback(
    (
      workspaceId: string,
      updateFn: (data?: InfiniteData<TagsListResponse>) => InfiniteData<TagsListResponse> | undefined,
    ) => {
      return queryClient.setQueriesData<InfiniteData<TagsListResponse> | undefined>(
        { queryKey: getPrivateTagsKey({ workspaceId }) },
        updateFn,
      );
    },
    [queryClient],
  );

  return {
    setTagQueriesData,
  };
};

export const useUpdateTagsList = () => {
  const { setTagQueriesData } = useSetTagsQueriesData();

  const updateTagsList = useCallback(
    (workspaceId: string, newTags: Partial<ApiTagListResponse>[]) =>
      setTagQueriesData(workspaceId, getUpdatedTags(keyBy(newTags, 'id'))),
    [setTagQueriesData],
  );

  return {
    updateTagsList,
  };
};

export const useRemoveTagsFromList = () => {
  const { setTagQueriesData } = useSetTagsQueriesData();

  const removeTagsFromList = useCallback(
    (workspaceId: string, tagIds: ApiTagListResponse['id'][]) =>
      setTagQueriesData(workspaceId, getFilteredTags(tagIds)),
    [setTagQueriesData],
  );

  return {
    removeTagsFromList,
  };
};

export const useAddTagsToList = () => {
  const { setTagQueriesData } = useSetTagsQueriesData();

  const addTagsToList = useCallback(
    (workspaceId: string, newTags: ApiTagListResponse[]) => setTagQueriesData(workspaceId, addTags(newTags)),
    [setTagQueriesData],
  );

  return {
    addTagsToList,
  };
};
