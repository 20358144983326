import { PublicClip } from '@air/api';
import { Clip } from '@air/api/types';
import { formatDuration } from '@air/utilities';
import { memo } from 'react';

import ClipUtils, { isPdfRenderedExtension } from '~/utils/ClipUtils';

export interface AssetGalleryCardShortInfoProps {
  clip: Clip | PublicClip;
}

export const AssetGalleryCardShortInfo = memo(({ clip }: AssetGalleryCardShortInfoProps) => {
  const time = formatDuration(clip.duration ?? 0);
  const transcoded = ClipUtils.isTranscoded(clip.status);
  const videoTime = clip.type === 'video' && transcoded && time;

  const value = isPdfRenderedExtension(clip.ext) ? clip.ext.toUpperCase() : videoTime;

  if (!value) {
    return null;
  }

  return (
    <span
      className="block rounded-sm bg-pigeon-700 px-1 text-10 font-medium text-white"
      style={{
        letterSpacing: isPdfRenderedExtension(clip.ext) ? 1.44 : 0.32,
        fontWeight: isPdfRenderedExtension(clip.ext) ? 'semibold' : 'medium',
      }}
    >
      {value}
    </span>
  );
});

AssetGalleryCardShortInfo.displayName = 'AssetGalleryCardShortInfo';
