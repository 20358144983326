import { BoardsFiltersSortBy, BoardsListOptions } from '@air/api/types';
import { useMemo } from 'react';

import { useFilterParams } from '~/hooks/filters/useFilterParams';
import { useSearchParams } from '~/hooks/search/useSearchParams';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';

type UseBoardSearchParamsReturn = Partial<BoardsListOptions> & Pick<BoardsListOptions, 'view'>;

export const useBoardSearchParams = (): UseBoardSearchParamsReturn => {
  const boardId = useURLBoardIdSelector();
  const libraryId = useURLLibraryIdSelector();

  const { onlyInLibrariesParam } = useFilterParams();

  const { search } = useSearchParams();

  const boardsType = useMemo(() => {
    if (libraryId) {
      return 'YES';
    } else if (onlyInLibrariesParam?.is === false) {
      return 'NO';
    } else {
      return 'ALL';
    }
  }, [libraryId, onlyInLibrariesParam?.is]);

  const boardView = useMemo<BoardsListOptions['view']>(() => {
    if (boardId) {
      return boardId;
    } else if (!!search) {
      return 'all';
    } else {
      return 'root';
    }
  }, [boardId, search]);

  return {
    search,
    ancestorCutoff: boardId,
    numThumbnails: 1,
    sortBy: BoardsFiltersSortBy.custom,
    view: boardView,
    includeAncestors: true,
    libraryIds: libraryId ? [libraryId] : undefined,
    libraryBoards: boardsType,
  };
};
