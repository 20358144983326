import { AssetSearchFiltersCalculated } from '@air/api/types';
import { useMemo } from 'react';

import { useClipsFilters } from '~/hooks/filters/useClipsFilters';
import { useFilterParams } from '~/hooks/filters/useFilterParams';

/**
 * @description The types filter is not natively part of useClipsFilters but is necessary to create and read, saved filters. Since we need to do this type of spreading in various places we've made this hook to retrieve a single object that contains the filterParams and the type filter
 * @returns {AssetSearchFiltersCalculated} The complete object with filters needed to pass to the API
 */
export const useGetFilterParamsWithTypeFilter = (): AssetSearchFiltersCalculated => {
  const { filtersParams } = useClipsFilters();

  const { typesParam } = useFilterParams();

  const filters = useMemo(() => {
    return typesParam.length > 0 ? { ...filtersParams, type: { or: typesParam } } : filtersParams;
  }, [filtersParams, typesParam]);

  return filters;
};
