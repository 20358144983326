import { Meta } from '../types/docs';

const META: Meta = {
  name: 'renamed_clip',
  description: 'User renamed a clip',
  params: [
    {
      name: 'clip_id',
      description: 'Id of renamed clip',
      required: true,
      type: 'string',
    },
    {
      name: 'new_clip_title',
      description: 'New clip title',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
