import { type AirActionTrackingLocation } from '@air/analytics';
import { type Asset, type Clip } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';
import { matchesAirror, NO_CLIP_PERMISSION_ERROR } from '@air/errors';
import { useToasts } from '@air/provider-toast';
import { resetSelectedItemsAction } from '@air/redux-selected-items';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useDeletePrivateClips } from '~/swr-hooks/clips/useDeletePrivateClips';

import { DeleteAssetsWarning } from './DeleteBoards/DeleteAssetsWarning';

interface DeletePrivateClipsModalProps {
  trackLocation: AirActionTrackingLocation;
  assetIds: Asset['id'][];
  clipIds: Clip['id'][];
  parentBoardId?: string;
  onDelete?: () => void;
}

export const DeletePrivateClipsModal = ({
  assetIds,
  clipIds,
  onClose,
  onDelete,
  parentBoardId,
  trackLocation,
}: AirModalProps<DeletePrivateClipsModalProps>) => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const { deletePrivateClips } = useDeletePrivateClips();
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const { showToast } = useToasts();

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const deleteClips = async () => {
    try {
      setDeleting(true);
      await deletePrivateClips({ assetIds, clipIds, trackLocation, parentBoardId });
      dispatch(resetSelectedItemsAction());
      setDeleting(false);
      onDelete?.();
      onClose();
    } catch (error) {
      if (matchesAirror(error, NO_CLIP_PERMISSION_ERROR)) {
        showToast(`You do not have the necessary permissions to delete this clip.`);
      } else {
        showToast(`There was an error. Please try again later.`);
      }

      setDeleting(false);
    }
  };

  return (
    <ConfirmationModal
      ctaPrimaryText="Delete"
      description={
        <>
          You are about to delete <b>{pluralize('asset', assetIds.length, true)}</b> and <b>all associated versions.</b>
        </>
      }
      id="delete-private-clips"
      isDangerous
      isLoading={deleting}
      isOpen
      onConfirm={deleteClips}
      onDismiss={onClose}
      title={`Delete ${pluralize('asset', assetIds.length, true)}?`}
    >
      <DeleteAssetsWarning />
    </ConfirmationModal>
  );
};
