import { ClipType } from '@air/api/types';

import { ItemTypeFilter } from '~/components/Filters/types';
import { assetClipTypes, assetFileType } from '~/utils/ClipUtils';

export const getTypesToFetchFromParams = (typesParam: ItemTypeFilter[]) => {
  let types: ClipType[] = Object.keys(ClipType) as ClipType[];
  if (typesParam.length) {
    if (!typesParam.includes('files')) {
      types = types.filter((type) => type !== assetFileType);
    }
    if (!typesParam.includes('assets')) {
      types = types.filter((type) => !assetClipTypes.includes(type));
    }
  }
  return types;
};
