import { ViewFieldSupport } from '@air/api/types';
import React, { ComponentType, forwardRef, memo } from 'react';
import { useSelector } from 'react-redux';

import { CustomFieldTableHeaderProps } from '~/components/TableView/CustomFieldTableHeader/types';
import { NameHeader } from '~/components/TableView/NameHeader';
import { SortableHeader } from '~/components/TableView/SortableHeader';
import { TableCell } from '~/components/TableView/TableCell';
import TableHeader from '~/components/TableView/TableHeader';
import { getMinTableContentWidth, getTableColumnWidth } from '~/components/TableView/types';
import { ConfigVisibilityToggleType, getVisibilityToggleTypeFromString } from '~/constants/configViews';
import {
  DATE_CREATED_HEADER,
  DATE_MODIFIED_HEADER,
  FILE_HEADER,
  RESOLUTION_HEADER,
  SIZE_HEADER,
  TYPE_HEADER,
} from '~/constants/testIDs';
import { activeTableColumnsSelector, visibleConfigViewOptionsSelector } from '~/store/configViews/selectors';

export const dataTestIdMap: Record<ConfigVisibilityToggleType, string> = {
  boardCount: '',
  openCommentCount: '',
  tagCount: '',
  dateCreated: DATE_CREATED_HEADER,
  dateModified: DATE_MODIFIED_HEADER,
  ext: FILE_HEADER,
  size: SIZE_HEADER,
  type: TYPE_HEADER,
  resolution: RESOLUTION_HEADER,
  titleAndMetadata: '',
  dateTaken: 'dateTaken',
};

export interface TableHeadersProps {
  CustomFieldTableHeaderComponent: ComponentType<CustomFieldTableHeaderProps>;
  horizontalPadding: number;
  topPos: number;
}

const _TableHeaders = forwardRef<HTMLDivElement, TableHeadersProps>(
  ({ CustomFieldTableHeaderComponent, horizontalPadding, topPos }: TableHeadersProps, ref) => {
    const visibleConfigViewOptions = useSelector(visibleConfigViewOptionsSelector);

    const activeTableColumns = useSelector(activeTableColumnsSelector);
    const minContentWidth = getMinTableContentWidth(activeTableColumns);
    return (
      <TableHeader
        ref={ref}
        style={{
          top: topPos,
        }}
        innerStyle={{
          minWidth: minContentWidth,
          paddingLeft: horizontalPadding,
          paddingRight: horizontalPadding,
        }}
      >
        <NameHeader />
        {visibleConfigViewOptions.map((option) => {
          if (option.isCustomField && option.customFieldId && option.label !== undefined) {
            return (
              <CustomFieldTableHeaderComponent
                key={option.customFieldId}
                customFieldId={option.customFieldId}
                customFieldName={option.label}
              />
            );
          }
          const toggleType = getVisibilityToggleTypeFromString(option.fieldName);
          if (toggleType) {
            const toggleSortBy = option.fieldName;
            if (toggleSortBy && option.supports.includes(ViewFieldSupport.sort)) {
              return (
                <SortableHeader
                  key={option.fieldName}
                  title={option.label}
                  sortingType={option.fieldName}
                  data-testid={dataTestIdMap[toggleType]}
                  style={{ maxWidth: getTableColumnWidth(toggleType) }}
                  width={getTableColumnWidth(toggleType)}
                />
              );
            } else {
              return (
                <TableCell
                  key={option.fieldName}
                  width={getTableColumnWidth(toggleType)}
                  className="pl-4"
                  style={{ maxWidth: getTableColumnWidth(toggleType) }}
                  data-testid={dataTestIdMap[toggleType]}
                >
                  {option.label}
                </TableCell>
              );
            }
          }
          return null;
        })}
      </TableHeader>
    );
  },
);

_TableHeaders.displayName = '_TableHeaders';

export const TableHeaders = memo(_TableHeaders);

TableHeaders.displayName = 'PrivateTableHeaders';
