import { ChevronDown as ChevronDownIcon } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';
import * as RadixCollapsible from '@radix-ui/react-collapsible';
import classNames from 'classnames';
import React, { memo, ReactNode, useState } from 'react';

export interface CollapsibleCardProps {
  children: ReactNode;
  header: ReactNode;
  footer: ReactNode;
  testId?: string;
}

export const CollapsibleCard = memo(({ testId, header, footer, children }: CollapsibleCardProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleLabel = isExpanded ? 'Collapse' : 'Expand';

  return (
    <RadixCollapsible.Root
      className="w-full rounded bg-surface-1 p-2 shadow-kanban dark:border dark:border-grey-5"
      data-testid={testId}
      onOpenChange={setIsExpanded}
      open={isExpanded}
    >
      <div className="flex items-center justify-between px-0.5 py-1">
        {header}
        <RadixCollapsible.Trigger>
          <Tooltip label={toggleLabel}>
            <IconButton appearance="ghost" color="grey" label={toggleLabel} size="small">
              <ChevronDownIcon
                className={classNames('block h-4 w-4 transition-transform', isExpanded ? 'rotate-0' : '-rotate-90')}
              />
            </IconButton>
          </Tooltip>
        </RadixCollapsible.Trigger>
      </div>

      <RadixCollapsible.Content className="overflow-hidden radix-state-closed:animate-radix-collapsible-slide-up radix-state-open:animate-radix-collapsible-slide-down">
        <div>{children}</div>
        {footer}
      </RadixCollapsible.Content>
    </RadixCollapsible.Root>
  );
});

CollapsibleCard.displayName = 'FilterCard';
