import { Trash } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { IconButton } from '@air/primitive-icon-button';
import React, { memo } from 'react';

import { CLEAR_FILTER_BTN, REMOVE_FILTER_BTN } from '~/constants/testIDs';

interface FilterCardFooterProps {
  onDeleteClick: () => void;
  onClearClick?: () => void;
}

export const FilterCardFooter = memo(({ onDeleteClick, onClearClick }: FilterCardFooterProps) => (
  <div className="flex items-center justify-end p-1">
    {!!onClearClick && (
      <>
        <Button appearance="ghost" color="grey" data-testid={CLEAR_FILTER_BTN} onClick={onClearClick} size="small">
          Clear
        </Button>
        <div className="mx-3 h-4 w-px bg-grey-5" />
      </>
    )}
    <IconButton
      appearance="ghost"
      color="grey"
      data-testid={REMOVE_FILTER_BTN}
      icon={Trash}
      label="Remove filter"
      onClick={onDeleteClick}
      size="small"
    />
  </div>
));

FilterCardFooter.displayName = 'FilterCardFooter';
