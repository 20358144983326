import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useActionBar } from '@air/provider-action-bar';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo, type ReactNode, useCallback, useEffect, useRef } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';

import { SELECTION_BAR_DELAY } from '~/constants/selectionActionBar';

import { SelectionActionBarComponent, SelectionActionBarComponentProps } from './SelectionActionBarComponent';

export interface SelectionActionBarProps {
  selectedItemsCount: number;
  onClearAll: () => void;
  options: SelectionActionBarComponentProps['menuOptions'];
  itemLabel?: string;
  baseMenuItemRenderer?: (item: DropdownMenuOption) => ReactNode;
}

export const SelectionActionBar = memo(
  ({ selectedItemsCount, onClearAll, options, itemLabel, baseMenuItemRenderer }: SelectionActionBarProps) => {
    const { isAboveLargeScreen } = useBreakpointsContext();
    const { setActionBar, actionBar } = useActionBar();

    const delayedHideHandler = useRef(0);
    const actionBarIsOpen = useRef(!!actionBar);

    actionBarIsOpen.current = !!actionBar;

    const hidePlateWithDelay = useCallback(() => {
      clearTimeout(delayedHideHandler.current);
      delayedHideHandler.current = setTimeout(() => setActionBar(null), 100);
    }, [setActionBar]);

    const selectionComponent = useDeepCompareMemo(
      () => (
        <SelectionActionBarComponent
          baseMenuItemRenderer={baseMenuItemRenderer}
          itemLabel={itemLabel}
          menuOptions={options}
          numberOfSelectedItems={selectedItemsCount}
          onClearAll={onClearAll}
        />
      ),
      [baseMenuItemRenderer, itemLabel, options, onClearAll, selectedItemsCount],
    );

    useEffect(() => {
      return () => {
        clearTimeout(delayedHideHandler.current);
        hidePlateWithDelay();
      };
    }, [hidePlateWithDelay]);

    useEffect(() => {
      if (!isAboveLargeScreen || selectedItemsCount === 0) {
        hidePlateWithDelay();
        return;
      }

      // if transitioning between selections, update the snackbar immediately
      if (actionBarIsOpen.current) {
        clearTimeout(delayedHideHandler.current);
        setActionBar(selectionComponent);
      } else {
        clearTimeout(delayedHideHandler.current);
        // delay the popup to account for double-clicking selections. Prevents flashing
        const delayedPopup = setTimeout(() => setActionBar(selectionComponent), SELECTION_BAR_DELAY);
        return () => {
          clearTimeout(delayedPopup);
        };
      }
    }, [
      baseMenuItemRenderer,
      hidePlateWithDelay,
      isAboveLargeScreen,
      itemLabel,
      onClearAll,
      selectedItemsCount,
      selectionComponent,
      setActionBar,
    ]);

    return null;
  },
);

SelectionActionBar.displayName = 'SelectionActionBar';
