import { isFinite } from 'lodash';
import { memo, useCallback, useMemo } from 'react';

import { SelectChipOptionContainer } from '~/components/Select/components/SelectChipOptionContainer';
import { Select, SelectProps } from '~/components/Select/Select';
import { DefaultChipType } from '~/components/Select/shared/types';

interface AudioSampleRatesSelectProps
  extends Omit<SelectProps, 'placeholder' | 'options' | 'selectedOptions' | 'onSelectionChange'> {
  selectedValues: string[];
  onSelectionChange: (newString: string[]) => void;
  audioRates: string[];
}

const rateToOption = (rate: string): DefaultChipType => {
  const rateNumber = Number(rate) / 1000;

  return {
    value: rate,
    // Number.parseFloat is used to remove trailing zeros
    label: isFinite(rateNumber) ? `${Number.parseFloat(rateNumber.toFixed(3))}kHz` : rate,
  };
};

export const AudioSampleRatesSelect = memo(
  ({ audioRates, selectedValues, onSelectionChange, ...props }: AudioSampleRatesSelectProps) => {
    const options = useMemo(() => audioRates.map((rate) => rateToOption(rate)), [audioRates]);
    const selectedOptions = useMemo(() => selectedValues.map((rate) => rateToOption(rate)), [selectedValues]);

    const onValuesChange: SelectProps['onSelectionChange'] = useCallback(
      ({ chips }) => {
        onSelectionChange(chips.map((chip) => chip.value));
      },
      [onSelectionChange],
    );

    const listItemRenderer = useCallback<Required<SelectProps>['listItemRenderer']>(
      (option) => (
        <SelectChipOptionContainer className="flex justify-between py-2">
          <p className="text-14 text-grey-12">{option.label}</p>
        </SelectChipOptionContainer>
      ),
      [],
    );

    return (
      <Select
        data-testid="AUDIO_SAMPLE_RATE"
        placeholder="Search for audio sample rate i.e. 44kHz"
        listItemRenderer={listItemRenderer}
        maxDropdownHeight={300}
        options={options}
        renderAsInput
        onSelectionChange={onValuesChange}
        selectedOptions={selectedOptions}
        alwaysShowClearItemButton
        isClearable
        isSearchable
        isSticky
        size="large"
        {...props}
      />
    );
  },
);

AudioSampleRatesSelect.displayName = 'AudioSampleRatesSelect';
