import { memo } from 'react';

import { ConfigViewOption } from '~/store/configViews/types';

import { useToggleViewVisibilityMenuOption } from './hooks/useToggleViewVisibilityMenuOption';
import { useToggleViewVisibilityMenuOptions } from './hooks/useToggleViewVisibilityMenuOptions';
import { ViewVisibilityHeaderToggle } from './shared/ViewVisibilityHeaderToggle';
import { ViewVisibilitySection } from './ViewVisibilitySection/ViewVisibilitySection';
import { ViewVisibilityToggle } from './ViewVisibilitySection/ViewVisibilityToggle';

export interface ConfigViewVisbilityOptionsProps {
  options: ConfigViewOption[];
}

export const ConfigViewVisbilityOptions = memo(({ options }: ConfigViewVisbilityOptionsProps) => {
  const { toggleViewVisibilityMenuOption } = useToggleViewVisibilityMenuOption();
  const { toggleViewVisibilityMenuOptions } = useToggleViewVisibilityMenuOptions();
  const initialShowOptions = options.some(({ isVisible }) => isVisible);

  if (options.length === 1) {
    const option = options[0];

    return (
      <ViewVisibilityHeaderToggle
        key={option.fieldName}
        id={option.fieldName}
        label={option.label}
        onToggle={() => toggleViewVisibilityMenuOption(option)}
        isToggleOn={option.isVisible}
      />
    );
  }

  return (
    <ViewVisibilitySection
      initialShowOptions={initialShowOptions}
      id="info"
      label="Metadata"
      onToggleShowingOptions={(showing) => {
        toggleViewVisibilityMenuOptions(options, showing);
      }}
      toggles={options.map((option) => (
        <ViewVisibilityToggle
          key={option.fieldName}
          onClick={() => toggleViewVisibilityMenuOption(option)}
          option={option}
        />
      ))}
    />
  );
});

ConfigViewVisbilityOptions.displayName = 'ConfigViewVisbilityOptions';
