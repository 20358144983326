import { useAirModal } from '@air/provider-modal';

import { AddAssetsToBoardsModal } from '~/components/Modals/AddAssetsToBoardsModal/AddAssetsToBoardsModal';

export const useAddAssetsToBoardsModal = () => {
  const [showAddAssetsToBoardsModal] = useAirModal(AddAssetsToBoardsModal);

  return {
    showAddAssetsToBoardsModal,
  };
};
