import {
  AllAudioVisualFilters,
  AllDateFilters,
  AllLocationFilters,
  AudioVisualFilters,
  DateFilters,
  FilterType,
  LocationFilters,
} from '~/components/FiltersMenu/types';

export const isDateFilter = (filter: FilterType): filter is DateFilters =>
  AllDateFilters.some((dateFilter) => dateFilter === filter);

export const isLocationFilter = (filter: FilterType): filter is LocationFilters =>
  AllLocationFilters.some((locationFilter) => locationFilter === filter);

export const isAudioVisualFilter = (filter: FilterType): filter is AudioVisualFilters =>
  AllAudioVisualFilters.some((audioVisualFilter) => audioVisualFilter === filter);
