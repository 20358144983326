import { RefObject, SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import { List } from 'react-virtualized';

const scrollElement = (element: RefObject<HTMLElement> | null, scrollLeft: number) => {
  if (element && element.current) {
    element.current.scrollTo(scrollLeft, 0);
  }
};

/**
 * This hook is a hack for table view horizontal scrolling.
 * We want to scroll Table horizontally, but only the table - not the whole page.
 * Since we use WindowScroller, it is not so easy to set it up. Moreover, we want to show scroller above footer,
 * not when a user scrolls down the table
 *
 * @param listRef ref to react-virtualized List element
 * @param scrollRef ref to scroller element
 * @param headerRef ref to header element
 */
export function useListHorizontalScroll(
  listRef: RefObject<List> | null,
  scrollRef: RefObject<HTMLElement> | null,
  headerRef: RefObject<HTMLElement> | null,
) {
  // this is true when element.scrollTo is called in onListScroll
  const isEventFromGridScroll = useRef(false);

  // this is true when element.scrollTo is called in onHorizontal scroll
  const isEventFromScroll = useRef(false);

  const getListScroller = useCallback(() => {
    if (!listRef || !listRef.current) {
      return null;
    }
    // @ts-ignore - _scrollingContainer is not exposed in Grid interface
    return (listRef?.current?.Grid?._scrollingContainer?.children ?? [])[0];
  }, [listRef]);

  const onListScroll = useCallback(
    // TODO: Cant figure out how to type this properly.
    (params: any) => {
      const scrollLeft = params.target.scrollLeft;

      // scroll event is called when a user scrolls, but also when scrollTo is called
      // we have to distinguish between those two cases
      if (isEventFromScroll.current) {
        isEventFromScroll.current = false;
      } else {
        isEventFromGridScroll.current = true;
        isEventFromScroll.current = false;

        scrollElement(headerRef, scrollLeft);
        scrollElement(scrollRef, scrollLeft);
      }
    },
    // @ts-ignore - we need to check headerRef.current here
    [headerRef.current, scrollRef.current], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const listScroller = getListScroller();
    if (listScroller) {
      listScroller.removeEventListener('scroll', onListScroll);
      listScroller.addEventListener('scroll', onListScroll);
    }
  }, [getListScroller, onListScroll]);

  const onHorizontalScroll = useCallback(
    ({ nativeEvent: { target } }: SyntheticEvent<HTMLDivElement>) => {
      // @ts-ignore - scrollLeft is not recognized
      const scrollLeft = target.scrollLeft;

      // scroll event is called when a user scrolls, but also when scrollTo is called
      // we have to distinguish between those two cases
      if (isEventFromGridScroll.current) {
        isEventFromGridScroll.current = false;
      } else {
        isEventFromScroll.current = true;
        isEventFromGridScroll.current = false;

        scrollElement(headerRef, scrollLeft);
        const listScroller = getListScroller();
        if (listScroller) {
          listScroller.scrollTo(scrollLeft, 0);
        }
      }
    },
    // @ts-ignore - we need to check headerRef.current here
    [headerRef.current, getListScroller], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return onHorizontalScroll;
}
