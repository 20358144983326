import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModifiedDateFilter } from '~/components/Filters/DateFilter/ModifiedDateFilter';
import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import {
  removeFilterAction,
  setModifiedDateFilterEndDateAction,
  setModifiedDateFilterStartDateAction,
  setModifiedDateFilterTypeAction,
} from '~/store/filters/actions';
import {
  modifiedDateFilterDateTypeSelector,
  modifiedDateFilterEndDateSelector,
  modifiedDateFilterStartDateSelector,
} from '~/store/filters/selectors';
import { DateRangeType, SelectedFilter } from '~/store/filters/types';

export type ModifiedDateFilterCardProps = BaseFilterCardProps;

export const ModifiedDateFilterCard = memo((props: ModifiedDateFilterCardProps) => {
  const dispatch = useDispatch();
  const dateType = useSelector(modifiedDateFilterDateTypeSelector);
  const startDate = useSelector(modifiedDateFilterStartDateSelector);
  const endDate = useSelector(modifiedDateFilterEndDateSelector);

  const onDateTypeChange = useCallback(
    (type: DateRangeType) => {
      dispatch(setModifiedDateFilterTypeAction({ type }));
    },
    [dispatch],
  );

  const onStartDateChange = useCallback(
    (startDate?: Date) => {
      dispatch(setModifiedDateFilterStartDateAction({ startDate }));
    },
    [dispatch],
  );

  const onEndDateChange = useCallback(
    (endDate?: Date) => {
      dispatch(setModifiedDateFilterEndDateAction({ endDate }));
    },
    [dispatch],
  );

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
  };

  return (
    <FilterCard currentFilter="dateModified" onRemoveFilter={onRemoveFilter} {...props}>
      <ModifiedDateFilter
        startDate={startDate}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
        onTypeChange={onDateTypeChange}
        endDate={endDate}
        dateType={dateType}
      />
    </FilterCard>
  );
});

ModifiedDateFilterCard.displayName = 'ModifiedDateFilterCard';
