import { ComponentType, memo, useCallback, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import { BaseStringSelectProps } from '~/components/StringSelect';
import {
  clearCamerasFilterValuesAction,
  removeFilterAction,
  setCameraMakeFilterValuesAction,
  setCameraModelsFilterValuesAction,
} from '~/store/filters/actions';
import { selectedCameraMakeFiltersSelector, selectedCameraModelsFiltersSelector } from '~/store/filters/selectors';
import { SelectedFilter } from '~/store/filters/types';

export interface CamerasFilterCardProps extends BaseFilterCardProps {
  CameraModelsSelect: ComponentType<BaseStringSelectProps & { make: string }>;
  CameraMakeSelect: ComponentType<BaseStringSelectProps>;
}

export const CamerasFilterCard = memo(({ CameraModelsSelect, CameraMakeSelect, ...props }: CamerasFilterCardProps) => {
  const dispatch = useDispatch();
  const selectedModels = useSelector(selectedCameraModelsFiltersSelector);
  const selectedMake = useSelector(selectedCameraMakeFiltersSelector);

  const clearCamera = useCallback(() => {
    dispatch(clearCamerasFilterValuesAction());
  }, [dispatch]);

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
    clearCamera();
  };

  const onSelectedModelsChange = useCallback(
    (models: string[]) => dispatch(setCameraModelsFilterValuesAction({ models })),
    [dispatch],
  );

  const onSelectedMakeChange = useCallback(
    (makes: string[]) => {
      batch(() => {
        dispatch(setCameraMakeFilterValuesAction({ make: makes[0] }));
        // if a user selects camera make, models have to be cleared, because they may not belong to the selected make
        if (makes.length > 0) {
          dispatch(setCameraModelsFilterValuesAction({ models: [] }));
        }
      });
    },
    [dispatch],
  );

  const selectedMakeValues = useMemo(() => (selectedMake ? [selectedMake] : []), [selectedMake]);

  return (
    <FilterCard currentFilter="camera" onClearClick={clearCamera} onRemoveFilter={onRemoveFilter} {...props}>
      <div className="flex flex-col gap-2">
        <CameraMakeSelect selectedValues={selectedMakeValues} onSelectionChange={onSelectedMakeChange} />
        <CameraModelsSelect
          make={selectedMake}
          selectedValues={selectedModels}
          onSelectionChange={onSelectedModelsChange}
        />
      </div>
    </FilterCard>
  );
});

CamerasFilterCard.displayName = 'CamerasFilterCard';
