import { memo } from 'react';

import {
  BulkEditPlainTextCustomFieldModal,
  BulkEditPlainTextCustomFieldModalProps,
} from '~/components/Modals/BulkEditCustomFieldModal/BulkEditPlainTextCustomFieldModal';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export type PublicBulkEditPlainTextCustomFieldModalProps = Omit<BulkEditPlainTextCustomFieldModalProps, 'onSave'>;

export const PublicBulkEditPlainTextCustomFieldModal = memo(
  (props: AirModalProps<PublicBulkEditPlainTextCustomFieldModalProps>) => {
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

    return (
      <BulkEditPlainTextCustomFieldModal
        {...props}
        onSave={async (params) => {
          await updateItemsCustomFieldValue({
            ...params,
            trackingLocation: 'context-menu-bulk-edit',
            workspaceId: undefined,
          });
        }}
      />
    );
  },
);
PublicBulkEditPlainTextCustomFieldModal.displayName = 'PublicBulkEditPlainTextCustomFieldModal';
