import { PublicClip } from '@air/api';
import { Board, Clip, PublicBoard } from '@air/api/types';
import { noop } from 'lodash';
import { useMemo } from 'react';

import { useBoardsBeingDeleted } from '~/swr-hooks/boards/useBoardsBeingDeleted';
import { getAssetFromAssetsData, getBoardsFromBoardsData } from '~/swr-hooks/gallery/galleryView/utils';
import { UseGalleryViewItemsParams, UseGalleryViewItemsReturn } from '~/swr-hooks/gallery/types';

export function useGalleryViewItems<B extends PublicBoard | Board, C extends Clip | PublicClip>({
  boards,
  clips,
  files,
}: UseGalleryViewItemsParams<B, C>): UseGalleryViewItemsReturn<B, C> {
  const boardsBeingDeleted = useBoardsBeingDeleted();
  const loadNextPage = () => {
    if (boards.isValidating || clips.isValidating || files.isValidating) {
      return;
    }
    if (!!boards.data && boards.hasMore) {
      boards.loadNextPage();
    } else if (!!clips.data && clips.hasMore) {
      clips.loadNextPage();
    } else if (!!files.data && files.hasMore) {
      files.loadNextPage();
    }
  };

  const isLoadingInitialData =
    !boards.data?.[0]?.data?.length &&
    !clips.data?.[0]?.data?.clips?.length &&
    !files.data?.[0]?.data?.clips?.length &&
    (boards.isValidating || clips.isValidating || files.isValidating);

  const clipsItems = getAssetFromAssetsData(clips.data);
  const filesItems = getAssetFromAssetsData(files.data);
  const boardItems = getBoardsFromBoardsData(boards.data, boardsBeingDeleted);

  const boardsBeingDeletedCount = useMemo(() => {
    if (boardsBeingDeleted.length === 0) {
      return 0;
    }

    return (
      boards.data
        ?.flatMap((page) => page.data)
        .reduce((acc, board) => {
          return acc + (boardsBeingDeleted.includes(board.id) ? 1 : 0);
        }, 0) ?? 0
    );
  }, [boards.data, boardsBeingDeleted]);
  const boardCount = (boards.data ? boards.data[boards.data.length - 1]?.total : 0) - boardsBeingDeletedCount;

  return {
    data: {
      boards: {
        items: boardItems,
        hasMore: boards.hasMore,
        total: boardCount,
        isLoading: boards.isVisible && boards.isValidating,
      },
      clips: {
        items: clipsItems,
        total: clips.data ? clips.data[clips.data.length - 1]?.data?.total : 0,
        hasMore: clips.hasMore,
        isLoading: clips.isVisible && clips.isValidating,
      },
      files: {
        items: filesItems,
        total: files.data ? files.data[files.data.length - 1]?.data?.total : 0,
        hasMore: files.hasMore,
        isLoading: files.isVisible && files.isValidating,
      },
    },
    loadNextPage: boards.hasMore || clips.hasMore || files.hasMore ? loadNextPage : noop,
    isLoading: isLoadingInitialData,
    isEmpty:
      !boards.isValidating &&
      !(boards.data && boards.data.some(({ data }) => data.length > 0)) &&
      !clips.isValidating &&
      !(clips.data && clips.data.some(({ data: { clips } }) => clips.length > 0)) &&
      !files.isValidating &&
      !(files.data && files.data.some(({ data: { clips } }) => clips.length > 0)),
  };
}
