import { AirActionTrackingLocation, useTrackToggledFavorite } from '@air/analytics';
import { Clips } from '@air/api';
import { Clip } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getFavoritedMessage } from '~/constants/messages';
import { getVersionsListKey } from '~/constants/react-query-keys';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import {
  favoriteClipsFailureAction,
  favoriteClipsRequestAction,
  unfavoriteClipsFailureAction,
  unfavoriteClipsRequestAction,
} from '~/store/centralizedClip/actions';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useUpdateFavoritedAssetsInAllViews } from '~/utils/mutateUtils/GalleryAssets';

import { useActiveSubscriptionCallback } from '../subscriptions/useActiveSubscriptionCallback';

type UseToggleFavoriteReturn = {
  toggleFavorite: (params: {
    /** Array of clips that you would like to toggle the bookmarked state of */
    clips: Pick<Clip, 'id' | 'bookmarked' | 'assetId'>[];
    /** The location of where the function is being called (for analytics) */
    trackLocation: AirActionTrackingLocation;
  }) => void;
};

/**
 * This hook gives you a function to pass clips to that will toggle their current bookmarked state. If any of them are currently unbookmarked, it will bookmark all of them. If all of them are currently bookmarked, it will unbookmark all of them.
 */
export const useToggleFavorite = (): UseToggleFavoriteReturn => {
  const dispatch = useDispatch();
  const { showToast } = useToasts();
  const { activeSubscriptionCallback } = useActiveSubscriptionCallback();
  const { updateFavoritedAssetsInAllViews } = useUpdateFavoritedAssetsInAllViews();
  const queryClient = useQueryClient();
  const { trackToggledFavorite } = useTrackToggledFavorite();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const toggleFavorite: UseToggleFavoriteReturn['toggleFavorite'] = useCallback(
    ({ clips, trackLocation }) => {
      activeSubscriptionCallback(async () => {
        const getAllClipsAreFavorited = (clips: Pick<Clip, 'id' | 'bookmarked'>[]) =>
          clips.every(({ bookmarked }) => bookmarked);
        const clipIds = clips.map(({ id }) => id);
        const favorited = !getAllClipsAreFavorited(clips);

        if (!workspaceId) {
          throw new Error('No workspace id');
        }

        try {
          updateFavoritedAssetsInAllViews(clipIds, favorited);

          dispatch(favorited ? favoriteClipsRequestAction({ clipIds }) : unfavoriteClipsRequestAction({ clipIds }));

          await Clips.batchUpdate({
            workspaceId,
            clips: clipIds.map((id): Partial<Clip> => ({ id, bookmarked: favorited })),
          });

          clips.forEach(({ assetId }) => {
            queryClient.invalidateQueries({ queryKey: getVersionsListKey({ assetId }) });
          });

          trackToggledFavorite({
            favorited,
            ids: clipIds,
            location: trackLocation,
          });

          const toastMessage = getFavoritedMessage(favorited, clips.length);
          showToast(toastMessage);
        } catch (error) {
          dispatch(favorited ? favoriteClipsFailureAction({ clipIds }) : unfavoriteClipsFailureAction({ clipIds }));

          showToast(`There was an error while trying to toggle a favorite`);

          reportErrorToBugsnag({
            error,
            context: `Failed to toggle a clip's favorite`,
            metadata: {
              data: {
                location: trackLocation,
              },
            },
          });
        }
      });
    },
    [
      activeSubscriptionCallback,
      workspaceId,
      updateFavoritedAssetsInAllViews,
      dispatch,
      trackToggledFavorite,
      showToast,
      queryClient,
    ],
  );

  return {
    toggleFavorite,
  };
};
