import {
  DropdownMenu,
  DropdownMenuOptionItem,
  DropdownMenuOptionSub,
  renderDropdownItems,
} from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { Tooltip } from '@air/primitive-tooltip';

export type SelectionActionBarIconButtonProps = DropdownMenuOptionItem | DropdownMenuOptionSub;

export const SelectionActionBarIconButton = ({
  id,
  prefix,
  label,
  onSelect,
  type,
  ...restOfProps
}: SelectionActionBarIconButtonProps) => {
  if (!prefix) return null;

  const trigger = (
    <IconButton
      appearance="ghost"
      className="text-pigeon-50 hover:bg-pigeon-700 active:bg-pigeon-800"
      color="grey"
      data-id={id}
      data-testid="SELECTION_ACTION_BAR_BUTTON"
      onClick={onSelect}
      size="large"
      label=""
    >
      {prefix}
    </IconButton>
  );

  if (type === 'item') {
    return (
      <Tooltip label={label} side="top">
        {trigger}
      </Tooltip>
    );
  }

  if (type === 'sub' && 'options' in restOfProps) {
    return (
      <Tooltip label={label} side="top">
        <DropdownMenu sideOffset={16} trigger={trigger}>
          {renderDropdownItems({ options: restOfProps.options })}
        </DropdownMenu>
      </Tooltip>
    );
  }

  return null;
};
