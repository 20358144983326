import { PublicClip } from '@air/api';
import { Board, Clip, PublicBoard } from '@air/api/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { GalleryItemType } from '~/components/Gallery/types';
import { setSelectableGalleryItemsAction } from '~/store/selectedItems/actions';
import { SelectableGalleryItem } from '~/store/selectedItems/types';

export function useGroupedGallerySelectedItems(
  boards: Board[] | PublicBoard[],
  assets: Clip[] | PublicClip[],
  files: Clip[] | PublicClip[],
) {
  const dispatch = useDispatch();

  //Send rendered items to reducer, to enable multiselect with SHIFT
  //Order of those is used to determine which items should be selected
  useEffect(() => {
    const selectableBoardItems: SelectableGalleryItem[] = boards.map((board) => ({
      id: board.id,
      type: GalleryItemType.board,
      item: board,
    }));

    const selectableAssetItems: SelectableGalleryItem[] = assets.map((asset) => ({
      id: asset.id,
      type: GalleryItemType.asset,
      item: asset,
    }));

    const selectableFileItems: SelectableGalleryItem[] = files.map((file) => ({
      id: file.id,
      type: GalleryItemType.file,
      item: file,
    }));

    dispatch(
      setSelectableGalleryItemsAction({
        selectableItems: [...selectableBoardItems, ...selectableAssetItems, ...selectableFileItems],
      }),
    );
  }, [dispatch, boards, assets, files]);
}
