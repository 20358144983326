import { Gear } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Popover } from '@air/primitive-popover';
import { memo, ReactNode } from 'react';

import { CONFIG_VIEWS_VISIBILITY } from '~/constants/testIDs';

export interface ViewVisibilityToggleMenuProps {
  children: ReactNode;
}

export const ViewVisibilityToggleMenu = memo(({ children }: ViewVisibilityToggleMenuProps) => {
  return (
    <Popover
      side="bottom"
      align="end"
      trigger={
        <IconButton
          appearance="ghost"
          color="grey"
          size="medium"
          label="Visibility Toggles"
          data-testid={CONFIG_VIEWS_VISIBILITY}
          icon={Gear}
        />
      }
    >
      {children}
    </Popover>
  );
});

ViewVisibilityToggleMenu.displayName = 'ViewVisibilityToggleMenu';
