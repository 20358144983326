import { memo } from 'react';

import { FilterRowWithCheckbox } from '~/components/Filters/FilterRowWithCheckbox';
import { ItemTypeFilter } from '~/components/Filters/types';

export interface TypeFilterProps {
  availableTypes?: ItemTypeFilter[];
  selectedTypes: ItemTypeFilter[];
  onTypeSelectedChange: (type: ItemTypeFilter, isSelected: boolean) => void;
}

export const defaultTypes: ItemTypeFilter[] = ['boards', 'files', 'assets'];

const typeFiltersLabels: { [key in ItemTypeFilter]: string } = {
  assets: 'Assets',
  files: 'Files',
  boards: 'Boards',
};

export const TypeFilter = memo(
  ({ availableTypes = defaultTypes, selectedTypes, onTypeSelectedChange }: TypeFilterProps) => {
    return (
      <div>
        {availableTypes.map((type) => (
          <FilterRowWithCheckbox
            key={type}
            label={typeFiltersLabels[type]}
            isSelected={selectedTypes.includes(type)}
            onChange={(isSelected) => onTypeSelectedChange(type, isSelected)}
          />
        ))}
      </div>
    );
  },
);

TypeFilter.displayName = 'TypeFilter';
