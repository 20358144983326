import { ShortUrl } from '@air/api';
import { ShortUrlObjectType } from '@air/api/types';
import { matchesAirror, NOT_FOUND } from '@air/errors';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useRevalidateISR } from '~/hooks/useRevalidateISR';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getShortIdInformationKey } from '~/swr-hooks/shortUrl/useGetExistingShortIdInformation';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

/**
 * This hook returns a function that you can pass a clip id to that will,
 * if it exists, revalidate the short url for that clip. You want to call this
 * function if any changes are made to the clip and you want those changes to be
 * reflected in the public clip. This function under the hood
 * kicks off a regeneration of the static files for the public clip.
 */
export const useRevalidatePublicClip = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { revalidateISR } = useRevalidateISR();
  const client = useQueryClient();

  const getPublicClipShortId = useCallback(
    async ({ boardContext, clipId }: { clipId: string; boardContext: string | undefined }) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      try {
        return await client.ensureQueryData({
          queryKey: getShortIdInformationKey({ objectId: clipId, boardContext }),
          queryFn: () =>
            ShortUrl.getExistingShortIdForObject({
              workspaceId,
              id: clipId,
              type: ShortUrlObjectType.clip,
              boardContext,
            }),
        });
      } catch (error) {
        // if short url doesn't exist, just return null - we don't need to revalidate
        if (matchesAirror(error, NOT_FOUND)) {
          return null;
        }
        throw error;
      }
    },
    [currentWorkspace?.id, client],
  );

  const revalidatePublicClip = useCallback(
    async ({ boardContext, clipId }: { clipId: string; boardContext: string | undefined }) => {
      try {
        const response = await getPublicClipShortId({ boardContext, clipId });

        if (!response) {
          // if there is no short url for this board, we don't need to revalidate
          return;
        }

        await revalidateISR({
          path: `/a/${response.id}`,
        });
      } catch (error) {
        reportErrorToBugsnag({ error, context: 'Failed to revalidate public board', metadata: { clipId } });
      }
    },
    [getPublicClipShortId, revalidateISR],
  );

  return {
    revalidatePublicClip,
  };
};
