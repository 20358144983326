import { ShortUrlResponse } from '@air/api/types';
import { memo } from 'react';

import { useUpdateShareClipLink } from '~/components/SharePrivateClipModal/hooks/useUpdateShareClipLink';
import { SharePrivateContentSettings } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/SharePrivateContentSettings';

interface SharePrivateClipSettingsProps {
  clipId: string;
  shortIdInfo: ShortUrlResponse;
}

export const SharePrivateClipSettings = memo(({ shortIdInfo, clipId }: SharePrivateClipSettingsProps) => {
  const { onShortUrlUpdate, isLoading } = useUpdateShareClipLink({ clipId, shortIdInfo });

  return (
    <SharePrivateContentSettings shortIdInfo={shortIdInfo} onShortUrlUpdate={onShortUrlUpdate} isLoading={isLoading} />
  );
});

SharePrivateClipSettings.displayName = 'SharePrivateClipSettings';
