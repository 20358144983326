import { type BaseCustomField } from '@air/api/types';
import { useCallback, useMemo } from 'react';

import { useLibraryCustomFields } from '~/components/LibraryBeta/hooks/queries/useLibraryCustomFields';

export type UseGetAvailableCustomFields = {
  libraryId?: string;
};

export const useGetAvailableCustomFields = <Data extends BaseCustomField>({
  libraryId,
}: UseGetAvailableCustomFields) => {
  const { data: libraryCustomFields } = useLibraryCustomFields({ libraryId });

  const getAvailableCustomFields = useCallback(
    (customFields?: Data[]) => {
      return !!libraryCustomFields?.data.length
        ? customFields?.filter(
            (customField) => !!libraryCustomFields?.data.find((field) => field.id === customField.id)?.visible,
          )
        : customFields;
    },
    [libraryCustomFields?.data],
  );

  const hasHiddenCustomFields = useMemo(() => {
    return libraryCustomFields?.data.some((field) => !field.visible);
  }, [libraryCustomFields?.data]);

  return {
    getAvailableCustomFields,
    hasHiddenCustomFields,
    libraryCustomFields,
  };
};
