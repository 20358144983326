import { permissionsSelector } from '~/store/permissions/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface UseClipPermissionsParams {
  clipId: string | undefined;
}

export const useClipPermissions = ({ clipId }: UseClipPermissionsParams) => {
  const clipPermissions = useAirSelector((st) => permissionsSelector(st, 'clipIds', clipId));

  return {
    clipPermissions,
  };
};
