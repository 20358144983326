import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useCallback } from 'react';

import { getDropdownMenuOption } from '~/utils/menuOptions/getDropdownMenuOption';

export interface UseGetPrivateClipMenuOptionsParams {
  /** Call back to download an alt resolution for a video */
  altResolutions?: DropdownMenuOption[];
  originalResolution?: DropdownMenuOption;
  customFieldsMenuOptions?: DropdownMenuOption[];
  /** Callback for when the user selects to add this clip to a board */
  onAddToBoard?: () => void;
  /** Callback for when the user selects to add tags to this clip */
  onEditTags?: () => void;
  /** Callback for when the user selects to delete this clip */
  onDelete?: () => void;
  /** Callback for when the user selects to download this clip */
  onDownload?: () => void;
  /** Callback for when the user selects to move this clip to a board */
  onMoveToBoard?: () => void;
  /** Callback for when the user selects to open asset details modal */
  onOpen?: () => void;
  onOpenOnDesktop?: () => void;
  /** Callback for when the user selects to remove this clip from the current board */
  onRemoveFromCurrentBoard?: () => void;
  onRenameAsset?: () => void;
  /** Callback for when the user selects to add this clip as a board thumbnail */
  onSetBoardThumbnail?: () => void;
  /** Callback for when the user selects to share this clip */
  onShare?: () => void;
  /** Callback for when the user selects to toggle this clip's bookmarked state */
  onFavorite?: () => void;
  onUnfavorite?: () => void;
}

/**
 * Use this utility to build out the order of the options you'd see in an ellipsis menu for a board.
 * Just pass the functions you want, with the board (and it's permissions).
 */
export const useGetPrivateClipMenuOptions = () => {
  const getClipMenuOptions = useCallback(
    ({
      onAddToBoard,
      onOpen,
      onEditTags,
      onFavorite,
      onUnfavorite,
      onShare,
      onDownload,
      onRemoveFromCurrentBoard,
      onRenameAsset,
      altResolutions,
      originalResolution,
      onDelete,
      customFieldsMenuOptions,
      onOpenOnDesktop,
      onMoveToBoard,
      onSetBoardThumbnail,
    }: UseGetPrivateClipMenuOptionsParams) => {
      const options: DropdownMenuOption[] = [];

      onOpen &&
        options.push({
          ...getDropdownMenuOption('open-asset'),
          onSelect: onOpen,
          type: 'item',
        });

      onShare &&
        options.push({
          ...getDropdownMenuOption('share'),
          onSelect: onShare,
          type: 'item',
        });

      onFavorite &&
        options.push({
          ...getDropdownMenuOption('favorite'),
          onSelect: onFavorite,
          type: 'item',
        });

      onUnfavorite &&
        options.push({
          ...getDropdownMenuOption('unfavorite'),
          onSelect: onUnfavorite,
          type: 'item',
        });

      if (altResolutions && altResolutions.length > 0) {
        const downloadOptionsArray: DropdownMenuOption[] = [];

        if (originalResolution) {
          downloadOptionsArray.push(
            {
              id: 'original-title',
              label: 'original',
              type: 'title',
            },
            originalResolution,
            {
              id: 'original-separator',
              type: 'separator',
            },
          );
        }

        downloadOptionsArray.push({ id: 'proxy-title', label: 'Proxy', type: 'title' }, ...altResolutions);

        options.push({
          ...getDropdownMenuOption('download'),
          options: downloadOptionsArray,
          type: 'sub',
        });
      } else {
        onDownload &&
          options.push({
            ...getDropdownMenuOption('download'),
            onSelect: onDownload,
            type: 'item',
          });
      }

      onOpenOnDesktop &&
        options.push({
          ...getDropdownMenuOption('open-on-desktop'),
          onSelect: onOpenOnDesktop,
          type: 'item',
        });

      onAddToBoard &&
        options.push(
          {
            id: 'add-to-board-separator',
            type: 'separator',
          },
          {
            ...getDropdownMenuOption('add-to-board'),
            onSelect: onAddToBoard,
            type: 'item',
          },
        );

      onSetBoardThumbnail &&
        options.push({ ...getDropdownMenuOption('set-board-thumbnail'), onSelect: onSetBoardThumbnail, type: 'item' });

      onMoveToBoard && options.push({ ...getDropdownMenuOption('move'), onSelect: onMoveToBoard, type: 'item' });

      onRenameAsset && options.push({ ...getDropdownMenuOption('rename'), onSelect: onRenameAsset, type: 'item' });

      onEditTags && options.push({ ...getDropdownMenuOption('edit-tags'), onSelect: onEditTags, type: 'item' });

      customFieldsMenuOptions &&
        customFieldsMenuOptions.length > 0 &&
        options.push({
          ...getDropdownMenuOption('edit-custom-fields'),
          options: customFieldsMenuOptions,
          type: 'sub',
        });

      onRemoveFromCurrentBoard &&
        options.push(
          {
            id: 'remove-from-board-separator',
            type: 'separator',
          },
          {
            ...getDropdownMenuOption('remove-from-board'),
            onSelect: onRemoveFromCurrentBoard,
            type: 'item',
          },
        );

      if (onDelete) {
        if (!onRemoveFromCurrentBoard) {
          options.push({
            id: 'delete-separator',
            type: 'separator',
          });
        }

        options.push({
          ...getDropdownMenuOption('delete'),
          onSelect: onDelete,
          type: 'item',
          className: 'text-red-11',
        });
      }

      return options;
    },
    [],
  );

  return { getClipMenuOptions };
};
