import { useTrackChangedViewType } from '@air/analytics';
import { ViewTypeName } from '@air/api/types';
import { memo, useCallback, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { VIEW_TYPE_MENU_TOGGLE } from '~/constants/testIDs';
import { useApplyFilters } from '~/hooks/filters/useApplyFilters';
import { setCurrentViewTypeAction } from '~/store/configViews/actions';
import { availableViewTypeNamesSelector, currentViewTypeNameSelector } from '~/store/configViews/selectors';

import { ViewTypeToggleMenuComponent } from './ViewTypeToggleMenuComponent';

export interface ViewTypeToggleMenuProps {
  canViewCustomFields?: boolean;
}

export const ViewTypeToggleMenu = memo(({ canViewCustomFields = true }: ViewTypeToggleMenuProps) => {
  const dispatch = useDispatch();
  const currentViewTypeName = useSelector(currentViewTypeNameSelector);
  const viewTypes = useSelector(availableViewTypeNamesSelector);
  const permissionedViewTypeNames = useMemo(
    () => viewTypes.filter((name) => (!canViewCustomFields ? name !== ViewTypeName.kanban : name === name)),
    [canViewCustomFields, viewTypes],
  );
  const { clearFilters } = useApplyFilters();
  const { trackChangedViewType } = useTrackChangedViewType();

  const changeCurrentView = useCallback(
    (viewTypeName: ViewTypeName) => {
      batch(() => {
        if (viewTypeName === ViewTypeName.kanban) {
          clearFilters();
        }
        dispatch(setCurrentViewTypeAction({ viewTypeName }));
      });
      trackChangedViewType({ viewType: viewTypeName });
    },
    [dispatch, clearFilters, trackChangedViewType],
  );

  if (!currentViewTypeName) {
    return null;
  }

  return (
    <ViewTypeToggleMenuComponent
      availableViewTypeNames={permissionedViewTypeNames}
      dataTestId={VIEW_TYPE_MENU_TOGGLE}
      currentViewTypeName={currentViewTypeName}
      onChange={changeCurrentView}
    />
  );
});

ViewTypeToggleMenu.displayName = 'ViewTypeToggleMenu';
