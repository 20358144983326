import { Clip } from '@air/api/types';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';

import { centralizedClipAssetIdSelector } from '~/store/centralizedClip/selectors';

export interface MediaPlayerAssetsInfo {
  itemsIds: { id: Clip['id']; assetId: Clip['assetId'] }[];
  hasMore: boolean;
  loadMore: () => void;
}

export const useBaseMediaPlayerData = (data?: MediaPlayerAssetsInfo) => {
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const itemsIds = data?.itemsIds ?? [];

  const currentAssetIndex = itemsIds.findIndex((item) => item.assetId === assetId);
  const previousAssetInfo = currentAssetIndex > 0 ? itemsIds[currentAssetIndex - 1] : null;
  const nextAssetInfo = currentAssetIndex < itemsIds.length - 1 ? itemsIds[currentAssetIndex + 1] : null;

  const isNearEnd = itemsIds.length - currentAssetIndex < 6 && data?.hasMore;

  return {
    previousId: previousAssetInfo?.id || '',
    nextId: nextAssetInfo?.id || '',
    isNearEnd,
  };
};

export const defaultMediaPlayerAssetInfo: MediaPlayerAssetsInfo = { itemsIds: [], loadMore: noop, hasMore: false };
