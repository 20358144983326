import { TriangleDown } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { FiltersMenu } from '~/components/FiltersMenu/FiltersMenu';
import {
  baseFiltersOptions,
  CustomFieldFilterType,
  FilterSelectType,
  FilterType,
} from '~/components/FiltersMenu/types';
import { customFieldIconMap } from '~/constants/customFieldIconMap';
import { FILTER_SELECT_BTN } from '~/constants/testIDs';
import { changeFilterTypeAction } from '~/store/filters/actions';

export interface FilterSelectProps {
  getFiltersOptions: (onSelect: FilterSelectType) => DropdownMenuOption[];
  currentFilter: FilterType;
  currentCustomField?: CustomFieldFilterType;
  onFilterSelect?: FilterSelectType;
}

export const FilterSelect = memo(
  ({ getFiltersOptions, currentCustomField, currentFilter, onFilterSelect }: FilterSelectProps) => {
    const dispatch = useDispatch();

    const onSelect = useCallback(
      (type: FilterType, customField?: CustomFieldFilterType) => {
        dispatch(
          changeFilterTypeAction({
            newFilter: { type, customField },
            oldFilter: { type: currentFilter, customField: currentCustomField },
          }),
        );
        onFilterSelect?.(type, customField);
      },
      [currentCustomField, currentFilter, dispatch, onFilterSelect],
    );

    const options = useMemo(() => getFiltersOptions(onSelect), [getFiltersOptions, onSelect]);

    const trigger = useMemo(() => {
      const filter = baseFiltersOptions[currentFilter];

      const title = currentCustomField?.name ?? filter.label;
      const Icon = currentCustomField?.type.title ? customFieldIconMap[currentCustomField.type.title] : filter.icon;

      return (
        <Button
          appearance="ghost"
          color="grey"
          data-testid={FILTER_SELECT_BTN}
          prefix={!!Icon && <Icon className="h-4 w-4" />}
          size="medium"
          suffix={<TriangleDown className="h-4 w-4" />}
        >
          <span className="truncate">{title}</span>
        </Button>
      );
    }, [currentCustomField?.name, currentCustomField?.type.title, currentFilter]);

    return <FiltersMenu options={options} trigger={trigger} />;
  },
);

FilterSelect.displayName = 'FilterSelect';
