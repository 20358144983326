import { AirActionTrackingLocation } from '@air/analytics';
import { Clip, ShortUrlResponse } from '@air/api/types';
import { TabPanel } from '@air/primitive-tabs';
import { memo, useMemo, useState } from 'react';

import { SharePrivateClipLinkInfo } from '~/components/SharePrivateClipModal/SharePrivateClipLinkInfo';
import { SharePrivateClipSettings } from '~/components/SharePrivateClipModal/SharePrivateClipSettings';
import { SharePrivateContentTabs } from '~/components/SharePrivateContentModal/SharePrivateContentTabs';

interface SharePrivateClipTabsProps {
  clip: Pick<Clip, 'id' | 'assetVersionCount' | 'ext'>;
  trackLocation: AirActionTrackingLocation;
  boardContext: string | undefined;
  shortIdInfo: ShortUrlResponse;
}

export const SharePrivateClipTabs = memo(
  ({ clip, shortIdInfo, trackLocation, boardContext }: SharePrivateClipTabsProps) => {
    const [activeTab, setActiveTab] = useState('link');

    const panels = useMemo<TabPanel[]>(() => {
      return [
        {
          id: 'link',
          label: 'Link',
          content: (
            <SharePrivateClipLinkInfo
              shortIdInfo={shortIdInfo}
              clip={clip}
              trackLocation={trackLocation}
              goToSettings={() => setActiveTab('settings')}
              boardContext={boardContext}
            />
          ),
        },
        {
          id: 'settings',
          label: 'Settings',
          content: <SharePrivateClipSettings clipId={clip.id} shortIdInfo={shortIdInfo} />,
        },
      ];
    }, [boardContext, clip, shortIdInfo, trackLocation]);

    return <SharePrivateContentTabs setActiveTab={setActiveTab} activeTab={activeTab} panels={panels} />;
  },
);

SharePrivateClipTabs.displayName = 'SharePrivateClipTabs';
