import { AirActionTrackingLocation, useTrackAddedTagsToAssets } from '@air/analytics';
import { Tags } from '@air/api';
import { Clip } from '@air/api/types';
import { useCallback } from 'react';

import { TagBase } from '~/components/TagsSelect/utils';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export const useAddTagsToClips = () => {
  const { trackAddedTagsToAssets } = useTrackAddedTagsToAssets();
  const { currentWorkspace } = useCurrentWorkspace();

  const addTagsToClips = useCallback(
    async ({
      tags,
      clipIds,
      trackLocation,
    }: {
      tags: TagBase[];
      clipIds: Clip['id'][];
      trackLocation: AirActionTrackingLocation;
    }) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      try {
        await Tags.addOrCreateTagsOnClips({ workspaceId, tags, clips: clipIds });
        trackAddedTagsToAssets({
          assets: clipIds,
          tags: tags.map((tag) => ({ ...tag, isDemo: false })),
          location: trackLocation,
        });
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to add tags to clips',
          metadata: {
            data: {
              tags,
              clipIds,
            },
          },
        });
      }
    },
    [trackAddedTagsToAssets, currentWorkspace?.id],
  );

  return {
    addTagsToClips,
  };
};
