import { memo } from 'react';

import {
  BulkEditSingleSelectCustomFieldModal,
  BulkEditSingleSelectCustomFieldModalProps,
} from '~/components/Modals/BulkEditCustomFieldModal/BulkEditSingleSelectCustomFieldModal';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useGetPrivateCustomFieldOptions } from '~/swr-hooks/customFields/useGetPrivateCustomFieldOptions';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export type PrivateBulkEditSingleSelectCustomFieldModalProps = Omit<
  BulkEditSingleSelectCustomFieldModalProps,
  'cfOptions' | 'onSave'
>;

export const PrivateBulkEditSingleSelectCustomFieldModal = memo(
  (props: AirModalProps<PrivateBulkEditSingleSelectCustomFieldModalProps>) => {
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

    const { data: cfOptions } = useGetPrivateCustomFieldOptions({
      customFieldId: props.field.id,
    });

    return (
      <BulkEditSingleSelectCustomFieldModal
        {...props}
        cfOptions={cfOptions}
        onSave={async (params) => {
          await updateItemsCustomFieldValue({
            ...params,
            trackingLocation: 'context-menu-bulk-edit',
            workspaceId,
          });
        }}
      />
    );
  },
);

PrivateBulkEditSingleSelectCustomFieldModal.displayName = 'PrivateBulkEditSingleSelectCustomFieldModal';
