import { CardSize } from '@air/api/types';
import { ChevronDown } from '@air/next-icons';
import { input } from '@air/primitive-input';
import { memo } from 'react';

import { useCardSizePreference } from '~/hooks/useCardSizePreference';

const cardSizes: { label: string; value: CardSize }[] = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
  { label: 'Extra Large', value: 'extra-large' },
];

export const ViewCardSizePreference = memo(() => {
  const { cardSize, updateCardSize } = useCardSizePreference();

  return (
    <>
      <div className="flex items-center justify-between gap-6 px-2 py-1">
        <label className="text-14 text-grey-12" htmlFor="preferences">
          Size
        </label>
        <div className={input({ className: 'relative px-0 text-12', size: 'small' })}>
          <select
            id="cardSize"
            defaultValue={cardSize}
            className="h-full w-full cursor-pointer appearance-none bg-transparent pl-2 pr-8"
            onChange={(event) => {
              updateCardSize(event.target.value as CardSize);
            }}
            value={cardSize}
          >
            {cardSizes.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <ChevronDown className="pointer-events-none absolute right-2 top-1/2 h-4 w-4 -translate-y-1/2" />
        </div>
      </div>
    </>
  );
});

ViewCardSizePreference.displayName = 'ViewCardSizePreference';
