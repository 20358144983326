import { ShortUrl } from '@air/api';
import { useShortIdContext } from '@air/provider-short-id';
import { useQuery } from '@tanstack/react-query';

import { getCustomFieldOptionsKey } from '~/constants/react-query-keys';

type UseGetPublicCustomFieldOptionsParams = {
  customFieldId?: string;
  enabled?: boolean;
  staleTime?: number;
};

export const useGetPublicCustomFieldOptions = ({
  customFieldId,
  staleTime,
  enabled,
}: UseGetPublicCustomFieldOptionsParams) => {
  const { shortId } = useShortIdContext();

  return useQuery({
    queryKey: getCustomFieldOptionsKey(customFieldId),

    queryFn: () => {
      if (!customFieldId) {
        throw new Error('No custom field id');
      }

      return ShortUrl.listCustomFieldValues(shortId, customFieldId);
    },
    enabled: !!customFieldId && enabled,
    staleTime,
  });
};
