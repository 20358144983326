import { memo } from 'react';

import { DateFilter, DateFilterProps } from '~/components/Filters/DateFilter/DateFilter';
import { DateRangeType } from '~/store/filters/types';

export interface CreatedDateFilterProps extends Omit<DateFilterProps, 'selectedType'> {
  dateType: DateRangeType;
}

export const CreatedDateFilter = memo(
  ({ dateType, endDate, onTypeChange, onStartDateChange, startDate, onEndDateChange }: CreatedDateFilterProps) => {
    return (
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        selectedType={dateType}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
        onTypeChange={onTypeChange}
      />
    );
  },
);

CreatedDateFilter.displayName = 'CreatedDateFilter';
