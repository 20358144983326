import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { CustomFieldFilter } from '~/components/Filters/CustomFieldFilter/CustomFieldFilter';
import { CustomFieldValueFilterBase } from '~/components/Filters/CustomFieldFilter/utils';
import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import { CustomFieldFilterType } from '~/components/FiltersMenu/types';
import {
  clearCustomFieldFilterValuesAction,
  removeFilterAction,
  setCustomFieldFilterLogicAction,
  setCustomFieldFilterValuesAction,
} from '~/store/filters/actions';
import { customFieldFilterLogicSelector, customFieldFilterValuesSelector } from '~/store/filters/selectors';
import { FilterLogicType, SelectedFilter } from '~/store/filters/types';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface BaseCustomFieldFilterCardProps extends BaseFilterCardProps {
  customField: CustomFieldFilterType;
  values: CustomFieldValueFilterBase[];
}

export const BaseCustomFieldFilterCard = memo(({ customField, values, ...props }: BaseCustomFieldFilterCardProps) => {
  const dispatch = useDispatch();
  const { id: customFieldId } = customField;

  const clearValues = useCallback(() => {
    dispatch(clearCustomFieldFilterValuesAction({ customFieldId }));
  }, [customFieldId, dispatch]);

  const selectedLogic = useAirSelector((state) => customFieldFilterLogicSelector(state, customFieldId));
  const selectedValues = useAirSelector((state) => customFieldFilterValuesSelector(state, customFieldId));

  const onLogicChange = useCallback(
    (logic: FilterLogicType) => dispatch(setCustomFieldFilterLogicAction({ customFieldId, logic })),
    [dispatch, customFieldId],
  );

  const onSelectedValuesChanged = useCallback(
    (values: CustomFieldValueFilterBase[]) => dispatch(setCustomFieldFilterValuesAction({ customFieldId, values })),
    [dispatch, customFieldId],
  );

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
    clearValues();
  };

  return (
    <FilterCard
      currentFilter="customField"
      currentCustomField={customField}
      onClearClick={clearValues}
      onRemoveFilter={onRemoveFilter}
      {...props}
    >
      <CustomFieldFilter
        values={values}
        onSelectedValuesChange={onSelectedValuesChanged}
        onLogicChange={onLogicChange}
        selectedLogic={selectedLogic}
        selectedValues={selectedValues}
      />
    </FilterCard>
  );
});

BaseCustomFieldFilterCard.displayName = 'BaseCustomFieldFilterCard';
