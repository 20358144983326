import { memo } from 'react';

import {
  BulkEditSingleSelectCustomFieldModal,
  BulkEditSingleSelectCustomFieldModalProps,
} from '~/components/Modals/BulkEditCustomFieldModal/BulkEditSingleSelectCustomFieldModal';
import { useGetPublicCustomFieldOptions } from '~/swr-hooks/customFields/useGetPublicCustomFieldOptions';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export type PublicBulkEditSingleSelectCustomFieldModalProps = Omit<
  BulkEditSingleSelectCustomFieldModalProps,
  'cfOptions' | 'onSave'
>;

export const PublicBulkEditSingleSelectCustomFieldModal = memo(
  (props: AirModalProps<PublicBulkEditSingleSelectCustomFieldModalProps>) => {
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

    const { data: cfOptions } = useGetPublicCustomFieldOptions({
      customFieldId: props.field.id,
    });

    return (
      <BulkEditSingleSelectCustomFieldModal
        {...props}
        cfOptions={cfOptions}
        onSave={async (params) => {
          await updateItemsCustomFieldValue({
            ...params,
            trackingLocation: 'context-menu-bulk-edit',
            workspaceId: undefined,
          });
        }}
      />
    );
  },
);

PublicBulkEditSingleSelectCustomFieldModal.displayName = 'PublicBulkEditSingleSelectCustomFieldModal';
