import { TrackTagLocation, useTrackNewTagCreated } from '@air/analytics';
import { Tags } from '@air/api';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useAddTagsToList } from '~/utils/mutateUtils/Tags/Tags';

export const useCreateTag = () => {
  const { addTagsToList } = useAddTagsToList();
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackNewTagCreated } = useTrackNewTagCreated();

  const createTag = useCallback(
    async (name: string, trackLocation: TrackTagLocation) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      try {
        const { tags } = await Tags.create({ workspaceId, labels: [name] });
        addTagsToList(
          workspaceId,
          tags.map((tag) => ({ ...tag, workspaceId, count: 0 })),
        );
        trackNewTagCreated({ location: trackLocation });
        return tags;
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to create tag',
          metadata: {
            data: {
              name,
            },
          },
        });
      }
    },
    [addTagsToList, currentWorkspace?.id, trackNewTagCreated],
  );

  return {
    createTag,
  };
};
