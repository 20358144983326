import { PublicClip } from '@air/api';
import { BaseCustomField, Board, Clip, CustomField, CustomFieldTypeName, PublicBoard } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { useCallback } from 'react';

import { PublicBulkEditDateValueCustomFieldModal } from '~/components/Modals/BulkEditCustomFieldModal/PublicBulkEditDateValueCustomFieldModal';
import { PublicBulkEditMultiSelectCustomFieldModal } from '~/components/Modals/BulkEditCustomFieldModal/PublicBulkEditMultiSelectCustomFieldModal';
import { PublicBulkEditPlainTextCustomFieldModal } from '~/components/Modals/BulkEditCustomFieldModal/PublicBulkEditPlainTextCustomFieldModal';
import { PublicBulkEditSingleSelectCustomFieldModal } from '~/components/Modals/BulkEditCustomFieldModal/PublicBulkEditSingleSelectCustomFieldModal';
import { BulkEditCustomFieldModalProps } from '~/components/Modals/BulkEditCustomFieldModal/types';
import { AssignedCustomFieldValue } from '~/swr-hooks/customFields/types';

export interface ShowCustomFieldBulkEditModalParams {
  boards: (Board | PublicBoard)[];
  assets: (Clip | PublicClip)[];
  field: BaseCustomField;
}

const getCurrentValuesForCustomField = (items: (Board | PublicBoard | Clip | PublicClip)[], field: CustomField) => {
  return items.reduce((acc: AssignedCustomFieldValue[], item) => {
    const customFieldsLookup: AssignedCustomFieldValue[] = item.customFields || [];
    const currentValue = customFieldsLookup.find((cf) => cf.id === field.id);
    acc.push(currentValue ? currentValue : { value: null, values: null, ...field });
    //                                     ^
    // We want to include an empty custom field for comparison purposes.
    return acc;
  }, []);
};

export const useShowPublicCustomFieldBulkEditModal = () => {
  const [showBulkEditSingleSelectCustomFieldModal] = useAirModal(PublicBulkEditSingleSelectCustomFieldModal);
  const [showBulkEditMultiSelectCustomFieldModal] = useAirModal(PublicBulkEditMultiSelectCustomFieldModal);
  const [showBulkEditPlainTextCustomFieldModal] = useAirModal(PublicBulkEditPlainTextCustomFieldModal);
  const [showBulkEditDateValueCustomFieldModal] = useAirModal(PublicBulkEditDateValueCustomFieldModal);

  const showBulkEditModal = useCallback(
    ({ field, assets, boards }: ShowCustomFieldBulkEditModalParams) => {
      const boardIds = boards.map((b) => b.id);
      const assetIds = assets.map((a) => a.assetId);
      const combinedResources = [...boards, ...assets];

      const modalProps: BulkEditCustomFieldModalProps = {
        field,
        assetIds,
        boardIds,
        initialValues: getCurrentValuesForCustomField(combinedResources, field),
      };

      switch (field.type.title) {
        case CustomFieldTypeName.singleSelect: {
          return showBulkEditSingleSelectCustomFieldModal(modalProps);
        }
        case CustomFieldTypeName.multiSelect: {
          return showBulkEditMultiSelectCustomFieldModal(modalProps);
        }
        case CustomFieldTypeName.plainText: {
          return showBulkEditPlainTextCustomFieldModal(modalProps);
        }
        case CustomFieldTypeName.date: {
          return showBulkEditDateValueCustomFieldModal(modalProps);
        }
      }
    },
    [
      showBulkEditDateValueCustomFieldModal,
      showBulkEditMultiSelectCustomFieldModal,
      showBulkEditPlainTextCustomFieldModal,
      showBulkEditSingleSelectCustomFieldModal,
    ],
  );

  return {
    showBulkEditModal,
  };
};
