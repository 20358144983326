import { AirActionTrackingLocation, useTrackSetCustomBoardThumbnail } from '@air/analytics';
import { Board, Clip } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';
import { useToasts } from '@air/provider-toast';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PrivateBoardSearch, PrivateBoardSearchProps } from '~/components/BoardSearch/PrivateBoardSearch';
import { useSingleBoardSearchState } from '~/components/BoardSearch/shared/hooks';
import { ToastLink } from '~/components/UI/ToastLink';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { setCentralizedBoardAction } from '~/store/centralizedBoard/actions';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { updateCentralizedClipBoardThumbnails } from '~/store/centralizedClip/actions';
import { useSetBoardThumbnail } from '~/swr-hooks/boards/useSetBoardThumbnail';
import { BoardsSelectOption } from '~/types/BoardSearch';
import { canChangeBoardDetails } from '~/utils/permissions/boardPermissions';

type BoardData = Pick<Board, 'id' | 'title'>;

export interface SetBoardThumbnailModalProps {
  clip: Pick<Clip, 'id' | 'assets'>;
  trackLocation: AirActionTrackingLocation;
}

export const SetBoardThumbnailModal = ({
  onClose,
  trackLocation,
  clip,
}: AirModalProps<SetBoardThumbnailModalProps>) => {
  const dispatch = useDispatch();
  const { goToBoardPage } = useGoToBoardPage();
  const { setBoardThumbnail } = useSetBoardThumbnail();
  const { showToast } = useToasts();
  const currentBoard = useSelector(centralizedBoardSelector);
  const [isAddingThumbnail, setIsAddingThumbnail] = useState(false);
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const { trackSetCustomBoardThumbnail } = useTrackSetCustomBoardThumbnail();
  const { getBoardPermissions } = useBoardPermissionsCache();

  const {
    selectedBoard,
    isBoardSelected,
    shouldShowSubBoards,
    onSelectBoard,
    isLibrarySelected,
    shouldShowLibraryBoards,
    onSelectLibrary,
  } = useSingleBoardSearchState({
    initialBoard: currentBoard,
  });

  const onBoardSelectChange: PrivateBoardSearchProps['onBoardSelectChange'] = useCallback(
    ({ board, selected }) => {
      onSelectBoard(selected ? board : null);
    },
    [onSelectBoard],
  );

  const getIsBoardDisabled: Required<PrivateBoardSearchProps>['getIsBoardDisabled'] = useCallback(
    (board) => {
      const boardPermissions = getBoardPermissions(board.id);

      if (!canChangeBoardDetails(boardPermissions)) {
        return {
          isDisabled: true,
          message: 'You do not have permission to edit this board',
        };
      }
    },
    [getBoardPermissions],
  );

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const goToBoard = (board: BoardData) => {
    goToBoardPage({ board, trackLocation: 'set-custom-board-thumbnail-toast' });
  };

  const processBoardThumbnail = async (selectedBoard: BoardsSelectOption) => {
    const isSelectedBoardCurrentBoard = currentBoard?.id === selectedBoard.id;

    try {
      const newThumbnailBoard = await setBoardThumbnail({ boardId: selectedBoard.id, clip });
      if (!isSelectedBoardCurrentBoard) {
        dispatch(updateCentralizedClipBoardThumbnails({ boardId: selectedBoard.id, newThumbnail: clip.assets.image }));
      } else if (!!newThumbnailBoard) {
        dispatch(setCentralizedBoardAction({ board: newThumbnailBoard }));
      }
    } catch {
      // TODO: we should probably have ui to handle this case
    }
    return selectedBoard;
  };

  const isUpdatingCurrentBoard = (board: BoardData) => board.id === currentBoard?.id;

  const logCustomBoardThumbnailEvent = ({ board }: { board: BoardData }) => {
    trackSetCustomBoardThumbnail({
      clipId: clip.id,
      boardId: board.id,
      setCurrentBoardThumbnail: isUpdatingCurrentBoard(board),
      location: trackLocation,
    });
  };

  const showConfirmationToast = (board: BoardData) => {
    showToast(
      <>
        {!isUpdatingCurrentBoard(board) ? (
          <ToastLink onClick={() => goToBoard(board)}>{board.title}</ToastLink>
        ) : (
          board.title
        )}
        {` thumbnail has been updated`}
      </>,
    );
  };

  const handleAddClick = async () => {
    if (!selectedBoard) {
      throw new Error('No board selected');
    }

    setIsAddingThumbnail(true);
    try {
      const board = await processBoardThumbnail(selectedBoard);
      if (board) {
        showConfirmationToast(board);
        logCustomBoardThumbnailEvent({ board });
      }
      onClose();
    } catch (_error) {
      setIsAddingThumbnail(false);
      showToast('An error occurred - Please try again later');
    }
  };

  return (
    <Modal data-testid="SET_BOARD_THUMBNAIL_MODAL" dangerouslyBypassFocusLock isOpen onDismiss={onClose}>
      <div className="mb-3 flex justify-between">
        <ModalTitle>Set as board thumbnail</ModalTitle>
        <ModalCloseButton className="shrink-0" onClick={onClose} />
      </div>
      <ModalDescription className="mb-4">Choose a board to update.</ModalDescription>
      <PrivateBoardSearch
        isBoardSelected={isBoardSelected}
        onBoardSelectChange={onBoardSelectChange}
        shouldShowSubBoards={shouldShowSubBoards}
        initialBoardId={currentBoard?.id}
        isLibrarySelected={isLibrarySelected}
        shouldShowLibraryBoards={shouldShowLibraryBoards}
        onSelectLibrary={onSelectLibrary}
        shouldExpandLibraryInsteadOfSelect={true}
        getIsBoardDisabled={getIsBoardDisabled}
      />
      <div className="mt-6 flex justify-end gap-3">
        <Button appearance="ghost" color="grey" onClick={onClose} size="large">
          Cancel
        </Button>
        <Button
          appearance="filled"
          color="blue"
          disabled={isAddingThumbnail || !selectedBoard}
          size="large"
          onClick={() => handleAddClick()}
        >
          {isAddingThumbnail ? 'Applying…' : 'Apply'}
        </Button>
      </div>
    </Modal>
  );
};
