import { CustomFieldValue, CustomFieldValueListResponse } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import pluralize from 'pluralize';
import React, { memo, useCallback, useMemo, useState } from 'react';

import { Select, SelectProps } from '~/components/Select/Select';
import { DefaultChipType } from '~/components/Select/shared/types';
import { BULK_EDIT_SINGLE_SELECT_MODAL, CUSTOM_FIELD_SELECT_COMPONENT } from '~/constants/testIDs';
import { getCustomFieldSelectOption } from '~/utils/CustomFields';

import { BulkEditCustomFieldModalProps } from './types';

export interface BulkEditSingleSelectCustomFieldModalProps extends BulkEditCustomFieldModalProps {
  cfOptions: CustomFieldValueListResponse | undefined;
  onSave: (props: {
    assetIds: string[];
    boardIds: string[];
    nextValue: CustomFieldValue | null | undefined;
    baseCustomField: BulkEditCustomFieldModalProps['field'];
  }) => Promise<void>;
}

export const BulkEditSingleSelectCustomFieldModal = memo(
  ({
    field,
    assetIds,
    boardIds,
    initialValues,
    onClose,
    cfOptions,
    onSave,
  }: AirModalProps<BulkEditSingleSelectCustomFieldModalProps>) => {
    const allHaveSameValue = useMemo(() => {
      const baseValue = initialValues[0].value;
      return initialValues.every((cf) => cf.value?.id === baseValue?.id || cf.value === baseValue);
    }, [initialValues]);

    const [values, setValues] = useState<DefaultChipType[]>(() => {
      if (!initialValues.length) return [];
      const baseValue = initialValues[0].value;
      if (!baseValue) return [];
      if (allHaveSameValue) return [getCustomFieldSelectOption(baseValue)];
      return [];
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const selectOptions = useMemo(() => {
      if (!cfOptions) return [];
      return cfOptions.data.map((option) => getCustomFieldSelectOption(option));
    }, [cfOptions]);
    const onSelectionChange: SelectProps<DefaultChipType>['onSelectionChange'] = useCallback(({ chips }) => {
      setIsTouched(true);
      setValues(chips);
    }, []);

    const onConfirm = useCallback(async () => {
      setIsSubmitting(true);
      const [rawVal] = values;
      const nextValue = (rawVal && cfOptions?.data.find((v) => v.id === rawVal.value)) || null;

      await onSave({
        assetIds,
        boardIds,
        baseCustomField: field,
        nextValue,
      });
      onClose();
    }, [assetIds, boardIds, cfOptions?.data, field, onClose, onSave, values]);

    const placeholder = useMemo(() => {
      if (isTouched) return 'None';
      if (allHaveSameValue) return 'Find an option';
      return 'Multiple Values';
    }, [allHaveSameValue, isTouched]);

    return (
      <Modal isOpen data-testid={BULK_EDIT_SINGLE_SELECT_MODAL} onDismiss={onClose}>
        <div className="flex flex-col gap-6">
          <header className="flex justify-between gap-4">
            <ModalTitle>Edit {field.name}</ModalTitle>
            <ModalCloseButton className="shrink-0" onClick={onClose} />
          </header>
          <p className="text-16 text-grey-11">
            Choose or remove a value below to apply to the {pluralize('item', assetIds.length + boardIds.length, true)}{' '}
            you’ve selected.
          </p>
          <Select
            autoFocus
            closeMenuOnSelect
            data-testid={`${CUSTOM_FIELD_SELECT_COMPONENT}-${field.name}`}
            options={selectOptions}
            placeholder={placeholder}
            selectedOptions={values}
            isSearchable
            className="w-full"
            maxDropdownHeight={300}
            onSelectionChange={onSelectionChange}
            isSingleSelect
            isClearable
            size="extra-large"
            isLoading={!cfOptions}
          />
          <footer className="flex justify-end gap-3">
            <Button appearance="ghost" color="grey" onClick={onClose} size="large">
              Cancel
            </Button>
            <Button
              data-testid="BULK_EDIT_SINGLE_SELECT_CUSTOM_FIELD_MODAL_SAVE"
              isLoading={isSubmitting}
              disabled={!isTouched || isSubmitting}
              appearance="filled"
              color="blue"
              size="large"
              onClick={onConfirm}
            >
              Save
            </Button>
          </footer>
        </div>
      </Modal>
    );
  },
);
BulkEditSingleSelectCustomFieldModal.displayName = 'BulkEditSingleSelectCustomFieldModal';
