import { Comment as CommentIcon, Stack as StackIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import classNames from 'classnames';
import React, { memo } from 'react';

export interface AssetMetaDataProps {
  assetVersionCount: number;
  onCommentsClick?: () => void;
  onVersionsClick?: () => void;
  openCommentCount: number;
  shouldShowDiscussions: boolean;
  shouldShowVersions: boolean;
}

export const AssetMetaData = memo(
  ({
    assetVersionCount,
    onCommentsClick,
    onVersionsClick,
    openCommentCount,
    shouldShowDiscussions,
    shouldShowVersions,
  }: AssetMetaDataProps) => {
    const showVersions = shouldShowVersions && assetVersionCount > 1;
    const showDiscussions = shouldShowDiscussions && openCommentCount > 0;

    return (
      <div className="pointer-events-none -ml-1 flex items-center gap-1">
        {showVersions && (
          <Button
            appearance="ghost"
            className={classNames('h-5 px-1', !!onVersionsClick ? 'pointer-events-auto' : 'pointer-events-none')}
            color="grey"
            data-disableselect="true"
            data-testid="TABLE_ROW_VERSIONS_INDICATOR"
            onClick={(event) => {
              event.preventDefault();
              onVersionsClick?.();
            }}
            /**
             * Prevents the button from triggering selection of the row when clicking on the button.
             */
            onMouseDown={(event) => event.stopPropagation()}
            prefix={<StackIcon className="h-3 w-3" />}
            size="small"
          >
            {assetVersionCount}
          </Button>
        )}

        {showDiscussions && (
          <Button
            appearance="ghost"
            className={classNames('h-5 px-1', !!onCommentsClick ? 'pointer-events-auto' : 'pointer-events-none')}
            color="grey"
            data-disableselect="true"
            data-testid="TABLE_ROW_DISCUSSIONS_INDICATOR"
            onClick={(event) => {
              event.preventDefault();
              onCommentsClick?.();
            }}
            /**
             * Prevents the button from triggering selection of the row when clicking on the button.
             */
            onMouseDown={(event) => event.stopPropagation()}
            prefix={<CommentIcon className="h-3 w-3" />}
            size="small"
          >
            {openCommentCount}
          </Button>
        )}
      </div>
    );
  },
);

AssetMetaData.displayName = 'AssetMetaData';
