import { Color } from '@air/api/types';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ColorFilter, ColorFilterProps } from '~/components/Filters/ColorFilter/ColorFilter';
import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import {
  addColorFilterValueAction,
  clearColorFilterValuesAction,
  removeColorFilterValueAction,
  removeFilterAction,
} from '~/store/filters/actions';
import { selectedColorsFiltersSelector } from '~/store/filters/selectors';
import { SelectedFilter } from '~/store/filters/types';

export interface ColorFilterCardProps extends BaseFilterCardProps {
  colors: ColorFilterProps['colors'];
}

export const ColorFilterCard = memo(({ colors, ...props }: ColorFilterCardProps) => {
  const dispatch = useDispatch();
  const selectedColors = useSelector(selectedColorsFiltersSelector);

  const clearValues = useCallback(() => {
    dispatch(clearColorFilterValuesAction());
  }, [dispatch]);

  const onClick = (color: Color) => (isSelected: boolean) => {
    if (isSelected) {
      dispatch(addColorFilterValueAction({ color: color.hex }));
    } else {
      dispatch(removeColorFilterValueAction({ color: color.hex }));
    }
  };

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
    clearValues();
  };

  return (
    <FilterCard currentFilter="color" onClearClick={clearValues} onRemoveFilter={onRemoveFilter} {...props}>
      <ColorFilter colors={colors} onClick={onClick} selectedColors={selectedColors} />
    </FilterCard>
  );
});

ColorFilterCard.displayName = 'ColorFilterCard';
