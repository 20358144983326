import { CardSize } from '@air/api/types';
import { useCallback, useSyncExternalStore } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CardSizeListener, CardSizeStore } from '~/classes/CardSizeStore';
import { useRouterSelector } from '~/hooks/useRouterSelector';
import { centralizedBoardLibraryIdSelector, CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';
import { setCardSizeAction } from '~/store/configViews/actions';
import { currentCardSizeSelector } from '~/store/configViews/selectors';

const getServerSnapshot = (): CardSize => 'medium';

export const useCardSizePreference = () => {
  const dispatch = useDispatch();
  const boardId = useSelector(CentralizedBoardSelectors.boardId);
  const libraryId = useSelector(centralizedBoardLibraryIdSelector);
  const pathname = useRouterSelector((state) => state.pathname);
  const configViewCardSize = useSelector(currentCardSizeSelector);

  const key = boardId || libraryId || pathname || '';

  const getCardSize = useCallback(() => {
    return configViewCardSize ?? CardSizeStore.getInstance().getCardSize(key);
  }, [key, configViewCardSize]);

  const subscribe = useCallback(
    (callback: CardSizeListener) => CardSizeStore.getInstance().subscribe(key, callback),
    [key],
  );

  const updateCardSize = useCallback((value: CardSize) => {
    if (boardId) {
      dispatch(setCardSizeAction({ cardSize: value }));
    }
    CardSizeStore.getInstance().setCardSize(key, value);
  }, [key, boardId, dispatch]);

  const cardSize = useSyncExternalStore(subscribe, getCardSize, getServerSnapshot);

  return { cardSize, updateCardSize };
};
