import { memo } from 'react';

import { BulkEditMultiSelectCustomFieldModal } from '~/components/Modals/BulkEditCustomFieldModal/BulkEditMultiSelectCustomFieldModal';
import { useGetPublicCustomFieldOptions } from '~/swr-hooks/customFields/useGetPublicCustomFieldOptions';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

import { BulkEditCustomFieldModalProps as BulkEditMultiSelectCustomFieldModalProps } from './types';

export type PublicBulkEditMultiSelectCustomFieldModalProps = Omit<
  BulkEditMultiSelectCustomFieldModalProps,
  'onSave' | 'cfOptions'
>;

export const PublicBulkEditMultiSelectCustomFieldModal = memo(
  (props: AirModalProps<PublicBulkEditMultiSelectCustomFieldModalProps>) => {
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

    const { data: cfOptions } = useGetPublicCustomFieldOptions({
      customFieldId: props.field.id,
    });

    return (
      <BulkEditMultiSelectCustomFieldModal
        {...props}
        cfOptions={cfOptions}
        onSave={async (params) => {
          await updateItemsCustomFieldValue({
            ...params,
            trackingLocation: 'context-menu-bulk-edit',
            workspaceId: undefined,
          });
        }}
      />
    );
  },
);
PublicBulkEditMultiSelectCustomFieldModal.displayName = 'PublicBulkEditMultiSelectCustomFieldModal';
