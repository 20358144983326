import { PublicClip } from '@air/api';
import { Board, Clip, ClipAndBoardListItem, ListResponse, PublicBoard } from '@air/api/types';

import { UseGalleryMixedData } from '~/swr-hooks/gallery/types';

export const useTableViewItems = <B extends Board | PublicBoard, C extends Clip | PublicClip>({
  data,
  loadMore,
  isLoading,
  isLoadingMore,
  hasMore,
}: {
  data: ListResponse<ClipAndBoardListItem>[];
  loadMore: () => void;
  isLoading: boolean;
  hasMore: boolean;
  isLoadingMore: boolean;
}): UseGalleryMixedData<B, C> => {
  const loadNextPage = () => {
    if (data && hasMore && !isLoading) {
      loadMore();
    }
  };

  const itemsData = data
    ? data.reduce(
        (acc, curr) => {
          const pageData = curr.data.map(({ type, data }) => ({
            type,
            item: data as Board | Clip,
          }));
          //@ts-ignore
          acc.push(...pageData);
          return acc;
        },
        [] as UseGalleryMixedData<B, C>['data'],
      )
    : [];

  return {
    data: itemsData,
    total: data ? data[data.length - 1]?.total : 0,
    hasMore,
    isLoading,
    isLoadingMore,
    loadNextPage,
    isEmpty: !!data && !isLoading && !data.some((items) => items.data.length > 0),
  };
};
