import { AirActionTrackingLocation } from '@air/analytics';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useAirModal } from '@air/provider-modal';
import { resetSelectedItemsAction, selectedItemsCountSelector } from '@air/redux-selected-items';
import produce from 'immer';
import { intersectionBy } from 'lodash';
import pluralize from 'pluralize';
import { useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';

import { GetSelectionMenuOptionsType } from '~/components/Gallery/types';
import { useAddAssetsToBoardsModal } from '~/components/Modals/AddAssetsToBoardsModal/useAddAssetsToBoardsModal';
import { DeleteAssetsAndBoardsModal } from '~/components/Modals/DeleteAssetsAndBoards';
import { useDeleteBoardsModal } from '~/components/Modals/DeleteBoards/useDeleteBoardsModal';
import { DeletePrivateClipsModal } from '~/components/Modals/DeletePrivateClipsModal';
import { useDuplicateBoard } from '~/components/Modals/DuplicateBoard/hooks/useDuplicateBoard';
import { EditClipsTagsModal } from '~/components/Modals/EditClipsTagsModal';
import { useMoveItemsModal } from '~/components/Modals/MoveItemsModal/useMoveItemsModal';
import { useRemoveAssetsFromBoardModal } from '~/components/Modals/RemoveClipsFromBoardModal/hooks/useRemoveAssetsFromBoardModal';
import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { useAssetPermissionsCache } from '~/hooks/useAssetPermissionsCache';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useClipPermissionsCache } from '~/hooks/useClipPermissionsCache';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { CentralizedBoardSelectors, centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { isFlattenedViewSelector } from '~/store/router/selectors';
import {
  hasOnlyBoardsSelectedSelector,
  hasOnlyClipsSelectedSelector,
  selectedBoardsIdsSelector,
  selectedBoardsSelector,
  selectedClipsHasUnfavorited,
  selectedClipsIdsInfoSelector,
  selectedClipsSelector,
  selectedPrivateBoardsSelector,
} from '~/store/selectedItems/selectors';
import { useGetBoardContainerPermissions } from '~/swr-hooks/boards/useGetBoardContainerPermissions';
import { useToggleFavorite } from '~/swr-hooks/clips/useToggleFavorite';
import { useActiveSubscriptionCallback } from '~/swr-hooks/subscriptions/useActiveSubscriptionCallback';
import { getDropdownMenuOption } from '~/utils/menuOptions/getDropdownMenuOption';
import {
  canAddAssetToBoard,
  canChangeAssetCustomFields,
  canDeleteAsset,
  canMoveAsset,
  canSeeApplicableClipTags,
} from '~/utils/permissions/assetPermissions';
import {
  canChangeBoardCustomFields,
  canDeleteBoard,
  canDuplicateBoard,
  canRemoveAssetsFromBoard,
} from '~/utils/permissions/boardPermissions';
import { canGenerateZip } from '~/utils/permissions/taskPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

import { useDownloadPrivateItems } from './useDownloadPrivateItems';
import { useGetPrivateCustomFieldsMenuOptions } from './useGetCustomFieldsMenuOptions';

export const usePrivateSelectionMenuOptions = () => {
  const { isSearchActive } = useIsSearchActive();

  const [showEditTagsToClipsModal] = useAirModal(EditClipsTagsModal);
  const { showRemoveAssetsFromBoardModal } = useRemoveAssetsFromBoardModal();
  const { showAddAssetsToBoardsModal } = useAddAssetsToBoardsModal();
  const [showDeletePrivateClipsModal] = useAirModal(DeletePrivateClipsModal);
  const { showDeleteBoardsModal } = useDeleteBoardsModal();
  const [showDeleteAssetsAndBoardsModal] = useAirModal(DeleteAssetsAndBoardsModal);
  const { showDuplicateBoardsModal } = useDuplicateBoard();
  const { showMoveItemsModal } = useMoveItemsModal();
  const { toggleFavorite } = useToggleFavorite();
  const { activeSubscriptionCallback } = useActiveSubscriptionCallback();
  const { getCustomFieldsMenuOptions } = useGetPrivateCustomFieldsMenuOptions();
  const { downloadPrivateItems } = useDownloadPrivateItems();
  const store = useAirStore();
  const dispatch = useDispatch();
  const { getClipPermissions } = useClipPermissionsCache();
  const { getBoardPermissions } = useBoardPermissionsCache();
  const { getAssetPermissions } = useAssetPermissionsCache();
  const { getBoardContainerPermissions } = useGetBoardContainerPermissions();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  const handleDelete = useCallback(
    (trackLocation: AirActionTrackingLocation) => {
      const currentBoardId = CentralizedBoardSelectors.boardId(store.getState());
      const currentBoardPermissions = currentBoardId ? getBoardPermissions(currentBoardId) : undefined;
      const hasOnlyClipsSelected = hasOnlyClipsSelectedSelector(store.getState());
      const hasOnlyBoardsSelected = hasOnlyBoardsSelectedSelector(store.getState());
      const selectedCount = selectedItemsCountSelector(store.getState());
      const selectedClipsInfo = selectedClipsIdsInfoSelector(store.getState());
      const selectedClipAssetIds = selectedClipsInfo.map(({ assetId }) => assetId);
      const selectedClipIds = selectedClipsInfo.map(({ id }) => id);
      const selectedBoards = selectedBoardsSelector(store.getState());
      const selectedBoardsIds = selectedBoardsIdsSelector(store.getState());
      const canDeleteAllAssets =
        selectedClipIds.length > 0
          ? canDeleteAsset(currentBoardId ? currentBoardPermissions : workspacePermissions)
          : true;
      const canDeleteAllBoards =
        selectedBoards.length > 0
          ? selectedBoards.every((board) => canDeleteBoard(getBoardContainerPermissions({ board })))
          : true;

      if (!canDeleteAllAssets || !canDeleteAllBoards) {
        return;
      }

      if (hasOnlyClipsSelected) {
        showDeletePrivateClipsModal({
          assetIds: selectedClipAssetIds,
          clipIds: selectedClipIds,
          trackLocation,
          parentBoardId: currentBoardId,
          onDelete: () => dispatch(resetSelectedItemsAction()),
        });
      } else if (hasOnlyBoardsSelected) {
        showDeleteBoardsModal({
          boardIds: selectedBoardsIds,
          onDelete: () => {
            dispatch(resetSelectedItemsAction());
          },
          trackLocation,
        });
      } else if (selectedCount > 0) {
        showDeleteAssetsAndBoardsModal({
          assetIds: selectedClipAssetIds,
          clipIds: selectedClipIds,
          boardIds: selectedBoardsIds,
          onDelete: () => dispatch(resetSelectedItemsAction()),
          trackLocation,
          parentBoardId: currentBoardId,
        });
      }
    },
    [
      dispatch,
      getBoardContainerPermissions,
      getBoardPermissions,
      showDeleteAssetsAndBoardsModal,
      showDeleteBoardsModal,
      showDeletePrivateClipsModal,
      store,
      workspacePermissions,
    ],
  );

  const getSelectionMenuOptions: GetSelectionMenuOptionsType = useCallback(
    (trackLocation) => {
      const currentBoardId = CentralizedBoardSelectors.boardId(store.getState());
      const currentBoardPermissions = currentBoardId ? getBoardPermissions(currentBoardId) : undefined;
      const boardTitle = centralizedBoardTitleSelector(store.getState());
      const isFlattenedView = isFlattenedViewSelector(store.getState()) === 'true';

      const hasOnlyClipsSelected = hasOnlyClipsSelectedSelector(store.getState());
      const hasOnlyBoardsSelected = hasOnlyBoardsSelectedSelector(store.getState());
      const areAllClipsFavorited = !selectedClipsHasUnfavorited(store.getState());
      const selectedCount = selectedItemsCountSelector(store.getState());
      const selectedClipsInfo = selectedClipsIdsInfoSelector(store.getState());
      const selectedClipIds = selectedClipsInfo.map(({ id }) => id);
      const selectedBoards = selectedBoardsSelector(store.getState());
      const selectedPrivateBoards = selectedPrivateBoardsSelector(store.getState());
      const selectedBoardsIds = selectedBoardsIdsSelector(store.getState());
      const selectedClips = selectedClipsSelector(store.getState());

      const canMoveAllBoards = selectedBoards.every((board) => canDeleteBoard(getBoardContainerPermissions({ board })));

      const canDownloadAllClips = selectedClips.every(({ id }) => canGenerateZip(getClipPermissions(id)));
      const canDownloadAllBoards = selectedBoards.every(({ id }) => canGenerateZip(getBoardPermissions(id)));

      const canEditSelectClipsTags = selectedClips.every(({ id }) => canSeeApplicableClipTags(getClipPermissions(id)));

      const canMoveAllAssets = selectedClips.every(({ assetId }) => canMoveAsset(getAssetPermissions(assetId)));
      const canEditAllClipCustomFields = selectedClips.every(({ id }) =>
        canChangeAssetCustomFields(getClipPermissions(id)),
      );
      const canEditAllBoardCustomFields = selectedBoards.every(({ id }) =>
        canChangeBoardCustomFields(getBoardPermissions(id)),
      );

      const canEditCustomFields = canEditAllClipCustomFields && canEditAllBoardCustomFields;
      const canRemoveAssets = currentBoardId && canRemoveAssetsFromBoard(currentBoardPermissions);

      if (selectedCount < 2) {
        return [];
      }

      const options = produce<DropdownMenuOption[]>([], (draft) => {
        if (hasOnlyClipsSelected) {
          const handleFavorite = () =>
            activeSubscriptionCallback(() => toggleFavorite({ clips: selectedClips, trackLocation }));

          if (areAllClipsFavorited) {
            draft.push({
              ...getDropdownMenuOption('unfavorite'),
              onSelect: handleFavorite,
              type: 'item',
            });
          } else {
            draft.push({
              ...getDropdownMenuOption('favorite'),
              onSelect: handleFavorite,
              type: 'item',
            });
          }

          const canAddAllAssetsToABoard = selectedClips.every(({ assetId }) =>
            canAddAssetToBoard(getAssetPermissions(assetId)),
          );

          if (canAddAllAssetsToABoard) {
            draft.push({
              ...getDropdownMenuOption('add-to-board'),
              onSelect: () =>
                showAddAssetsToBoardsModal({
                  clipIds: selectedClipIds,
                  trackLocation,
                  parentBoardId: currentBoardId,
                }),
              type: 'item',
            });
          }

          if (canEditSelectClipsTags) {
            draft.push({
              ...getDropdownMenuOption('edit-tags'),
              onSelect: () => {
                const initialTags = intersectionBy(...selectedClips.map(({ tags }) => tags), 'id');
                showEditTagsToClipsModal({
                  clipIds: selectedClipIds,
                  trackLocation,
                  initialTags,
                });
              },
              type: 'item',
            });
          }
        }

        if (canDownloadAllClips && canDownloadAllBoards) {
          draft.push({
            ...getDropdownMenuOption('download'),
            onSelect: () => {
              downloadPrivateItems({ trackLocation, boardIds: selectedBoardsIds, clipIds: selectedClipIds });
              dispatch(resetSelectedItemsAction());
            },
            type: 'item',
          });
        }

        const canMove = !!currentBoardId && !isSearchActive;

        /**
         * 'Move' option should show if user is in search and boards and assets are selected,
         * or if anything is selected while selecting in a board
         */
        const shouldShowMoveOption = (canMoveAllBoards && !hasOnlyClipsSelected && !canMove) || canMove;

        if (!isFlattenedView && shouldShowMoveOption && canMoveAllAssets && canMoveAllBoards && currentBoardId) {
          draft.push({
            ...getDropdownMenuOption('move'),
            onSelect: () =>
              showMoveItemsModal({
                boards: selectedPrivateBoards,
                clips: selectedClipsInfo,
                onMove: () => dispatch(resetSelectedItemsAction()),
                trackLocation,
              }),
            type: 'item',
          });
        }

        if (canEditCustomFields && getCustomFieldsMenuOptions) {
          const customFieldsMenuOptions = getCustomFieldsMenuOptions({
            boards: selectedBoards,
            assets: selectedClips,
          });
          if (customFieldsMenuOptions.length) {
            draft.splice(3, 0, {
              ...getDropdownMenuOption('edit-custom-fields'),
              options: customFieldsMenuOptions,
              type: 'sub',
            });
          }
        }

        const canDuplicateAllBoards = selectedBoards.every((board) =>
          canDuplicateBoard({
            boardContext: getBoardPermissions(board.id),
            targetContext: getBoardContainerPermissions({ board }),
          }),
        );

        if (hasOnlyBoardsSelected && canDuplicateAllBoards) {
          draft.push({
            ...getDropdownMenuOption('duplicate'),
            onSelect: () => showDuplicateBoardsModal({ boards: selectedPrivateBoards, trackLocation }),
            type: 'item',
          });
        }

        const isRemoveFromBoardVisible =
          hasOnlyClipsSelected && !isFlattenedView && currentBoardId && boardTitle && canRemoveAssets;

        if (isRemoveFromBoardVisible) {
          draft.push({
            id: 'remove-separator',
            type: 'separator',
          });

          draft.push({
            ...getDropdownMenuOption('remove-from-board'),
            onSelect: () =>
              showRemoveAssetsFromBoardModal({
                boardId: currentBoardId,
                boardTitle,
                clipIds: selectedClipIds,
                trackLocation,
              }),
            type: 'item',
          });
        }

        const canDeleteAllAssets =
          selectedClips.length > 0
            ? canDeleteAsset(currentBoardId ? currentBoardPermissions : workspacePermissions)
            : true;
        const canDeleteAllBoards =
          selectedBoards.length > 0
            ? selectedBoards.every((board) => canDeleteBoard(getBoardContainerPermissions({ board })))
            : true;

        if (canDeleteAllAssets && canDeleteAllBoards) {
          if (!isRemoveFromBoardVisible) {
            draft.push({
              id: 'delete-separator',
              type: 'separator',
            });
          }

          draft.push({
            ...getDropdownMenuOption('delete'),
            onSelect: () => handleDelete(trackLocation),
            type: 'item',
          });
        }

        if (!!draft.length && selectedCount > 1) {
          draft.unshift(
            {
              id: 'selected-header',
              type: 'title',
              label: `${pluralize('item', selectedCount, true)} selected`,
            },
            {
              id: 'selected-separator',
              type: 'separator',
            },
          );
        }

        return draft;
      });

      return options;
    },
    [
      store,
      getBoardPermissions,
      workspacePermissions,
      getBoardContainerPermissions,
      getClipPermissions,
      getAssetPermissions,
      isSearchActive,
      getCustomFieldsMenuOptions,
      activeSubscriptionCallback,
      toggleFavorite,
      showAddAssetsToBoardsModal,
      showEditTagsToClipsModal,
      downloadPrivateItems,
      dispatch,
      showMoveItemsModal,
      showDuplicateBoardsModal,
      showRemoveAssetsFromBoardModal,
      handleDelete,
    ],
  );

  useHotkeys(
    'Backspace, delete, del',
    () => {
      handleDelete('keyboard');
    },
    [handleDelete],
  );

  return {
    getSelectionMenuOptions,
  };
};
