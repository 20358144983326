import { useTrackHideField, useTrackShowField } from '@air/analytics';
import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';

import { setFieldsVisibilityAction, setSortingFieldAction } from '~/store/configViews/actions';
import { currentSortFieldNameSelector } from '~/store/configViews/selectors';
import { ConfigViewOption } from '~/store/configViews/types';
import { useAirStore } from '~/utils/ReduxUtils';

export const useToggleViewVisibilityMenuOption = () => {
  const dispatch = useDispatch();
  const store = useAirStore();
  const { trackShowField } = useTrackShowField();
  const { trackHideField } = useTrackHideField();

  const toggleViewVisibilityMenuOption = useCallback(
    (option: ConfigViewOption) => {
      const sort = currentSortFieldNameSelector(store.getState());

      batch(() => {
        if (option.isVisible && option.fieldName === sort) {
          // default to name column sort if this toggle is going to disappear
          dispatch(setSortingFieldAction({ sortingFieldName: 'name' }));
        }
        dispatch(
          setFieldsVisibilityAction({
            fields: [{ name: option.fieldName, customFieldId: option.customFieldId }],
            nextIsFieldVisible: !option.isVisible,
          }),
        );
      });

      const type = option.isCustomField ? 'custom-field' : option.fieldName;

      if (option.isVisible) {
        trackHideField({ type });
      } else {
        trackShowField({ type });
      }
    },
    [dispatch, store, trackHideField, trackShowField],
  );

  return {
    toggleViewVisibilityMenuOption,
  };
};
