import { Checkbox } from '@air/primitive-checkbox';
import { memo } from 'react';

import { FILTER_ROW_CHECKBOX } from '~/constants/testIDs';

interface TypeFilterRowProps {
  label: string;
  isSelected: boolean;
  onChange: (isSelected: boolean) => void;
}

export const FilterRowWithCheckbox = memo(({ onChange, label, isSelected }: TypeFilterRowProps) => (
  <div className="mb-0.5 flex justify-between py-1.5 pl-1.5 pr-1">
    <label className="text-14 text-grey-12">{label}</label>

    <Checkbox
      aria-label={label}
      checked={isSelected}
      data-testid={`${FILTER_ROW_CHECKBOX}-${label}`}
      onCheckedChange={onChange}
    />
  </div>
));

FilterRowWithCheckbox.displayName = 'FilterRowWithCheckbox';
