import { type OpenAssetModalLocations } from '@air/analytics';
import { useCallback } from 'react';

import { type CentralizedClip } from '~/store/centralizedClip/types';

export function useOpenAssetInNewTab() {
  const openAssetInNewTab = useCallback(
    ({
      asset,
    }: {
      asset: Partial<CentralizedClip> & Pick<CentralizedClip, 'id'>;
      trackLocation?: OpenAssetModalLocations;
    }) => {
      const assetRoute = `/c/${asset.id}`;
      const basePath = window.location.href.split('?')[0].split('/c/')[0];

      return window.open(`${basePath}${assetRoute}`, '_blank', 'noopener,noreferrer');
    },
    [],
  );

  return { openAssetInNewTab };
}
