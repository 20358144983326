import { Clip } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { orderBy } from 'lodash';
import { useCallback } from 'react';

import { useAddAssetsToBoardsModal } from '~/components/Modals/AddAssetsToBoardsModal/useAddAssetsToBoardsModal';
import { DeletePrivateClipsModal } from '~/components/Modals/DeletePrivateClipsModal';
import { EditClipsTagsModal } from '~/components/Modals/EditClipsTagsModal';
import { MoveItemsModal } from '~/components/Modals/MoveItemsModal/MoveItemsModal';
import { useRemoveAssetsFromBoardModal } from '~/components/Modals/RemoveClipsFromBoardModal/hooks/useRemoveAssetsFromBoardModal';
import { RenameClipsModal } from '~/components/Modals/RenameClipsModal';
import { SetBoardThumbnailModal } from '~/components/Modals/SetBoardThumbnail/SetBoardThumbnailModal';
import { SharePrivateClipModal } from '~/components/SharePrivateClipModal/SharePrivateClipModal';
import { GetItemMenuOptionsCallback } from '~/constants/MenuOptions';
import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';
import { useAssetPermissionsCache } from '~/hooks/useAssetPermissionsCache';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useCanShowInFinderOption } from '~/hooks/useCanShowInFinderOption';
import { useClipPermissionsCache } from '~/hooks/useClipPermissionsCache';
import { useDownloadPrivateAsset } from '~/hooks/useDownloadPrivateAsset';
import { useDownloadPrivateProxyAsset } from '~/hooks/useDownloadPrivateProxyAsset';
import { useGetPrivateCustomFieldsMenuOptions } from '~/hooks/useGetCustomFieldsMenuOptions';
import { useGetPrivateClipMenuOptions } from '~/hooks/useGetPrivateClipMenuOptions';
import { useGoToAssetPage } from '~/hooks/useGoToAssetPage';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useDesktopAppConnectionContext } from '~/providers/DesktopAppConnectionProvider';
import { CentralizedBoardSelectors, centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';
import { useToggleFavorite } from '~/swr-hooks/clips/useToggleFavorite';
import ClipUtils from '~/utils/ClipUtils';
import {
  canAddAssetToBoard,
  canChangeAssetCustomFields,
  canChangeVersionDetails,
  canDeleteAsset,
  canDownloadVersion,
  canEditClipTags,
  canMoveAsset,
  canRemoveClipTags,
} from '~/utils/permissions/assetPermissions';
import { canRemoveAssetsFromBoard } from '~/utils/permissions/boardPermissions';
import { canCreateShortUrl } from '~/utils/permissions/shortUrlPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

export function useGetPrivateAssetMenuOptionsCallback() {
  const { goToAssetPage } = useGoToAssetPage();
  const { canShowOpenInFinder } = useCanShowInFinderOption();
  const { isFlattenedView } = useIsFlattenedView();

  const { openClipInFinder } = useDesktopAppConnectionContext();

  const store = useAirStore();
  const [showEditClipsTagsModal] = useAirModal(EditClipsTagsModal);
  const [showRenameClipsModal] = useAirModal(RenameClipsModal);
  const { showAddAssetsToBoardsModal } = useAddAssetsToBoardsModal();
  const { showRemoveAssetsFromBoardModal } = useRemoveAssetsFromBoardModal();
  const [showDeletePrivateClipsModal] = useAirModal(DeletePrivateClipsModal);
  const [sharePrivateClip] = useAirModal(SharePrivateClipModal);
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  const { toggleFavorite } = useToggleFavorite();
  const { downloadPrivateAsset } = useDownloadPrivateAsset();
  const { getClipPermissions } = useClipPermissionsCache();
  const { getBoardPermissions } = useBoardPermissionsCache();
  const { getAssetPermissions } = useAssetPermissionsCache();
  const { downloadPrivateProxyAsset } = useDownloadPrivateProxyAsset();

  const { getCustomFieldsMenuOptions } = useGetPrivateCustomFieldsMenuOptions();

  const { getClipMenuOptions } = useGetPrivateClipMenuOptions();

  const [showMoveToBoardModal] = useAirModal(MoveItemsModal);

  const [showSetBoardThumbnailModal] = useAirModal(SetBoardThumbnailModal);

  const getAssetMenuOptionsCallback: GetItemMenuOptionsCallback<Clip> = useCallback(
    ({ item: clip }) =>
      ({ trackLocation }) => {
        const currentBoardId = CentralizedBoardSelectors.boardId(store.getState());
        const currentBoardTitle = centralizedBoardTitleSelector(store.getState());
        const currentBoardPermissions = currentBoardId ? getBoardPermissions(currentBoardId) : undefined;

        const clipPermissions = getClipPermissions(clip.id);
        const assetPermissions = getAssetPermissions(clip.assetId);

        const canDownloadClip = canDownloadVersion(clipPermissions);
        const canRemoveAssets = currentBoardId && canRemoveAssetsFromBoard(currentBoardPermissions);
        const canRenameClip = canChangeVersionDetails(clipPermissions);
        const canMove = canMoveAsset(assetPermissions);
        const canShare = canCreateShortUrl(clipPermissions);
        const canEditTags = canEditClipTags(clipPermissions) && canRemoveClipTags(clipPermissions);
        const canDelete = canDeleteAsset(currentBoardId ? currentBoardPermissions : workspacePermissions);
        const canAddToBoard = canAddAssetToBoard(assetPermissions);
        const canManageApplications = canChangeAssetCustomFields(assetPermissions);

        if (!clipPermissions) {
          return [];
        }

        return getClipMenuOptions({
          customFieldsMenuOptions: canManageApplications ? getCustomFieldsMenuOptions({ assets: [clip] }) : undefined,
          onAddToBoard: canAddToBoard
            ? () =>
                showAddAssetsToBoardsModal({
                  clipIds: [clip.id],
                  trackLocation,
                  parentBoardId: currentBoardId,
                })
            : undefined,

          onEditTags: canEditTags
            ? () => {
                showEditClipsTagsModal({
                  clipIds: [clip.id],
                  trackLocation,
                  initialTags: clip.tags,
                });
              }
            : undefined,
          onDelete: canDelete
            ? () =>
                showDeletePrivateClipsModal({
                  assetIds: [clip.assetId],
                  clipIds: [clip.id],
                  trackLocation,
                  parentBoardId: currentBoardId,
                })
            : undefined,
          onDownload: canDownloadClip ? () => downloadPrivateAsset({ id: clip.id, trackLocation }) : undefined,
          originalResolution: canDownloadClip
            ? {
                id: 'original',
                label: `${clip.ext.toUpperCase()} - ${clip.width}x${clip.height}`,
                onSelect: () => downloadPrivateAsset({ id: clip.id, trackLocation }),
                type: 'item',
              }
            : undefined,
          altResolutions: canDownloadClip
            ? orderBy(clip.altResolutions, (resolution) => resolution.width * resolution.height, 'desc').map(
                (resolution) => ({
                  label: `${resolution.ext} - ${resolution.width}x${resolution.height}`,
                  id: `proxy-${resolution.width}-${resolution.height}`,
                  onSelect: () =>
                    downloadPrivateProxyAsset({ id: clip.id, resolutionId: resolution.id, trackLocation }),
                  type: 'item',
                }),
              )
            : undefined,
          onMoveToBoard:
            currentBoardId && canMove
              ? () =>
                  showMoveToBoardModal({
                    boards: [],
                    clips: [
                      {
                        id: clip.id,
                        assetId: clip.assetId,
                        openCommentCount: clip.openCommentCount,
                      },
                    ],
                    trackLocation,
                  })
              : undefined,
          onOpen: () =>
            goToAssetPage({
              asset: clip,
              trackLocation: 'open-dropdown-select',
            }),
          onOpenOnDesktop:
            /**
             * @see https://air-labs-team.slack.com/archives/C052RDYCGAG/p1696874696775379
             */
            canShowOpenInFinder && canDownloadClip
              ? () => {
                  openClipInFinder(clip, currentBoardId);
                }
              : undefined,
          onRemoveFromCurrentBoard:
            !isFlattenedView && currentBoardId && currentBoardTitle && canRemoveAssets
              ? () => {
                  showRemoveAssetsFromBoardModal({
                    boardId: currentBoardId,
                    boardTitle: currentBoardTitle,
                    clipIds: [clip.id],
                    trackLocation,
                  });
                }
              : undefined,
          onRenameAsset: canRenameClip
            ? () =>
                showRenameClipsModal({
                  clip,
                })
            : undefined,
          onSetBoardThumbnail: ClipUtils.canHavePreviewImage(clip.type)
            ? () =>
                showSetBoardThumbnailModal({
                  trackLocation: 'asset-details',
                  clip,
                })
            : undefined,
          onShare: canShare
            ? () =>
                sharePrivateClip({
                  clip,
                  trackLocation,
                  boardContext: currentBoardId,
                })
            : undefined,

          onFavorite: !clip.bookmarked ? () => toggleFavorite({ clips: [clip], trackLocation }) : undefined,
          onUnfavorite: clip.bookmarked ? () => toggleFavorite({ clips: [clip], trackLocation }) : undefined,
        });
      },
    [
      store,
      getBoardPermissions,
      getClipPermissions,
      getAssetPermissions,
      workspacePermissions,
      getClipMenuOptions,
      getCustomFieldsMenuOptions,
      canShowOpenInFinder,
      isFlattenedView,
      showAddAssetsToBoardsModal,
      showEditClipsTagsModal,
      showDeletePrivateClipsModal,
      downloadPrivateAsset,
      downloadPrivateProxyAsset,
      showMoveToBoardModal,
      goToAssetPage,
      openClipInFinder,
      showRemoveAssetsFromBoardModal,
      showRenameClipsModal,
      showSetBoardThumbnailModal,
      sharePrivateClip,
      toggleFavorite,
    ],
  );

  return {
    getAssetMenuOptionsCallback,
  };
}
