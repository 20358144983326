import { BaseCustomField } from '@air/api/types';
import { memo } from 'react';

import { customFieldIconMap } from '~/constants/customFieldIconMap';
import { ConfigViewOption } from '~/store/configViews/types';

import { useToggleViewVisibilityMenuOption } from './hooks/useToggleViewVisibilityMenuOption';
import { useToggleViewVisibilityMenuOptions } from './hooks/useToggleViewVisibilityMenuOptions';
import { ViewVisibilitySection } from './ViewVisibilitySection/ViewVisibilitySection';
import { ViewVisibilityToggle } from './ViewVisibilitySection/ViewVisibilityToggle';

export interface CustomFieldsVisbilityOptionsProps {
  options: ConfigViewOption[];
  customFields: BaseCustomField[];
}

export const CustomFieldsVisbilityOptions = memo(({ options, customFields }: CustomFieldsVisbilityOptionsProps) => {
  const { toggleViewVisibilityMenuOption } = useToggleViewVisibilityMenuOption();
  const { toggleViewVisibilityMenuOptions } = useToggleViewVisibilityMenuOptions();
  const initialShowOptions = options.some(({ isVisible }) => isVisible);

  return (
    <ViewVisibilitySection
      initialShowOptions={initialShowOptions}
      id="customFields"
      label="Custom fields"
      onToggleShowingOptions={(showing) => {
        toggleViewVisibilityMenuOptions(options, showing);
      }}
      toggles={options.map((option) => {
        const customField = customFields.find((cf) => cf.id === option.customFieldId);
        const customFieldTypeName = customField?.type.title;
        const Icon = customFieldTypeName ? customFieldIconMap[customFieldTypeName] : null;

        return (
          <ViewVisibilityToggle
            key={option.fieldName}
            onClick={() => toggleViewVisibilityMenuOption(option)}
            option={option}
            prefix={!!Icon && <Icon width={16} />}
          />
        );
      })}
    />
  );
});

CustomFieldsVisbilityOptions.displayName = 'CustomFieldsVisbilityOptions';
