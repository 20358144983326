import { PublicClip } from '@air/api';
import { AssetCustomFieldValue, BaseCustomField, Clip } from '@air/api/types';

import { ConfigViewOption } from '~/store/configViews/types';

export const getAssetCustomFields = ({
  visibleCustomFields,
  allCustomFields,
  clip,
}: {
  clip: Pick<Clip | PublicClip, 'customFields' | 'assetId'>;
  visibleCustomFields: ConfigViewOption[];
  allCustomFields: BaseCustomField[];
}) =>
  visibleCustomFields.reduce<AssetCustomFieldValue[]>((cfs, visibleCF) => {
    const customField = allCustomFields?.find((cf) => cf.id === visibleCF.customFieldId);
    if (customField) {
      const cf = clip.customFields?.find((cf) => cf.id === visibleCF.customFieldId) || {
        ...customField,
        assetId: clip.assetId,
        value: null,
        values: null,
      };
      return [...cfs, cf];
    }
    return cfs;
  }, []);
