import { useTrackRenamedClip } from '@air/analytics/src/events/useTrackRenamedClip';
import { Clips } from '@air/api';
import { Clip } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getVersionsListKey } from '~/constants/react-query-keys';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { updateAssetTitleSuccessAction } from '~/store/centralizedClip/actions';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useUpdateAssetInAllViews } from '~/utils/mutateUtils/GalleryAssets';
import { getBoardIdFromPath } from '~/utils/PathUtils';

export const useUpdateAssetTitle = () => {
  const dispatch = useDispatch();
  const { updateAssetInAllViews } = useUpdateAssetInAllViews();
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackRenamedClip } = useTrackRenamedClip();
  const workspaceId = currentWorkspace?.id;

  const updateAssetTitle = useCallback(
    async (clipId: Clip['id'], title: Clip['title'], assetId: Clip['assetId']) => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      try {
        const updated = await Clips.batchUpdate({ workspaceId, clips: [{ id: clipId, title }] });
        trackRenamedClip({ clip_id: clipId, new_clip_title: title });
        dispatch(updateAssetTitleSuccessAction({ clipId, title }));
        const parentBoardId = getBoardIdFromPath(window.location.pathname);
        updateAssetInAllViews({ parentBoardId, asset: updated[0] });

        queryClient.invalidateQueries({ queryKey: getVersionsListKey({ assetId }) });
      } catch (error) {
        throw reportErrorToBugsnag({ error, context: 'Failed to update assets title' });
      }
    },
    [workspaceId, trackRenamedClip, dispatch, updateAssetInAllViews, queryClient],
  );

  return {
    updateAssetTitle,
  };
};
