import { useBreakpointsContext } from '@air/provider-media-query';
import { memo } from 'react';

import { NoAssetsAddedDesktopAsset } from '~/components/ContributableNullState/NoAssetsAddedDesktopAsset';
import { NoAssetsAddedMobileAsset } from '~/components/ContributableNullState/NoAssetsAddedMobileAsset';
import { NullState, NullStateProps } from '~/components/NullState';

export type NonContributableEmptyStateProps = Partial<Pick<NullStateProps, 'description' | 'title'>>;

export const NonContributableEmptyState = memo(
  ({
    description = 'Check back later to see if anything has been uploaded to this board.',
    title = ' Nothing to see here, yet…',
  }: NonContributableEmptyStateProps) => {
    const { isAboveMediumScreen } = useBreakpointsContext();

    return (
      <NullState
        image={
          <div>
            {isAboveMediumScreen ? (
              <NoAssetsAddedDesktopAsset className="mx-auto -mb-4 w-full" />
            ) : (
              <NoAssetsAddedMobileAsset className="mx-auto" />
            )}
          </div>
        }
        title={title}
        description={description}
      />
    );
  },
);

NonContributableEmptyState.displayName = 'NonContributableEmptyState';
