import { ClipsListOptions } from '@air/api/types';

import { useClipsFilters } from '~/hooks/filters/useClipsFilters';
import { useSearchParams } from '~/hooks/search/useSearchParams';

export const useClipSearchParams = (): Partial<ClipsListOptions> => {
  const { search } = useSearchParams();
  const { filtersParams } = useClipsFilters();

  return {
    withOpenDiscussionStatus: true,
    filters: filtersParams,
    search,
    boardId: filtersParams.board?.is || filtersParams.boardTree?.is,
  };
};
