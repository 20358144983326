import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DateFilter } from '~/components/Filters/DateFilter/DateFilter';
import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import {
  removeFilterAction,
  setTakenDateFilterEndDateAction,
  setTakenDateFilterStartDateAction,
  setTakenDateFilterTypeAction,
} from '~/store/filters/actions';
import {
  takenDateFilterDateTypeSelector,
  takenDateFilterEndDateSelector,
  takenDateFilterStartDateSelector,
} from '~/store/filters/selectors';
import { DateRangeType, SelectedFilter } from '~/store/filters/types';

export type TakenDateFilterCardProps = BaseFilterCardProps;

export const TakenDateFilterCard = memo((props: TakenDateFilterCardProps) => {
  const dispatch = useDispatch();
  const dateType = useSelector(takenDateFilterDateTypeSelector);
  const startDate = useSelector(takenDateFilterStartDateSelector);
  const endDate = useSelector(takenDateFilterEndDateSelector);

  const onDateTypeChange = useCallback(
    (type: DateRangeType) => {
      dispatch(setTakenDateFilterTypeAction({ type }));
    },
    [dispatch],
  );

  const onStartDateChange = useCallback(
    (startDate?: Date) => {
      dispatch(setTakenDateFilterStartDateAction({ startDate }));
    },
    [dispatch],
  );

  const onEndDateChange = useCallback(
    (endDate?: Date) => {
      dispatch(setTakenDateFilterEndDateAction({ endDate }));
    },
    [dispatch],
  );

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
  };

  return (
    <FilterCard currentFilter="dateTaken" onRemoveFilter={onRemoveFilter} {...props}>
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        selectedType={dateType}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
        onTypeChange={onDateTypeChange}
      />
    </FilterCard>
  );
});

TakenDateFilterCard.displayName = 'TakenDateFilterCard';
