import { AirActionTrackingLocation, useTrackDownloadedItems } from '@air/analytics';
import { Clips } from '@air/api';
import { Clip } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { DownloadFile, isS3DownloadUrlActive } from '~/utils/DownloadFile';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export interface DownloadPrivateAssetParams {
  id: Clip['id'];
  trackLocation: AirActionTrackingLocation;
}

/**
 * This hook returns a function that downloads a single private asset.
 * If you are trying to download multiple private assets, @see useDownloadPrivateItems
 */
export function useDownloadPrivateAsset() {
  const { showToast } = useToasts();
  const { trackDownloadedItems } = useTrackDownloadedItems();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const downloadPrivateAsset = useCallback(
    async ({ id, trackLocation }: DownloadPrivateAssetParams) => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      try {
        const { url } = await Clips.getDownloadUrl({ clipId: id, workspaceId });

        if (isS3DownloadUrlActive(url)) {
          DownloadFile(url);

          trackDownloadedItems({
            clipIds: [id],
            location: trackLocation,
          });
        } else {
          showToast('One or more of the selected file downloads has expired.');
        }
      } catch (error) {
        showToast('There was an error downloading a private asset.');

        reportErrorToBugsnag({
          error,
          context: 'Failed to download private assets from selection menu',
          metadata: {
            data: {
              id,
              location: trackLocation,
            },
          },
        });
      }
    },
    [showToast, trackDownloadedItems, workspaceId],
  );

  return {
    downloadPrivateAsset,
  };
}
