import { memo, ReactNode, RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import { List, ListRowRenderer, WindowScroller, WindowScrollerProps } from 'react-virtualized';

import { TableRowType } from '~/components/TableView/basicTypes';
import { useListHorizontalScroll } from '~/hooks/useListHorizontalScroll';
import usePrevious from '~/hooks/usePrevious';

export interface TableViewProps {
  rows: TableRowType[];
  renderHeaders?: (ref: RefObject<HTMLDivElement>) => ReactNode;
  minTableWidth: number;
  scrollElementRef: RefObject<WindowScrollerProps['scrollElement']>;
  canScrollHorizontally?: boolean;
}

// add small top offset to row to avoid focus/drop border cut
export const ROW_TOP_OFFSET = 5;
export const TABLE_HEADER_HEIGHT = 56;

/**
 * This is a basic component to display table view
 */
export const TableView = memo(
  ({ rows, minTableWidth, renderHeaders, canScrollHorizontally = true, scrollElementRef }: TableViewProps) => {
    const { width = 0, ref: widthRef } = useResizeDetector();

    const tableWidth = useMemo(() => Math.max(width + 16, minTableWidth), [width, minTableWidth]);

    const totalHeight = useMemo(() => rows.reduce((total, row) => total + row.height, 0), [rows]);
    const previousHeight = usePrevious(totalHeight);

    const headerRef = useRef(null);
    const scrollRef = useRef(null);

    const listRef = useRef<List>(null);

    const onHorizontalScroll = useListHorizontalScroll(listRef, scrollRef, headerRef);

    // recompute rows heights if total table height changes
    useEffect(() => {
      if (listRef.current && totalHeight !== previousHeight) {
        listRef.current.recomputeRowHeights();
      }
    }, [previousHeight, totalHeight]);

    const rowRenderer: ListRowRenderer = useCallback(
      ({ index, key, style: rowRenderStyle, isVisible }) => {
        const row = rows[index];

        // We need additional padding for the first element to avoid cutting focused outline
        const rowStyle = {
          ...rowRenderStyle,
          top: (rowRenderStyle.top as number) + ROW_TOP_OFFSET,
        };

        return row.render(rowStyle, key, isVisible);
      },
      [rows],
    );

    const getRowHeight = useCallback(({ index }: { index: number }) => rows[index].height, [rows]);

    const tableStyle = useMemo(
      () => ({
        paddingBottom: canScrollHorizontally ? 54 : '16rem',
      }),
      [canScrollHorizontally],
    );

    return scrollElementRef.current ? (
      <>
        {renderHeaders?.(headerRef)}

        <div ref={widthRef} />
        {!!tableWidth && (
          <WindowScroller scrollElement={scrollElementRef.current}>
            {({ height = 0, scrollTop, isScrolling }) => (
              <List
                tabIndex={-1}
                ref={listRef}
                autoWidth
                autoHeight
                width={tableWidth + 48}
                height={height}
                rowRenderer={rowRenderer}
                isScrolling={isScrolling}
                scrollTop={scrollTop}
                rowCount={rows.length}
                rowHeight={getRowHeight}
                style={tableStyle}
              />
            )}
          </WindowScroller>
        )}
        <div
          style={{ width: '100%', height: 0, overflowX: 'auto', visibility: 'hidden', pointerEvents: 'none' }}
          ref={scrollRef}
          onScroll={canScrollHorizontally ? onHorizontalScroll : undefined}
        />
      </>
    ) : null;
  },
  isEqual,
);

TableView.displayName = 'TableView';
