import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { keyBy } from 'lodash';

import { baseFiltersOptions, FilterSelectType, LocationFilters } from '~/components/FiltersMenu/types';
import { getFilterProps } from '~/components/FiltersMenu/utils/getFilterProps';
import { SelectedFilter } from '~/store/filters/types';

export const getLocationFiltersOptions = ({
  selectedFilters,
  locationFilters,
  onSelect,
}: {
  selectedFilters: SelectedFilter[];
  locationFilters: LocationFilters[];
  onSelect: FilterSelectType;
}) => {
  const selectedFiltersTypes = keyBy(selectedFilters, 'type');

  const options: DropdownMenuOption[] = locationFilters.map((locationFilter) => ({
    ...getFilterProps({ type: locationFilter, onSelect: onSelect, isSelected: !!selectedFiltersTypes[locationFilter] }),
    label: baseFiltersOptions[locationFilter].label,
    prefix: null,
    type: 'item',
  }));

  return options;
};
