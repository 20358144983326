import { PublicClip } from '@air/api';
import { Board, Clip, PublicBoard } from '@air/api/types';
import { Upload } from '@air/redux-uploader';
import { formatBytes, parseAirDateToISO } from '@air/utilities';
import { reportErrorToBugsnag } from '@air/utils-error';
import { format } from 'date-fns';
import { isString } from 'lodash';

import { GalleryItemType } from '~/components/Gallery/types';
import { ConfigVisibilityToggleType } from '~/constants/configViews';
import { SelectableGalleryItem } from '~/store/selectedItems/types';
import { UseGalleryMixedItem } from '~/swr-hooks/gallery/types';
import { getAssetGalleryItemType } from '~/utils/AssetUtils';

export const blankCellText = '--';

export const formatTableViewDate = (date: string) => {
  // new Date(date) shows Invalid Date on Safari and Firefox for some of our dates
  // 2020-03-24 16:32:12.16+00: '.16+00' are the problem so we split to remove it
  // 2020-06-20T00:54:10.897Z: 'T' and 'Z' are the problem so we replace them
  return format(parseAirDateToISO(date), 'MMM d, yyyy');
};

export const boardTableRowInfo = (board: Board | PublicBoard): Record<ConfigVisibilityToggleType, string> => ({
  dateCreated: formatTableViewDate(board.createdAt),
  dateModified: formatTableViewDate(board.updatedAt),
  dateTaken: blankCellText,
  ext: blankCellText,
  size: blankCellText,
  titleAndMetadata: blankCellText,
  type: blankCellText,
  resolution: blankCellText,
  tagCount: blankCellText,
  boardCount: blankCellText,
  openCommentCount: blankCellText,
});

export const uploadTableRowInfo = (upload: Upload): Record<ConfigVisibilityToggleType, string> => {
  const recordedAt = upload.apiUploadData.recordedAt;
  const createdAt = new Date(upload.createdAt).toISOString();
  let recordedAtString = createdAt;
  if (recordedAt) {
    if (isString(recordedAt)) {
      recordedAtString = recordedAt;
    } else {
      recordedAtString = recordedAt.toISOString();
    }
  }

  return {
    ext: upload.apiUploadData.ext,
    dateModified: formatTableViewDate(recordedAtString),
    dateCreated: formatTableViewDate(createdAt),
    dateTaken: formatTableViewDate(recordedAtString),
    size: formatBytes(upload.apiUploadData.size || 0),
    titleAndMetadata: blankCellText,
    type: blankCellText,
    resolution: blankCellText,
    tagCount: '0',
    boardCount: '0',
    openCommentCount: '0',
  };
};

export const assetTableRowInfo = (asset: Clip | PublicClip): Record<ConfigVisibilityToggleType, string> => {
  let dateModified = '--';
  let dateRecorded = '--';

  try {
    dateModified = formatTableViewDate(asset.updatedAt);
  } catch (e) {
    reportErrorToBugsnag({
      error: e,
      context: 'Invalid dateModified date',
      metadata: { asset },
    });
  }

  try {
    dateRecorded = formatTableViewDate(asset.recordedAt);
  } catch (e) {
    reportErrorToBugsnag({
      error: e,
      context: 'Invalid dateRecorded date',
      metadata: { asset },
    });
  }

  return {
    ext: asset.ext,
    dateModified,
    dateCreated: dateRecorded,
    dateTaken: asset.dateTaken ? formatTableViewDate(asset.dateTaken) : blankCellText,
    size: formatBytes(asset.size),
    type: asset.type === 'nonMedia' ? 'file' : asset.type,
    titleAndMetadata: blankCellText,
    resolution: asset.width && asset.height ? `${asset.width} x ${asset.height}` : blankCellText,
    tagCount: `${asset.tags?.length ?? 0}`,
    boardCount: `${asset.boardCount ?? 0}`,
    openCommentCount: `${asset.openDiscussionCount || 0}`,
  };
};

export const mixedItemToSelectable = (item: UseGalleryMixedItem) => {
  const itemType = item.type === 'board' ? GalleryItemType.board : getAssetGalleryItemType(item.item);

  return {
    id: item.item.id,
    type: itemType,
    item: item.item,
  } as SelectableGalleryItem;
};
