import { memo, useCallback } from 'react';

import { SelectChipOptionContainer } from '~/components/Select/components/SelectChipOptionContainer';
import { Select, SelectProps } from '~/components/Select/Select';

interface VideoFrameRatesSelectProps
  extends Omit<SelectProps, 'placeholder' | 'options' | 'selectedOptions' | 'onSelectionChange'> {
  selectedValues: string[];
  onSelectionChange: (newString: string[]) => void;
  frameRates: string[];
}

export const VideoFrameRatesSelect = memo(
  ({ frameRates, selectedValues, onSelectionChange, ...props }: VideoFrameRatesSelectProps) => {
    const options = frameRates.map((val) => ({ value: val, label: `${val}fps` }));
    const selectedOptions = selectedValues.map((val) => ({ value: val, label: `${val}fps`, borderRadius: 4 }));

    const onValuesChange: SelectProps['onSelectionChange'] = useCallback(
      ({ chips }) => {
        onSelectionChange(chips.map((chip) => chip.value));
      },
      [onSelectionChange],
    );

    const listItemRenderer = useCallback<Required<SelectProps>['listItemRenderer']>(
      (option) => (
        <SelectChipOptionContainer className="flex justify-between py-2">
          <p className="text-14 text-grey-12">{option.label}</p>
        </SelectChipOptionContainer>
      ),
      [],
    );

    return (
      <Select
        placeholder="Search for fps ie. 30fps"
        listItemRenderer={listItemRenderer}
        maxDropdownHeight={300}
        options={options}
        renderAsInput
        isSearchable
        onSelectionChange={onValuesChange}
        selectedOptions={selectedOptions}
        alwaysShowClearItemButton
        isClearable
        isSticky
        size="large"
        {...props}
      />
    );
  },
);

VideoFrameRatesSelect.displayName = 'VideoFrameRatesSelect';
