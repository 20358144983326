import { useTrackClickedEllipsisMenuButton } from '@air/analytics';
import { PublicClip } from '@air/api';
import { Clip } from '@air/api/types';
import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { itemIsSelectedSelector } from '@air/redux-selected-items';
import { memo, useCallback, useMemo } from 'react';
import { flushSync } from 'react-dom';

import { CardEllipsisMenu } from '~/components/Gallery/CardEllipsisMenu';
import { GetSelectionMenuOptionsType } from '~/components/Gallery/types';
import { GetItemMenuOptions } from '~/constants/MenuOptions';
import { ASSET_CARD_SUB_MENU } from '~/constants/testIDs';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface AssetGalleryCardMenuProps<C> {
  clip: C;
  getItemMenuOptions: GetItemMenuOptions;
  getSelectionMenuOptions: GetSelectionMenuOptionsType;
  setIsMenuOpen: (value: boolean) => void;
  trigger: JSX.Element;
}

const _AssetGalleryCardMenu = <C extends Clip | PublicClip>({
  clip,
  getItemMenuOptions,
  getSelectionMenuOptions,
  setIsMenuOpen,
  trigger,
}: AssetGalleryCardMenuProps<C>) => {
  const { trackClickedEllipsisMenuButton } = useTrackClickedEllipsisMenuButton();

  /** Multiselect */
  const isSelected = useAirSelector((st) => itemIsSelectedSelector(st, clip.id));

  const selectionMenuOptions = getSelectionMenuOptions('gallery-multi-select-ellipsis-menu');
  const ellipsisMenuOptions = getItemMenuOptions({ trackLocation: 'gallery-item-ellipsis-menu' });

  const onMenuChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        trackClickedEllipsisMenuButton({ location: 'asset-item' });
      }

      flushSync(() => {
        setIsMenuOpen(isOpen);
      });
    },
    [setIsMenuOpen, trackClickedEllipsisMenuButton],
  );

  const menuOptions = useMemo(() => {
    const menuOptionsArray: DropdownMenuOption[] = [];

    if (isSelected && selectionMenuOptions.length) {
      menuOptionsArray.push(...selectionMenuOptions);
    } else {
      menuOptionsArray.push(...ellipsisMenuOptions);
    }

    return menuOptionsArray;
  }, [ellipsisMenuOptions, isSelected, selectionMenuOptions]);

  if (!menuOptions?.length) {
    return null;
  }

  return (
    <CardEllipsisMenu
      title="More asset options"
      menuProps={{ options: menuOptions }}
      onMenuChange={onMenuChange}
      actionSheetTestId={ASSET_CARD_SUB_MENU}
      trigger={trigger}
    />
  );
};

export const AssetGalleryCardMenu = memo(_AssetGalleryCardMenu) as typeof _AssetGalleryCardMenu;
