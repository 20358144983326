import { SearchSavedFiltersResponse } from '@air/api/types';
import { ChevronRight } from '@air/next-icons';
import { ComboboxOptionText } from '@reach/combobox';

import { BOARD_UNIQ_CHAR } from '~/components/SearchBar/Suggestion/BoardSuggestion';
import { SearchSuggestionContainer } from '~/components/SearchBar/Suggestion/SearchSuggestionContainer';

interface SavedFilterSuggestionProps {
  index: number;
  onClick: (savedFilter: SearchSavedFiltersResponse) => void;
  savedFilter: SearchSavedFiltersResponse;
}

export const SavedFilterSuggestion = ({ savedFilter, onClick, index }: SavedFilterSuggestionProps) => {
  // Combobox does not allow by default for duplicated options
  // If there are any options with the same value, both are highlighted when user navigates using arrow up/down
  // But we can have two saved filters with the same name. And there is no option from Combobox to recognize between saved filter and search
  // For Combobox, option is always text (value)
  // So, this is a nasty hack to avoid such confusion. We add NULL (U+0000) char to each saved filter and repeat it 'index' times
  // This way we do not display anything additional in search/suggestions, but for Combobox, values are different
  const uniqSavedFilterName = `${savedFilter.name}${BOARD_UNIQ_CHAR.repeat(index)}`;

  return (
    <SearchSuggestionContainer
      index={index}
      value={uniqSavedFilterName}
      onClick={() => onClick(savedFilter)}
      data-saved-filter-id={savedFilter.id}
    >
      <div className="flex h-10 w-10 items-center justify-center pl-1 pt-1.5 text-14 text-grey-12">
        {savedFilter.icon}
      </div>
      <div className="ml-3 flex flex-1 flex-col justify-center">
        <div className="truncate">
          <ComboboxOptionText />
        </div>
        {savedFilter?.description ? <div className="text-12 text-grey-9">{savedFilter.description}</div> : null}
      </div>
      <ChevronRight className="h-6 w-6 text-grey-10" />
    </SearchSuggestionContainer>
  );
};
