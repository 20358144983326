import { Color } from '@air/api/types';
import { Checkbox } from '@air/primitive-checkbox';
import { memo } from 'react';

import { COLOR_FILTER_CHECKBOX } from '~/constants/testIDs';

export interface ColorFilterProps {
  colors: Color[];
  onClick: (color: Color) => (isSelected: boolean) => void;
  selectedColors: string[];
}

export const ColorFilter = memo(({ colors, onClick, selectedColors }: ColorFilterProps) => {
  return (
    <div className="flex flex-wrap gap-1">
      {colors.map((color) => (
        <Checkbox
          className={
            color.name === 'white' ? 'radix-state-checked:border-grey-8 radix-state-checked:text-black' : 'border-none'
          }
          style={{ backgroundColor: color.hex }}
          data-testid={`${COLOR_FILTER_CHECKBOX}-${color.name}`}
          key={color.hex}
          checked={selectedColors.includes(color.hex)}
          onCheckedChange={(checked) => onClick(color)(!!checked)}
          size="large"
        />
      ))}
    </div>
  );
});

ColorFilter.displayName = 'ColorFilter';
