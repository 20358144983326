import { AirActionTrackingLocation, useTrackDownloadedItems } from '@air/analytics';
import { useCallback } from 'react';

import { useCreatePrivateZippingTask } from '~/components/ZippingTasks/hooks/useCreatePrivateZippingTask';

interface DownloadPrivateItemsParams {
  /** Ids of the board who's assets to download */
  boardIds?: string[];
  /** Ids of the clips to download */
  clipIds?: string[];
  /** Where in the UI this was called from */
  trackLocation: AirActionTrackingLocation;
}

/**
 * This hook returns a function that allows a user to download multiple private assets and or boards
 */
export function useDownloadPrivateItems() {
  const { trackDownloadedItems } = useTrackDownloadedItems();
  const { createPrivateZippingTask } = useCreatePrivateZippingTask();

  const downloadPrivateItems = useCallback(
    async ({ trackLocation, clipIds, boardIds }: DownloadPrivateItemsParams) => {
      createPrivateZippingTask({
        boardIds,
        clipIds,
      });

      trackDownloadedItems({
        boardIds,
        clipIds,
        location: trackLocation,
      });
    },
    [createPrivateZippingTask, trackDownloadedItems],
  );

  return {
    downloadPrivateItems,
  };
}
