import { Clip, ClipStatus } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { merge } from 'lodash';
import { useDispatch } from 'react-redux';

import { useEventSubscription } from '~/hooks/useEventSubscription';
import { updateCentralizedClipAction } from '~/store/centralizedClip/actions';
import { getPrivateClipMetadata } from '~/swr-hooks/clips/usePrivateClipMetadata';
import { useUpdateAssetInAllViews } from '~/utils/mutateUtils/GalleryAssets';
import { getBoardIdFromPath, getClipIdFromPath } from '~/utils/PathUtils';

export interface UseHandleClipUpdatedProps {
  clipId: string | RegExp | undefined;
  workspaceId: string | undefined;
}

export const useHandleClipUpdated = ({ clipId, workspaceId }: UseHandleClipUpdatedProps) => {
  const dispatch = useDispatch();
  const { updateAssetInAllViews } = useUpdateAssetInAllViews();
  const queryClient = useQueryClient();

  useEventSubscription({
    currentWorkspaceId: workspaceId,
    event: clipId
      ? {
          eventType: 'System.Clip.updated',
          pattern: {
            clipId,
          },
        }
      : undefined,
    options: {
      onUpdate: ({ data: { clipId, updates: clip } }) => {
        const boardId = getBoardIdFromPath(window.location.pathname);

        /**
         * When an clip has been updated (in this workspace),
         * update it all views (gallery, table, kanban)
         */
        updateAssetInAllViews({
          asset: { id: clipId },
          parentBoardId: boardId,
          updateFn: (existingAsset: Clip) => merge(existingAsset, clip),
        });

        const currentClipId = getClipIdFromPath(window.location.pathname);

        if (clip.status === ClipStatus.transcoded) {
          queryClient.invalidateQueries({ queryKey: getPrivateClipMetadata(clipId) });
        }

        /**
         * If it's the current clip the user is looking at,
         * updated centralized clip too.
         * The reason we check clipId instead of clip.id
         * is because sometimes clip doesn't have id
         */
        if (currentClipId === clipId) {
          dispatch(
            updateCentralizedClipAction({
              clip: {
                id: clipId,
                ...clip,
              },
            }),
          );
        }
      },
    },
  });
};
