import { Clip } from '@air/api/types';
import { merge } from 'lodash';
import { useDispatch } from 'react-redux';

import { GalleryItemType } from '~/components/Gallery/types';
import { useEventSubscription } from '~/hooks/useEventSubscription';
import { updateCentralizedClipAssetsAction } from '~/store/centralizedClip/actions';
import { useUpdateAssetInAllViews } from '~/utils/mutateUtils/GalleryAssets';
import { getBoardIdFromPath, getClipIdFromPath } from '~/utils/PathUtils';

export interface UseHandlePublicClipMediaUpdatedProps {
  clipId: string | RegExp | undefined;
  workspaceId: string | undefined;
}

export const useHandleClipMediaUpdated = ({ clipId, workspaceId }: UseHandlePublicClipMediaUpdatedProps) => {
  const dispatch = useDispatch();
  const { updateAssetInAllViews } = useUpdateAssetInAllViews();

  useEventSubscription({
    currentWorkspaceId: workspaceId,
    event: clipId
      ? {
          eventType: 'System.Clip.Media.updated',
          pattern: {
            clipId,
          },
        }
      : undefined,
    options: {
      onUpdate: ({ data: { updates: assets, clipId } }) => {
        const boardId = getBoardIdFromPath(window.location.pathname);

        /**
         * When clip's assets have been updated (in this workspace),
         * update it all views (gallery, table, kanban)
         */
        updateAssetInAllViews({
          asset: { id: clipId },
          parentBoardId: boardId,
          type: GalleryItemType.asset,
          updateFn: (existingAsset: Clip) => merge(existingAsset, { assets }),
        });

        const currentClipId = getClipIdFromPath(window.location.pathname);

        /**
         * If it's the current clip the user is looking at,
         * updated centralized clip's assets too
         * The reason we check clipId instead of clip.id
         * is because sometimes clip doesn't have id
         */
        if (currentClipId === clipId) {
          dispatch(
            updateCentralizedClipAssetsAction({
              assets,
            }),
          );
        }
      },
    },
  });
};
