import { AirActionTrackingLocation } from '@air/analytics';
import { Tooltip } from '@air/primitive-tooltip';
import { memo } from 'react';

import { TABLE_ASSET_TITLE } from '~/constants/testIDs';
import { preventDefaultMouseEvent } from '~/utils/ClickUtils';

export interface TableAssetTitleProps {
  title: string;
  onClick: (location: AirActionTrackingLocation) => void;
  onCmdClick?: (location: AirActionTrackingLocation) => void;
}

export const TableAssetTitle = memo(({ onClick, onCmdClick, title }: TableAssetTitleProps) => {
  return (
    <Tooltip label={title} side="top" sideOffset={4}>
      <button
        className="block w-full truncate text-left text-14 font-medium text-grey-12 hover:underline"
        data-testid={TABLE_ASSET_TITLE}
        onClick={(event) => {
          if ((event.metaKey || event.ctrlKey) && !!onCmdClick) {
            onCmdClick?.('table-cell');
          } else {
            onClick?.('table-cell');
          }
        }}
        onMouseDown={preventDefaultMouseEvent}
      >
        {title}
      </button>
    </Tooltip>
  );
});

TableAssetTitle.displayName = 'TableAssetTitle';
