import { Eye, EyeClosed } from '@air/next-icons';
import { dropdownMenuItem } from '@air/primitive-dropdown-menu/src/DropdownMenuItem';
import { ComponentProps, memo, type ReactNode } from 'react';

import { VISIBILITY_TOGGLE } from '~/constants/testIDs';
import { ConfigViewOption } from '~/store/configViews/types';

export type ViewVisibilityToggleProps = Pick<ComponentProps<'button'>, 'onClick'> & {
  option: ConfigViewOption;
  prefix?: ReactNode;
};

export const ViewVisibilityToggle = memo(({ option, prefix, ...rest }: ViewVisibilityToggleProps) => {
  return (
    <button
      className={dropdownMenuItem({ size: 'small' })}
      data-testid={`${VISIBILITY_TOGGLE}-${option.fieldName}`}
      {...rest}
    >
      {!!prefix ? <div className="mr-2 shrink-0">{prefix}</div> : null}

      <div className="flex min-w-0 grow truncate text-14">{option.label}</div>

      <div className="ml-2 shrink-0 text-grey-10">
        {option.isVisible ? (
          <Eye data-testid={`${option.fieldName}-visible`} width={16} />
        ) : (
          <EyeClosed data-testid={`${option.fieldName}-not-visible`} width={16} />
        )}
      </div>
    </button>
  );
});

ViewVisibilityToggle.displayName = 'ViewVisibilityToggle';
