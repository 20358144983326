import { useTrackGeneratedShareUrl } from '@air/analytics';
import { ShortUrl } from '@air/api';
import { ShortUrlObjectType } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getShortIdInformationKey } from './useGetExistingShortIdInformation';

export const useGetShortIdForClip = () => {
  const queryClient = useQueryClient();
  const { trackGeneratedShareUrl } = useTrackGeneratedShareUrl();

  const getShortIdForClip = useMutation({
    mutationFn: (params: Parameters<typeof ShortUrl.getShortIdForClip>[0]) => ShortUrl.getShortIdForClip(params),
    onSuccess: (data, params) => {
      queryClient.setQueryData(
        getShortIdInformationKey({ objectId: params.id, boardContext: params.boardContext }),
        data,
      );

      trackGeneratedShareUrl({
        type: ShortUrlObjectType.clip,
        id: data.id,
      });
    },
    onError: (error, params) => {
      throw reportErrorToBugsnag({
        error,
        context: 'Failed to get short id for clip',
        metadata: { data: { params } },
      });
    },
  });

  return {
    getShortIdForClip,
  };
};
