import { PublicClip } from '@air/api';
import { Clip } from '@air/api/types';
import { Ellipsis as EllipsisHorizontalIcon } from '@air/next-icons';
import { memo } from 'react';

import { CardAction } from '~/components/CardAction';
import { AssetGalleryCardMenu } from '~/components/Gallery/AssetGalleryCard/AssetGalleryCardMenu';
import { GetSelectionMenuOptionsType } from '~/components/Gallery/types';
import { GetItemMenuOptions } from '~/constants/MenuOptions';
import { ASSET_CARD_SUB_MENU_BUTTON } from '~/constants/testIDs';

export interface AssetGalleryCardRightActionsProps<C> {
  clip: C;
  getItemMenuOptions: GetItemMenuOptions;
  getSelectionMenuOptions: GetSelectionMenuOptionsType;
  setIsMenuOpen: (isOpen: boolean) => void;
}

const _AssetGalleryCardRightActions = <C extends Clip | PublicClip>({
  clip,
  getItemMenuOptions,
  getSelectionMenuOptions,
  setIsMenuOpen,
}: AssetGalleryCardRightActionsProps<C>) => {
  return (
    <>
      <AssetGalleryCardMenu
        clip={clip}
        getItemMenuOptions={getItemMenuOptions}
        getSelectionMenuOptions={getSelectionMenuOptions}
        setIsMenuOpen={setIsMenuOpen}
        trigger={
          <CardAction data-testid={ASSET_CARD_SUB_MENU_BUTTON} icon={<EllipsisHorizontalIcon className="h-4 w-4" />} />
        }
      />
    </>
  );
};

export const AssetGalleryCardRightActions = memo(_AssetGalleryCardRightActions) as typeof _AssetGalleryCardRightActions;
