import { CustomFieldValueFilterBase } from '~/components/Filters/CustomFieldFilter/utils';
import { FilterLogicSelect } from '~/components/Filters/FilterLogicSelect';
import { FilterLogicType } from '~/store/filters/types';

import { CustomFieldValuesFilterSelect } from './CustomFieldValuesFilterSelect';

export interface CustomFieldFilterProps {
  values: CustomFieldValueFilterBase[];
  selectedLogic: FilterLogicType;
  onLogicChange: (logic: FilterLogicType) => void;
  selectedValues: CustomFieldValueFilterBase[];
  onSelectedValuesChange: (values: CustomFieldValueFilterBase[]) => void;
}

export const CustomFieldFilter = ({
  values,
  selectedLogic,
  onLogicChange,
  selectedValues,
  onSelectedValuesChange,
}: CustomFieldFilterProps) => {
  return (
    <div>
      <FilterLogicSelect selectedLogic={selectedLogic} onLogicChange={onLogicChange} />
      <CustomFieldValuesFilterSelect
        hasDropdownOverlay
        values={values}
        size="large"
        isClearable={false}
        alwaysShowClearItemButton
        selectedValues={selectedValues}
        onSelectedValuesChanged={onSelectedValuesChange}
      />
    </div>
  );
};
