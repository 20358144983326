import { EntityLookupResponse } from '@air/api';
import { AssetSearchFiltersCalculated, FilterLookupsResponse } from '@air/api/types';
import { isEmpty, isNil, keyBy } from 'lodash';
import { useCallback } from 'react';

import { ItemTypeFilter } from '~/components/Filters/types';
import { CustomFieldSelectedFilter, Filters } from '~/store/filters/types';
import { isLocationParamSeparate } from '~/utils/filters/types';
import {
  getColorsStateFromParams,
  getCustomFieldsStateFromParams,
  getDateStateFromParams,
  getKeywordsStateFromParams,
  getLocationStateFromParams,
  getTagsStateFromParams,
  getTypesStateFromParams,
  getUploadersStateFromParams,
} from '~/utils/filters/urlParamsToFiltersUtils';
import { isDefined } from '~/utils/isDefined';

export const useCreateParamsFromSavedFilters = () => {
  const createParamsFromSavedFilters = useCallback(
    (
      savedFilterCriteria: AssetSearchFiltersCalculated,
      options: FilterLookupsResponse,
      entities: EntityLookupResponse,
    ) => {
      const { uploaders: uploadersOptions = [] } = options;

      const { customFields: customFieldsOptions = [], tags: tagsOptions = [] } = entities;

      const params: Partial<Filters> = {};

      for (const [key, value] of Object.entries(savedFilterCriteria)) {
        switch (key as keyof AssetSearchFiltersCalculated) {
          case 'hasOpenComments': {
            const commentsValue = value as AssetSearchFiltersCalculated['hasOpenComments'];
            const hasComments = !isNil(commentsValue?.is);
            params.others = hasComments ? [...(params.others || []), 'has-open-comments'] : params.others;
            break;
          }
          case 'untagged': {
            const untaggedValue = value as AssetSearchFiltersCalculated['untagged'];
            const isUntagged = !isNil(untaggedValue?.is);
            params.others = isUntagged ? [...(params.others || []), 'is-untagged'] : params.others;
            break;
          }
          case 'hasMultipleVersions': {
            const multipleVersionsValue = value as AssetSearchFiltersCalculated['hasMultipleVersions'];
            const hasMultipleVersions = !isNil(multipleVersionsValue?.is);
            params.others = hasMultipleVersions ? [...(params.others || []), 'has-versions'] : params.others;
            break;
          }
          case 'bookmarked': {
            const bookmarkValue = value as AssetSearchFiltersCalculated['bookmarked'];
            const isBookmarked = !isNil(bookmarkValue?.is);
            params.others = isBookmarked ? [...(params.others || []), 'is-favorited'] : params.others;
            break;
          }
          case 'isOnBoards': {
            const onBoardValue = value as AssetSearchFiltersCalculated['isOnBoards'];
            const isOnBoards = !isNil(onBoardValue?.is);
            params.others = isOnBoards ? [...(params.others || []), 'is-on-boards'] : params.others;
            break;
          }
          case 'tag': {
            const tagsValue = value as AssetSearchFiltersCalculated['tag'];
            const tags = getTagsStateFromParams(tagsValue, tagsOptions);
            params.tags = tags;
            break;
          }
          case 'keyword': {
            const importedKeywordsValue = value as AssetSearchFiltersCalculated['keyword'];
            params.importedKeywords = getKeywordsStateFromParams(importedKeywordsValue);
            break;
          }
          case 'customFields': {
            const cfValue = value as AssetSearchFiltersCalculated['customFields'];
            let customFields: CustomFieldSelectedFilter[] = [];
            const cfWithKey = keyBy(customFieldsOptions, 'id');
            if (!isEmpty(cfWithKey) && !!cfValue) {
              customFields = getCustomFieldsStateFromParams(cfValue, cfWithKey);
            }
            params.customFields = customFields;
            break;
          }
          case 'extension': {
            const extensionValue = value as AssetSearchFiltersCalculated['extension'];
            params.extensions = extensionValue?.or;
            break;
          }
          case 'camera': {
            const cameraValue = (value as AssetSearchFiltersCalculated['camera'])?.or;
            if (!!cameraValue?.length) {
              params.camera = {
                make: cameraValue[0].make ?? '',
                models: cameraValue.map(({ model }) => model).filter(isDefined),
              };
            }
            break;
          }
          case 'creator': {
            const creatorValue = value as AssetSearchFiltersCalculated['creator'];
            params.creators = creatorValue?.or;
            break;
          }
          case 'color': {
            const colorValue = value as AssetSearchFiltersCalculated['color'];
            const colors = getColorsStateFromParams(colorValue);
            params.colors = colors;
            break;
          }
          case 'copyright': {
            const copyrightValue = value as AssetSearchFiltersCalculated['copyright'];
            params.copyrights = copyrightValue?.or ?? [];
            break;
          }
          case 'source': {
            const sourceValue = value as AssetSearchFiltersCalculated['source'];
            params.sources = sourceValue?.or ?? [];
            break;
          }
          case 'type': {
            const typeValue = value as AssetSearchFiltersCalculated['type'];
            let types: ItemTypeFilter[] = [];
            if (!!typeValue) {
              types = getTypesStateFromParams(
                (typeValue.and as ItemTypeFilter[]) || (typeValue.or as ItemTypeFilter[]),
              );
            }
            params.types = types;
            break;
          }
          case 'videoFrameRate': {
            const videoFrameRatesValue = value as AssetSearchFiltersCalculated['videoFrameRate'];
            params.videoFrameRates = (videoFrameRatesValue?.or ?? []).map((v) => v.toString());
            break;
          }
          case 'videoAspectRatio': {
            const videoAspectRatio = value as AssetSearchFiltersCalculated['videoAspectRatio'];
            params.videoAspectRatios = videoAspectRatio?.or;
            break;
          }
          case 'audioCoding': {
            const audioCodingValue = value as AssetSearchFiltersCalculated['audioCoding'];
            params.audioCodings = audioCodingValue?.or;
            break;
          }
          case 'audioSampleRate': {
            const audioSampleRateValue = value as AssetSearchFiltersCalculated['audioSampleRate'];
            params.audioSampleRates = audioSampleRateValue?.or?.map((v) => v.toString());
            break;
          }
          case 'updated': {
            const updatedValue = value as AssetSearchFiltersCalculated['updated'];
            let modifiedDate = null;
            if (!!updatedValue) {
              modifiedDate = getDateStateFromParams(updatedValue);
            }
            params.modifiedDate = modifiedDate || undefined;
            break;
          }
          case 'recorded': {
            const recordedValue = value as AssetSearchFiltersCalculated['recorded'];
            let recordedDate = null;
            if (!!recordedValue) {
              recordedDate = getDateStateFromParams(recordedValue);
            }
            params.createdDate = recordedDate || undefined;
            break;
          }
          case 'location': {
            const locationValue = value as AssetSearchFiltersCalculated['location'];
            if (locationValue && isLocationParamSeparate(locationValue)) {
              const { states, cities, countries } = getLocationStateFromParams(locationValue);

              params.countries = countries;
              params.states = states;
              params.cities = cities;
            }

            break;
          }
          case 'created': {
            const createdValue = value as AssetSearchFiltersCalculated['created'];
            let createdDate = null;
            if (!!createdValue) {
              createdDate = getDateStateFromParams(createdValue);
            }
            params.uploadedDate = createdDate || undefined;
            break;
          }
          case 'dateTaken': {
            const takenValue = value as AssetSearchFiltersCalculated['dateTaken'];
            let takenDate = null;
            if (!!takenValue) {
              takenDate = getDateStateFromParams(takenValue);
            }
            params.takenDate = takenDate || undefined;
            break;
          }
          case 'member': {
            const memberValue = value as AssetSearchFiltersCalculated['member'];
            const uploaders = getUploadersStateFromParams(memberValue, uploadersOptions);
            params.uploaders = uploaders;
          }
        }
      }

      return params;
    },
    [],
  );

  return { createParamsFromSavedFilters };
};
