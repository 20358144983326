import { CustomFieldValue, VisibleColumnType } from '@air/api/types';
import { Check as CheckIcon, EyeClosed, Field as FieldIcon, TriangleDown } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useBreakpointsContext } from '@air/provider-media-query';
import { CSSProperties, memo, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import { KanbanColumnHeaderDisplay } from '~/components/KanbanColumnHeaderDisplay';
import { DropdownMenuWithActionSheet } from '~/components/Menus/DropdownMenuWithActionSheet';
import { KANBAN_GROUP_BY_MENU } from '~/constants/testIDs';
import {
  setKanbanGroupByAction,
  setKanbanVisibleColumnsAction,
  showKanbanColumnAction,
} from '~/store/configViews/actions';
import {
  currentKanbanGroupBySelector,
  currentViewTypeNameSelector,
  kanbanGroupByOptionsSelector,
  visibleColumnIdsSelector,
} from '~/store/configViews/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export const ViewKanbanGroupByMenu = memo(
  ({ customFieldValues }: { customFieldValues: CustomFieldValue[] | undefined }) => {
    const [buttonFlashStyles, setButtonFlashStyles] = useState<CSSProperties>({});
    const groupByField = useAirSelector(currentKanbanGroupBySelector, shallowEqual);
    const [fetchedCustomField, setFetchedCustomField] = useState(groupByField?.customFieldId || null);
    const dispatch = useDispatch();
    const currentViewType = useAirSelector(currentViewTypeNameSelector);
    const groupByOptions = useAirSelector(kanbanGroupByOptionsSelector);
    const { isAboveMediumScreen } = useBreakpointsContext();
    const visibleColumnIds = useAirSelector(visibleColumnIdsSelector);
    const [prevVisibleColumnIds, setPrevVisibleColumnIds] = useState(visibleColumnIds);
    useEffect(() => {
      if (!!currentViewType && !groupByField && groupByOptions.length) {
        // first option will exist, as will its customFieldId
        const firstCFID = groupByOptions[0].customFieldId!;
        dispatch(setKanbanGroupByAction({ customFieldId: firstCFID }));
      }
    }, [groupByField, groupByOptions, dispatch, currentViewType]);
    useEffect(() => {
      // when groupByField changes, new columns fetched
      // set them in redux in order to be saved and read from
      if (!fetchedCustomField && groupByField?.customFieldId) {
        return setFetchedCustomField(groupByField.customFieldId);
      }
      if (groupByField?.customFieldId !== fetchedCustomField && customFieldValues?.length) {
        setFetchedCustomField(groupByField?.customFieldId || null);
        dispatch(setKanbanVisibleColumnsAction({ customFieldValues }));
      }
    }, [dispatch, groupByField?.customFieldId, fetchedCustomField, customFieldValues]);

    useEffect(() => {
      if (!visibleColumnIds) return;
      if (!prevVisibleColumnIds) {
        return setPrevVisibleColumnIds(visibleColumnIds);
      }
      if (visibleColumnIds.length < prevVisibleColumnIds.length) {
        setButtonFlashStyles({ backgroundColor: '#FFE784' });
        setTimeout(() => {
          setButtonFlashStyles({});
        }, 1000);
      }
      setPrevVisibleColumnIds(visibleColumnIds);
    }, [prevVisibleColumnIds, visibleColumnIds]);
    const hiddenColumnsOptions = useMemo(() => {
      if (!visibleColumnIds || !customFieldValues) return undefined;
      const columns: DropdownMenuOption[] = [];
      if (!visibleColumnIds.includes(VisibleColumnType.unassignedCustomFieldValue)) {
        const onClick = () =>
          dispatch(showKanbanColumnAction({ column: { type: VisibleColumnType.unassignedCustomFieldValue } }));
        columns.push({
          id: VisibleColumnType.unassignedCustomFieldValue,
          label: (
            <KanbanColumnHeaderDisplay
              id={VisibleColumnType.unassignedCustomFieldValue}
              color="transparent"
              label="None"
            />
          ),
          suffix: <EyeClosed className="h-4 w-4 text-pigeon-400" />,
          onSelect: onClick,
          type: 'item',
        });
      }
      customFieldValues.forEach((cfValue) => {
        if (!visibleColumnIds.includes(cfValue.id)) {
          const onClick = () =>
            dispatch(
              showKanbanColumnAction({
                column: {
                  type: VisibleColumnType.customFieldValue,
                  customFieldValueId: cfValue.id,
                  value: cfValue.value,
                },
              }),
            );
          columns.push({
            id: cfValue.id,
            label: (
              <KanbanColumnHeaderDisplay
                id={VisibleColumnType.unassignedCustomFieldValue}
                color={cfValue.color.name}
                label={cfValue.value}
              />
            ),
            suffix: <EyeClosed className="h-4 w-4 text-pigeon-400" />,
            onSelect: onClick,
            type: 'item',
          });
        }
      });
      return columns;
    }, [dispatch, visibleColumnIds, customFieldValues]);

    const options = useMemo(() => {
      if (!groupByOptions) return [];
      const opts: DropdownMenuOption[] = [];
      if (hiddenColumnsOptions?.length && isAboveMediumScreen) {
        opts.push({
          id: 'hiddenColumns',
          label: 'Hidden Columns',
          options: hiddenColumnsOptions,
          type: 'sub',
        });
      }
      groupByOptions.forEach((opt) => {
        const onClick = () => dispatch(setKanbanGroupByAction({ customFieldId: opt.customFieldId! }));
        opts.push({
          id: opt?.customFieldId ?? '',
          label: opt.name,
          prefix: <FieldIcon className="flex h-4 w-4 text-pigeon-400" />,
          suffix: groupByField?.customFieldId === opt.customFieldId ? <CheckIcon className="h-4 w-4" /> : null,
          onSelect: onClick,
          type: 'item',
        });
      });
      if (hiddenColumnsOptions?.length && !isAboveMediumScreen) {
        opts.push({
          id: 'separator',
          type: 'separator',
        });

        opts.push({
          id: 'hiddenColumns',
          label: 'Hidden Columns',
          type: 'title',
        });

        hiddenColumnsOptions.forEach((col) => {
          opts.push(col);
        });
      }
      return opts;
    }, [isAboveMediumScreen, hiddenColumnsOptions, dispatch, groupByField?.customFieldId, groupByOptions]);

    if (!groupByOptions.length || !groupByField) return null;

    return (
      <DropdownMenuWithActionSheet
        title={`Group by: ${groupByField.name}`}
        trigger={
          <Button
            appearance="ghost"
            color="grey"
            data-testid={KANBAN_GROUP_BY_MENU}
            size="medium"
            suffix={<TriangleDown className="h-4 w-4" />}
            style={buttonFlashStyles}
          >
            Group by: {groupByField.name}
          </Button>
        }
        options={options}
      />
    );
  },
);

ViewKanbanGroupByMenu.displayName = 'ViewKanbanGroupByMenu';
