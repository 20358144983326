import { CSSProperties } from 'react';

import LoadMoreSpinner from '~/components/LoadMoreSpinner';
import { TableRow } from '~/components/TableView/TableRow/TableRow';

interface TableLoaderRowProps {
  loadMore: () => void;
  isLoading: boolean;
  isVisible: boolean;
  style: CSSProperties;
}

const TableLoaderRow = ({ isVisible, isLoading, loadMore, style }: TableLoaderRowProps) => (
  <TableRow style={style} className="justify-center">
    <LoadMoreSpinner loadMore={loadMore} isLoading={isLoading} isVisible={isVisible} topOffset={0} />
  </TableRow>
);

export default TableLoaderRow;
