import { Boards } from '@air/api';
import { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useUpdateBoardInAllViews } from '~/utils/mutateUtils/GalleryBoards';

export interface SetBoardThumbnailParams {
  /** The id of the board you want to add the thumbnail on */
  boardId: Board['id'];
  /** The clip information for the desired thumbnail */
  clip: Pick<Clip, 'id' | 'assets'>;
}

export const useSetBoardThumbnail = () => {
  const { updateBoardInAllViews } = useUpdateBoardInAllViews();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const setBoardThumbnail = useCallback(
    async ({ boardId, clip }: SetBoardThumbnailParams) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      try {
        const newBoard = {
          id: boardId,
          thumbnails: [clip.assets.image],
        };

        const response = await Promise.all([
          Boards.updateThumbnail({ boardId, workspaceId, clipId: clip.id }),
          updateBoardInAllViews(newBoard),
        ]);
        return response[0];
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Unable to update board thumbnail',
          metadata: { Data: { clip, boardId } },
        });
      }
    },
    [updateBoardInAllViews, workspaceId],
  );

  return {
    setBoardThumbnail,
  };
};
