import { memo, useCallback, useMemo } from 'react';

import { SelectProps } from '~/components/Select/Select';
import { SingleSelect } from '~/components/Select/SingleSelect';
import { FilterLogicType } from '~/store/filters/types';

export interface FilterLogicSelectProps {
  selectedLogic: FilterLogicType;
  onLogicChange: (selected: FilterLogicType) => void;
  logicOptions?: FilterLogicType[];
}

interface LogicOption {
  value: FilterLogicType;
  label: string;
}

const logicSelectOptions: LogicOption[] = [
  { value: 'and', label: 'Contains' },
  { value: 'or', label: 'Contains one of...' },
  { value: 'not', label: 'Does not contain' },
];

const allLogicOptions: FilterLogicType[] = ['and', 'or', 'not'];

export const FilterLogicSelect = memo(
  ({ selectedLogic, onLogicChange, logicOptions = allLogicOptions }: FilterLogicSelectProps) => {
    const onSelectionChange: SelectProps<LogicOption>['onSelectionChange'] = useCallback(
      ({ chips }) => {
        onLogicChange(chips[chips.length - 1].value);
      },
      [onLogicChange],
    );

    const availableOptions = useMemo(
      () => logicSelectOptions.filter((option) => logicOptions.includes(option.value)),
      [logicOptions],
    );

    return (
      <SingleSelect
        options={availableOptions}
        selectedOption={logicSelectOptions.find((option) => option.value === selectedLogic)!}
        onSelectionChange={onSelectionChange}
        placeholder=""
        size="large"
        className="mb-4"
      />
    );
  },
);

FilterLogicSelect.displayName = 'FilterLogicSelect';
