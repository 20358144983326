import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { Portal } from '@air/primitive-portal';
import { memo, ReactNode } from 'react';

export interface FiltersMobileContainerProps {
  content: ReactNode;
  onClose: () => void;
}

export const MobileFiltersContainer = memo(({ content, onClose }: FiltersMobileContainerProps) => {
  return (
    <Portal>
      <div className="fixed inset-y-0 left-0 flex h-full w-screen flex-col bg-surface-1">
        <div className="flex h-full w-full shrink-0 flex-col overflow-auto">{content}</div>
        <IconButton
          appearance="ghost"
          className="absolute right-3 top-3"
          color="grey"
          icon={Close}
          label="Close filters"
          onClick={onClose}
          size="small"
        />
      </div>
    </Portal>
  );
});

MobileFiltersContainer.displayName = 'MobileFiltersContainer';
