import { Modal } from '@air/primitive-modal';
import { useAirModal } from '@air/provider-modal';
import { useShortIdContext } from '@air/provider-short-id';
import { memo, useCallback } from 'react';

import { AnonymousSignUpFormWrapper } from '~/components/AnonymousSignUpForm/AnonymousSignUpFormWrapper';
import LoginModal, { LoginModalProps } from '~/components/Modals/LoginModal/LoginModal';
import { AnonymousSignUpModalHeader } from '~/components/PublicLinkAnonymousSignUpModal/shared/AnonymousSignUpModalHeader';

export interface PublicLinkAnonymousSignUpModalProps {
  onLoginSuccess: () => void;
  ssoCallbackData: LoginModalProps['ssoCallbackData'];
}

export const PublicCustomFieldAnonymousSignUpModal = memo(
  ({ onClose, onLoginSuccess, ssoCallbackData }: AirModalProps<PublicLinkAnonymousSignUpModalProps>) => {
    const { shortId, objectType, objectId } = useShortIdContext();
    const [showLoginModal] = useAirModal(LoginModal);

    const _onLoginSuccess = useCallback(() => {
      onClose();
      onLoginSuccess();
    }, [onClose, onLoginSuccess]);

    const onFullLoginClick = useCallback(() => {
      onClose();
      showLoginModal({
        ssoCallbackData,
        onSuccess: _onLoginSuccess,
        accountCreationSource: 'share-link',
      });
    }, [_onLoginSuccess, onClose, showLoginModal, ssoCallbackData]);

    return (
      <Modal data-testid="CUSTOM_FIELD_ANONYMOUS_SIGNUP_MODAL" isOpen className="p-0">
        <AnonymousSignUpModalHeader alt="Cloud custom field" imageSrc="/assets/illustrations/cloud-custom-field.svg" />
        <AnonymousSignUpFormWrapper
          title="Want to change fields?"
          description="Add your info so your partners know who made edits."
          onLoggedIn={_onLoginSuccess}
          onFullLoginClick={onFullLoginClick}
          onCancelClick={onClose}
          shortId={shortId}
          objectId={objectId}
          objectType={objectType}
          trackLocation="custom-field-change"
        />
      </Modal>
    );
  },
);

PublicCustomFieldAnonymousSignUpModal.displayName = 'PublicLinkAnonymousSignUpModal';
