import { Calendar } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { DateInput, DateInputProps } from '@air/primitive-date-input';
import { tailwindMerge } from '@air/tailwind-variants';
import { parseAirDateToISO } from '@air/utilities';
import { reportErrorToBugsnag } from '@air/utils-error';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

export interface EditableDateProps extends Pick<DateInputProps, 'placeholder' | 'id' | 'size' | 'name'> {
  'data-testid'?: string;
  isEditing?: boolean;
  /**
   * This will set the max character length for the textarea.
   */
  onEditingStateChange?: (isEditingState: boolean) => void;
  readOnly?: boolean;
  dateValue?: string;
  onChange: (value: Date | undefined) => void;
  showCalendarIcon?: boolean;
}

export const EditableDate = ({
  ['data-testid']: testId,
  isEditing = false,
  onEditingStateChange,
  placeholder,
  readOnly,
  name,
  dateValue = '',
  onChange,
  size = 'large',
  showCalendarIcon = true,
}: EditableDateProps) => {
  const [isEditingState, setIsEditingState] = useState(isEditing);
  const isPreviousIsEditing = usePrevious(isEditingState);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);

  const setIsEditing = useCallback(
    (isEditing: boolean) => {
      setIsEditingState(isEditing);
      onEditingStateChange?.(isEditing);
    },
    [onEditingStateChange],
  );

  const buttonText = useMemo(() => {
    /** `dateValue` shows Invalid Date on Safari, so we reformat it here */
    const formattedDate = dateValue ? parseAirDateToISO(dateValue).toString() : undefined;
    try {
      return formattedDate ? format(new Date(formattedDate), 'MM/dd/yyyy') : placeholder;
    } catch (error) {
      reportErrorToBugsnag({
        error,
        context: 'Failed to format date in EditableDate',
        metadata: {
          dateValue,
          formattedDate,
        },
      });

      return '';
    }
  }, [dateValue, placeholder]);

  useEffect(() => {
    if (isEditingState && dateInputRef?.current) {
      dateInputRef.current?.focus();
      dateInputRef.current.setSelectionRange(dateInputRef.current.value.length, dateInputRef.current.value.length);
    }

    if (!isEditingState && isPreviousIsEditing && buttonRef?.current) {
      buttonRef.current.focus();
    }
  }, [buttonRef, isEditingState, isPreviousIsEditing, dateInputRef]);

  return (
    <div
      className={tailwindMerge('flex w-full rounded', readOnly ? 'cursor-default' : 'cursor-pointer')}
      data-testid={testId}
    >
      {isEditingState ? (
        <DateInput
          name={name}
          testId="DATE_INPUT_FIELD"
          showCalendarIcon={showCalendarIcon}
          onSelect={(_, e) => {
            e?.stopPropagation();
          }}
          className="w-full"
          size={size}
          autoFocus
          selected={dateValue ? parseAirDateToISO(dateValue) : undefined}
          placeholderText={placeholder}
          // timeouts are so clear button has a chance to trigger before close/blur do which unrender the component
          onCalendarClose={() => setTimeout(() => setIsEditing(false), 100)}
          onBlur={() => setTimeout(() => setIsEditing(false), 100)}
          onChange={(date) => {
            setIsEditing(false);
            onChange(date);
          }}
          ref={dateInputRef}
        />
      ) : (
        <Button
          appearance="ghost"
          color="grey"
          prefix={showCalendarIcon ? <Calendar className="h-4 w-4" /> : undefined}
          disabled={readOnly}
          onClick={() => {
            setIsEditing(true);
          }}
          size="large"
          className={tailwindMerge(
            'w-full justify-start pl-2 font-normal disabled:bg-transparent',
            !dateValue ? 'text-grey-7' : '',
          )}
          ref={buttonRef}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};
