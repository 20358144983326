import { DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { keyBy } from 'lodash';

import { DateFilters, FilterSelectType } from '~/components/FiltersMenu/types';
import { getFilterProps } from '~/components/FiltersMenu/utils/getFilterProps';
import { SelectedFilter } from '~/store/filters/types';

const dateFiltersLabels: { [key in DateFilters]: { label: string } } = {
  dateCreated: { label: 'Created' },
  dateTaken: { label: 'Taken' },
  dateUploaded: { label: 'Uploaded' },
  dateModified: { label: 'Modified' },
};

export const getDateFiltersOptions = ({
  selectedFilters,
  dateFilters,
  onSelect,
}: {
  selectedFilters: SelectedFilter[];
  dateFilters: DateFilters[];
  onSelect: FilterSelectType;
}) => {
  const selectedFiltersTypes = keyBy(selectedFilters, 'type');

  const options: DropdownMenuOption[] = dateFilters.map((dateFilter) => ({
    ...getFilterProps({ type: dateFilter, onSelect, isSelected: !!selectedFiltersTypes[dateFilter] }),
    label: dateFiltersLabels[dateFilter].label,
    prefix: null,
    type: 'item',
  }));

  return options;
};
