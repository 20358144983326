import { memo } from 'react';

import {
  BulkEditPlainTextCustomFieldModal,
  BulkEditPlainTextCustomFieldModalProps,
} from '~/components/Modals/BulkEditCustomFieldModal/BulkEditPlainTextCustomFieldModal';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export type PrivateBulkEditPlainTextCustomFieldModalProps = Omit<BulkEditPlainTextCustomFieldModalProps, 'onSave'>;

export const PrivateBulkEditPlainTextCustomFieldModal = memo(
  (props: AirModalProps<PrivateBulkEditPlainTextCustomFieldModalProps>) => {
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

    return (
      <BulkEditPlainTextCustomFieldModal
        {...props}
        onSave={async (params) => {
          await updateItemsCustomFieldValue({
            ...params,
            trackingLocation: 'context-menu-bulk-edit',
            workspaceId,
          });
        }}
      />
    );
  },
);
PrivateBulkEditPlainTextCustomFieldModal.displayName = 'PrivateBulkEditPlainTextCustomFieldModal';
