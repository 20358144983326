import classNames from 'classnames';
import { forwardRef, HTMLAttributes, memo } from 'react';

import { TABLE_ROW_HEIGHT_CLASS } from '../ui';

export interface TableRowProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const TableRow = memo(
  forwardRef<HTMLDivElement, TableRowProps>(({ className, ...props }, ref) => (
    <div
      tabIndex={0}
      className={classNames(`tableRow flex items-center text-sm text-grey-10 ${TABLE_ROW_HEIGHT_CLASS}`, className)}
      ref={ref}
      {...props}
    />
  )),
);

TableRow.displayName = 'TableRow';
