import { CustomFieldValue, CustomFieldValueListResponse } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { differenceBy, intersectionBy, map } from 'lodash';
import pluralize from 'pluralize';
import React, { memo, useCallback, useMemo, useState } from 'react';

import { Select, SelectProps } from '~/components/Select/Select';
import { DefaultChipType } from '~/components/Select/shared/types';
import { BULK_EDIT_MULTI_SELECT_MODAL, CUSTOM_FIELD_SELECT_COMPONENT } from '~/constants/testIDs';
import { getCustomFieldSelectOption } from '~/utils/CustomFields';

import { BulkEditCustomFieldModalProps } from './types';

export interface BulkEditMultiSelectCustomFieldModalProps extends BulkEditCustomFieldModalProps {
  cfOptions: CustomFieldValueListResponse | undefined;
  onSave: (props: {
    assetIds: string[];
    boardIds: string[];
    baseCustomField: BulkEditCustomFieldModalProps['field'];
    multiValueIdsToRemove: string[];
    multiValuesToAdd: CustomFieldValue[];
  }) => Promise<void>;
}

export const BulkEditMultiSelectCustomFieldModal = memo(
  ({
    field,
    assetIds,
    boardIds,
    initialValues: _initialValues,
    onClose,
    cfOptions,
    onSave,
  }: AirModalProps<BulkEditMultiSelectCustomFieldModalProps>) => {
    const fieldValues = useMemo(() => cfOptions?.data || [], [cfOptions?.data]);

    const initialValues = intersectionBy(..._initialValues.map((cf) => cf.values || []), 'id').map(
      getCustomFieldSelectOption,
    );

    const [values, setValues] = useState<DefaultChipType[]>(initialValues);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const selectOptions = useMemo(() => {
      if (!cfOptions) return [];
      return cfOptions.data.map((option) => getCustomFieldSelectOption(option));
    }, [cfOptions]);

    const onSelectionChange: SelectProps<DefaultChipType>['onSelectionChange'] = useCallback(({ chips }) => {
      setIsTouched(true);
      setValues(chips);
    }, []);

    const onConfirm = useCallback(async () => {
      setIsSubmitting(true);
      const valueIdsToAdd = map(differenceBy(values, initialValues, 'value'), 'value');
      const multiValueIdsToRemove = map(differenceBy(initialValues, values, 'value'), 'value');
      const multiValuesToAdd = fieldValues.filter((v) => valueIdsToAdd.includes(v.id));

      await onSave({
        assetIds,
        boardIds,
        baseCustomField: field,
        multiValueIdsToRemove,
        multiValuesToAdd,
      });
      onClose();
    }, [values, initialValues, fieldValues, onSave, assetIds, boardIds, field, onClose]);
    const placeholder = useMemo(() => {
      if (isTouched) return 'None';
      return 'Find an option';
    }, [isTouched]);

    return (
      <Modal isOpen data-testid={BULK_EDIT_MULTI_SELECT_MODAL} onDismiss={onClose}>
        <div className="flex flex-col gap-6">
          <header className="flex justify-between gap-4">
            <ModalTitle data-testid="MODAL_LABEL">Edit {field.name}</ModalTitle>
            <ModalCloseButton className="shrink-0" onClick={onClose} />
          </header>
          <p className="text-16 text-grey-11">
            Choose or remove a value or multiple values from the list below to apply to the{' '}
            {pluralize('item', assetIds.length + boardIds.length, true)} you’ve selected.
          </p>
          <Select
            autoFocus
            data-testid={`${CUSTOM_FIELD_SELECT_COMPONENT}-${field.name}`}
            options={selectOptions}
            placeholder={placeholder}
            selectedOptions={values}
            isClearable
            isSearchable
            className="w-full"
            maxDropdownHeight={300}
            onSelectionChange={onSelectionChange}
            isSingleSelect={false}
            size="extra-large"
            isLoading={!cfOptions}
          />
          <footer className="flex justify-end gap-3">
            <Button appearance="ghost" color="grey" onClick={onClose} size="large">
              Cancel
            </Button>
            <Button
              data-testid="BULK_EDIT_MULTI_SELECT_CUSTOM_FIELD_MODAL_SAVE"
              isLoading={isSubmitting}
              disabled={!isTouched || isSubmitting}
              appearance="filled"
              color="blue"
              size="large"
              onClick={onConfirm}
            >
              Save
            </Button>
          </footer>
        </div>
      </Modal>
    );
  },
);
BulkEditMultiSelectCustomFieldModal.displayName = 'BulkEditMultiSelectCustomFieldModal';
