import { Check } from '@air/next-icons';
import { DropdownMenuOption } from '@air/primitive-dropdown-menu';

import { CustomFieldFilterType, FilterSelectType } from '~/components/FiltersMenu/types';
import { customFieldIconMap } from '~/constants/customFieldIconMap';
import { SelectedFilter } from '~/store/filters/types';

export const getCustomFieldsFilterOptions = ({
  customFields,
  selectedFilters,
  onSelect,
}: {
  customFields: CustomFieldFilterType[];
  selectedFilters: SelectedFilter[];
  onSelect: FilterSelectType;
}): DropdownMenuOption[] =>
  customFields.map((field) => {
    const isFieldSelected = selectedFilters.some(
      ({ type, customField }) => type === 'customField' && !!customField && field.id === customField?.id,
    );

    const Icon = customFieldIconMap[field.type.title];

    return {
      disabled: isFieldSelected,
      id: field.id,
      label: field.name,
      onSelect: () => onSelect('customField', field),
      'data-testid': `customField-${field.id}`,
      prefix: <Icon width={16} />,
      suffix: isFieldSelected ? <Check className="h-4 w-4 text-pigeon-500" /> : null,
      type: 'item',
    };
  });
