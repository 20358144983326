import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import { TypeFilter } from '~/components/Filters/TypeFilter/TypeFilter';
import { ItemTypeFilter } from '~/components/Filters/types';
import {
  addItemTypeFilterAction,
  clearItemTypeFiltersAction,
  removeFilterAction,
  removeItemTypeFilterAction,
} from '~/store/filters/actions';
import { selectedItemTypesFiltersSelector } from '~/store/filters/selectors';
import { SelectedFilter } from '~/store/filters/types';

export interface TypeFilterCardProps extends BaseFilterCardProps {
  availableTypes?: ItemTypeFilter[];
}

export const TypeFilterCard = memo(({ availableTypes, ...props }: TypeFilterCardProps) => {
  const dispatch = useDispatch();
  const selectedTypes = useSelector(selectedItemTypesFiltersSelector);

  const clearValues = useCallback(() => {
    dispatch(clearItemTypeFiltersAction());
  }, [dispatch]);

  const onTypeSelectedChange = (type: ItemTypeFilter, isSelected: boolean) => {
    if (isSelected) {
      dispatch(addItemTypeFilterAction({ type }));
    } else {
      dispatch(removeItemTypeFilterAction({ type }));
    }
  };

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
    clearValues();
  };

  return (
    <FilterCard currentFilter="type" onClearClick={clearValues} onRemoveFilter={onRemoveFilter} {...props}>
      <TypeFilter
        availableTypes={availableTypes}
        onTypeSelectedChange={onTypeSelectedChange}
        selectedTypes={selectedTypes}
      />
    </FilterCard>
  );
});

TypeFilterCard.displayName = 'TypeFilterCard';
