import { useMemo } from 'react';

import { WKSPC_VIEW_CONTROL_HEIGHT } from '~/constants/WorkspaceSpacing';
import { useSearchHeaderHeightContext } from '~/providers/SearchHeaderHeightProvider';

/**
 * tableHeaderTopPos: in table view, the header is sticky except in mobile. It accounts for other sticky things above it.
 */
export function useTableHeaderPosition() {
  const { searchHeaderHeight } = useSearchHeaderHeightContext();

  const tableHeaderTopPos = useMemo(() => {
    return searchHeaderHeight + WKSPC_VIEW_CONTROL_HEIGHT;
  }, [searchHeaderHeight]);

  return { tableHeaderTopPos };
}
