import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, forwardRef } from 'react';

export interface TableCellProps extends ComponentProps<'div'> {
  width?: number;
  minWidth?: number;
}

export const TableCell = forwardRef<HTMLDivElement, TableCellProps>(
  ({ className, style, width, minWidth, ...restProps }: TableCellProps, forwardedRef) => (
    <div
      ref={forwardedRef}
      className={tailwindMerge('table-cell w-full select-none items-center truncate p-4', className)}
      style={{
        flex: width ? `1 0 ${width}px` : `1 1 100%`,
        minWidth: minWidth || width ? `${minWidth}px` : 'unset',
        ...style,
      }}
      {...restProps}
    />
  ),
);

TableCell.displayName = 'TableCell';
