import React, { memo } from 'react';

import {
  DropdownMenuWithActionSheet,
  type DropdownMenuWithActionSheetProps,
} from '~/components/Menus/DropdownMenuWithActionSheet';

export interface FiltersMenuProps {
  trigger: DropdownMenuWithActionSheetProps['trigger'];
  options: DropdownMenuWithActionSheetProps['options'];
}

export const FiltersMenu = memo(({ trigger, options }: FiltersMenuProps) => {
  return <DropdownMenuWithActionSheet title="Select filter" options={options} trigger={trigger} />;
});

FiltersMenu.displayName = 'FiltersMenu';
