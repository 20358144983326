import { AirActionTrackingLocation } from '@air/analytics';
import { Board, Clip, ShortUrlObjectType } from '@air/api/types';
import { Modal } from '@air/primitive-modal';
import { useEffect, useMemo } from 'react';

import { NoPermissionToCreateShareLink } from '~/components/NoPermissionToCreateShareLink';
import { SharePrivateClipTabs } from '~/components/SharePrivateClipModal/SharePrivateClipTabs';
import { ReadonlySharePrivateLink } from '~/components/SharePrivateContentModal/ReadonlySharePrivateLink';
import { ShareModalHeader } from '~/components/SharePrivateContentModal/ShareModalHeader';
import { SharePrivateContentSpinner } from '~/components/SharePrivateContentModal/SharePrivateContentSpinner';
import { SHARE_PRIVATE_CONTENT_MODAL } from '~/constants/testIDs';
import { useShowTurnOnShareLinkNotifications } from '~/hooks/turnOnEmailShareNotificationsToast/useShowTurnOnShareLinkNotifications';
import { useClipPermissions } from '~/hooks/useClipPermissions';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useGetExistingShortIdInformation } from '~/swr-hooks/shortUrl/useGetExistingShortIdInformation';
import { useGetShortIdForClip } from '~/swr-hooks/shortUrl/useGetShortIdForClip';
import { canSeeAssetVersions } from '~/utils/permissions/assetPermissions';
import { canCreateShortUrl, canEditShortUrl, canViewShortUrl } from '~/utils/permissions/shortUrlPermissions';

type SharePrivateClipModalProps = AirModalProps<{
  clip: Pick<Clip, 'id' | 'assetVersionCount' | 'ext'>;
  trackLocation: AirActionTrackingLocation;
  boardContext: Board['id'] | undefined;
}>;

export const SharePrivateClipModal = ({ clip, onClose, trackLocation, boardContext }: SharePrivateClipModalProps) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const { clipPermissions } = useClipPermissions({
    clipId: clip.id,
  });

  const canEdit = canEditShortUrl(clipPermissions);
  const canCreate = canCreateShortUrl(clipPermissions);

  const { showTurnOnShareLinkNotifications } = useShowTurnOnShareLinkNotifications();

  const { data: shortIdInfo, isLoading: isInitialLoading } = useGetExistingShortIdInformation({
    id: clip.id,
    boardContext,
    type: ShortUrlObjectType.clip,
    canView: canViewShortUrl(clipPermissions),
  });

  const { getShortIdForClip } = useGetShortIdForClip();

  useEffect(() => {
    const workspaceId = currentWorkspace?.id;
    if (!workspaceId) {
      return;
    }
    if (!isInitialLoading && !shortIdInfo && canCreate && !getShortIdForClip.isPending) {
      getShortIdForClip.mutate(
        {
          workspaceId,
          id: clip.id,
          boardContext,
        },
        {
          onSuccess: () => {
            showTurnOnShareLinkNotifications({
              onManageClick: onClose,
            });
          },
        },
      );
    }
  }, [
    currentWorkspace?.id,
    clip.id,
    canCreate,
    getShortIdForClip,
    isInitialLoading,
    shortIdInfo,
    boardContext,
    showTurnOnShareLinkNotifications,
    onClose,
  ]);

  const ModalContent = useMemo(() => {
    if (!clipPermissions) {
      return <SharePrivateContentSpinner />;
    } else if (!shortIdInfo) {
      if (canCreate) {
        return <SharePrivateContentSpinner />;
      } else {
        return <NoPermissionToCreateShareLink />;
      }
    } else {
      if (!canEdit) {
        return (
          <ReadonlySharePrivateLink
            objectType={ShortUrlObjectType.clip}
            shareId={shortIdInfo.id}
            objectId={clip.id}
            trackLocation={trackLocation}
          />
        );
      } else {
        return (
          <SharePrivateClipTabs
            clip={clip}
            trackLocation={trackLocation}
            shortIdInfo={shortIdInfo}
            boardContext={boardContext}
          />
        );
      }
    }
  }, [boardContext, canCreate, canEdit, clip, clipPermissions, shortIdInfo, trackLocation]);

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const modalTitle = `Share a link to ${
    clip.assetVersionCount && !canSeeAssetVersions(shortIdInfo?.permissions) ? `V${clip.assetVersionCount}` : 'asset'
  }`;

  return (
    <Modal data-testid={SHARE_PRIVATE_CONTENT_MODAL} dangerouslyBypassFocusLock isOpen onDismiss={onClose}>
      <ShareModalHeader onClose={onClose} title={modalTitle} />
      {ModalContent}
    </Modal>
  );
};
