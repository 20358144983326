import { ViewFieldListResponse } from '@air/api/types';

/**
 * Filter out IPTC fields from viewFields:
 * - dateTaken
 */
export const filterOutIPTCFields = (viewFields: ViewFieldListResponse): ViewFieldListResponse => ({
  ...viewFields,
  data: viewFields.data.filter((field) => field.name !== 'dateTaken'),
});
