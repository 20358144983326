import { useFilterParams } from '~/hooks/filters/useFilterParams';
import { useHasAnyClipFilterApplied } from '~/hooks/filters/useHasAnyClipFilterApplied';
import { useIsFlattenedView } from '~/hooks/search/useIsFlattenedView';

export function useCanShowFiles() {
  const { typesParam } = useFilterParams();

  return {
    canShowFiles: typesParam.length === 0 || typesParam.includes('files'),
  };
}

export function useCanShowAssets() {
  const { typesParam } = useFilterParams();

  return { canShowAssets: typesParam.length === 0 || typesParam.includes('assets') };
}

export const useCanShowBoards = () => {
  const { typesParam } = useFilterParams();
  const { isFlattenedView } = useIsFlattenedView();
  const { hasAnyClipFilterApplied } = useHasAnyClipFilterApplied();

  /**
   * Any time a user applies a filter (besides the type filter), we hide boards.
   * For example, if a user chooses the uploader filter and selects to filter assets
   * by a user in the workspace who uploaded those assets, we don't show boards.
   */
  const canShowBoards =
    (typesParam.length === 0 || typesParam.includes('boards')) && !hasAnyClipFilterApplied && !isFlattenedView;

  // non-board pages should show boards only when search params are present
  return {
    canShowBoards,
  };
};
