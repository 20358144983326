import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import { OtherFilters, OtherFiltersProps } from '~/components/Filters/OtherFilters/OtherFilters';
import {
  addOtherFilterValueAction,
  clearOtherFiltersValuesAction,
  removeFilterAction,
  removeOtherFilterValueAction,
} from '~/store/filters/actions';
import { selectedOtherFiltersSelector } from '~/store/filters/selectors';
import { OtherFiltersType, SelectedFilter } from '~/store/filters/types';

export interface OtherFiltersCardProps extends BaseFilterCardProps {
  otherFilters?: OtherFiltersProps['otherFilters'];
}

export const OtherFiltersCard = memo(({ otherFilters, ...props }: OtherFiltersCardProps) => {
  const dispatch = useDispatch();
  const selectedFilters = useSelector(selectedOtherFiltersSelector);

  const clearValues = useCallback(() => {
    dispatch(clearOtherFiltersValuesAction());
  }, [dispatch]);

  const onTypeSelectedChange = useCallback(
    (filter: OtherFiltersType, isSelected: boolean) => {
      if (isSelected) {
        dispatch(addOtherFilterValueAction({ filter }));
      } else {
        dispatch(removeOtherFilterValueAction({ filter }));
      }
    },
    [dispatch],
  );

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
    clearValues();
  };

  return (
    <FilterCard currentFilter="other" onClearClick={clearValues} onRemoveFilter={onRemoveFilter} {...props}>
      <OtherFilters
        otherFilters={otherFilters}
        onTypeSelectedChange={onTypeSelectedChange}
        selectedFilters={selectedFilters}
      />
    </FilterCard>
  );
});

OtherFiltersCard.displayName = 'OtherFiltersCard';
