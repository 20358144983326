import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CreatedDateFilter } from '~/components/Filters/DateFilter/CreatedDateFilter';
import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import {
  removeFilterAction,
  setCreatedDateFilterEndDateAction,
  setCreatedDateFilterStartDateAction,
  setCreatedDateFilterTypeAction,
} from '~/store/filters/actions';
import {
  createdDateFilterDateTypeSelector,
  createdDateFilterEndDateSelector,
  createdDateFilterStartDateSelector,
} from '~/store/filters/selectors';
import { DateRangeType, SelectedFilter } from '~/store/filters/types';

export type CreatedDateFilterCardProps = BaseFilterCardProps;

export const CreatedDateFilterCard = memo((props: CreatedDateFilterCardProps) => {
  const dispatch = useDispatch();
  const dateType = useSelector(createdDateFilterDateTypeSelector);
  const startDate = useSelector(createdDateFilterStartDateSelector);
  const endDate = useSelector(createdDateFilterEndDateSelector);

  const onDateTypeChange = useCallback(
    (type: DateRangeType) => {
      dispatch(setCreatedDateFilterTypeAction({ type }));
    },
    [dispatch],
  );

  const onStartDateChange = useCallback(
    (startDate?: Date) => {
      dispatch(setCreatedDateFilterStartDateAction({ startDate }));
    },
    [dispatch],
  );

  const onEndDateChange = useCallback(
    (endDate?: Date) => {
      dispatch(setCreatedDateFilterEndDateAction({ endDate }));
    },
    [dispatch],
  );

  const onRemoveFilter = (filter: SelectedFilter) => {
    dispatch(removeFilterAction({ filter }));
  };

  return (
    <FilterCard currentFilter="dateCreated" onRemoveFilter={onRemoveFilter} {...props}>
      <CreatedDateFilter
        dateType={dateType}
        endDate={endDate}
        onTypeChange={onDateTypeChange}
        onEndDateChange={onEndDateChange}
        onStartDateChange={onStartDateChange}
        startDate={startDate}
      />
    </FilterCard>
  );
});

CreatedDateFilterCard.displayName = 'CreatedDateFilterCard';
