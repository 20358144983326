import { DateInput, DateInputProps } from '@air/primitive-date-input';

export interface DateRangeInputProps extends Omit<DateInputProps, 'onChange' | 'selectsRange'> {
  onStartDateChange: (date?: Date) => void;
  onEndDateChange: (date?: Date) => void;
  size: DateInputProps['size'];
}

export const DateRangeInput = ({
  size,
  startDate,
  endDate,
  onEndDateChange,
  onStartDateChange,
}: DateRangeInputProps) => {
  return (
    <div className="flex items-center">
      <DateInput
        size={size}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        maxDate={endDate}
        selectsStart
        name="startDate"
        onChange={onStartDateChange}
      />
      <div className="mx-2.5 h-0.5 w-3 rounded-sm bg-grey-9" />
      <DateInput
        size={size}
        selected={endDate}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        selectsEnd
        name="endDate"
        onChange={onEndDateChange}
      />
    </div>
  );
};
