import { CardSize } from '@air/api/types';

import { CARD_SIZE_PREFERENCES } from '~/constants/localStorageKeys';

export type CardSizeListener = (value: CardSize) => void;

export class CardSizeStore {
  private listeners: { [key: string]: CardSizeListener[] } = {};

  private static instance: CardSizeStore;

  static getInstance() {
    if (!CardSizeStore.instance) {
      CardSizeStore.instance = new CardSizeStore();
    }
    return CardSizeStore.instance;
  }

  getCardSize = (id: string): CardSize => {
    const cardSizePreferences = localStorage.getItem(CARD_SIZE_PREFERENCES);
    if (cardSizePreferences) {
      const parsedPreferences = JSON.parse(cardSizePreferences);
      return parsedPreferences[id] ?? 'medium';
    }
    return 'medium';
  };

  setCardSize = (id: string, value: CardSize) => {
    const cardSizePreferences = localStorage.getItem(CARD_SIZE_PREFERENCES);
    const parsedPreferences = cardSizePreferences ? JSON.parse(cardSizePreferences) : {};
    parsedPreferences[id] = value;
    localStorage.setItem(CARD_SIZE_PREFERENCES, JSON.stringify(parsedPreferences));
    this.listeners[id]?.forEach((listener) => listener(value));
  };

  subscribe = (id: string, callback: CardSizeListener) => {
    if (!this.listeners[id]) {
      this.listeners[id] = [callback];
    } else {
      this.listeners[id].push(callback);
    }

    const handleStorageChange = (params: StorageEvent) => {
      if (params.key === CARD_SIZE_PREFERENCES) {
        callback(this.getCardSize(id));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      this.listeners[id] = this.listeners[id].filter((listener) => listener !== callback);
      window.removeEventListener('storage', handleStorageChange);
    };
  };
}
