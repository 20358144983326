import { AirActionTrackingLocation, useTrackRemovedClipsFromBoard } from '@air/analytics';
import { Boards } from '@air/api';
import { Board, Clip } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';
import { useToasts } from '@air/provider-toast';
import { resetSelectedItemsAction } from '@air/redux-selected-items';
import { reportErrorToBugsnag } from '@air/utils-error';
import { noop } from 'lodash';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateBoardStats } from '~/utils/mutateUtils/BoardStats';
import { useRemoveAssetsFromAllViews } from '~/utils/mutateUtils/GalleryAssets';

interface RemoveClipsFromBoardModalProps {
  onComplete?: () => void;
  clipIds: Clip['id'][];
  boardId: Board['id'];
  boardTitle: Board['title'];
  trackLocation: AirActionTrackingLocation;
}

export const RemoveAssetsFromBoardModal = ({
  clipIds,
  onComplete = noop,
  onClose,
  trackLocation,
  boardId,
  boardTitle,
}: AirModalProps<RemoveClipsFromBoardModalProps>) => {
  const dispatch = useDispatch();
  const { showToast } = useToasts();
  const [removing, setRemoving] = useState(false);
  const { removeAssetsFromAllViews } = useRemoveAssetsFromAllViews();
  const { updateBoardStats } = useUpdateBoardStats();
  const { trackRemovedClipsFromBoard } = useTrackRemovedClipsFromBoard();

  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const onRemoveClips = async () => {
    setRemoving(true);

    if (!workspaceId) {
      throw new Error('No workspaceId found');
    }

    try {
      await Boards.removeClips({ boardId, clipIds, workspaceId });

      trackRemovedClipsFromBoard({
        boardId,
        clipIds,
        location: trackLocation,
      });

      removeAssetsFromAllViews({ clipIds, parentBoardId: boardId });
      updateBoardStats(boardId);

      onComplete();
      dispatch(resetSelectedItemsAction());

      showToast(`${pluralize('item', clipIds.length, true)} removed from ${boardTitle}`);

      onClose();
    } catch (error) {
      reportErrorToBugsnag({
        error,
        context: 'Failed to remove assets a board',
        metadata: { data: { clipIds, boardId } },
      });
    }
  };

  return (
    <ConfirmationModal
      id="remove-clips-from-board-modal"
      isOpen
      title={`Remove from ${boardTitle || 'board'}`}
      description={`The ${pluralize('assets', clipIds.length, false)} will still be accessible in search results.`}
      onDismiss={onClose}
      ctaPrimaryText={removing ? 'Removing...' : 'Remove'}
      isLoading={removing}
      isDisabled={removing}
      onConfirm={onRemoveClips}
    />
  );
};
