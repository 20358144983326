import { CustomFields } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { getCustomFieldOptionsKey } from '~/constants/react-query-keys';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

type UseGetPrivateCustomFieldOptionsParams = {
  customFieldId?: string;
  enabled?: boolean;
  staleTime?: number;
};

export const useGetPrivateCustomFieldOptions = ({
  customFieldId,
  enabled,
  staleTime,
}: UseGetPrivateCustomFieldOptionsParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  return useQuery({
    queryKey: getCustomFieldOptionsKey(customFieldId),

    queryFn: () => {
      invariant(workspaceId, 'No workspace id');
      invariant(customFieldId, 'No custom field id');

      return CustomFields.listCustomFieldValues({ customFieldId, workspaceId });
    },
    enabled: !!workspaceId && !!customFieldId && enabled,
    staleTime,
  });
};
