import {
  ActionSheet,
  ActionSheetHeader,
  type ActionSheetOption,
  renderActionSheetItems,
} from '@air/primitive-action-sheet';

export const MobileFiltersSubMenu = ({ onClose, options }: AirModalProps<{ options: ActionSheetOption[] }>) => {
  return (
    <ActionSheet onClose={onClose}>
      <ActionSheetHeader>Select custom field</ActionSheetHeader>
      {renderActionSheetItems({
        options: options.map((option) => {
          if (option.type !== 'item') return option;

          return {
            ...option,
            onSelect: () => {
              onClose();
              option.onSelect?.();
            },
            type: 'item',
          };
        }),
      })}
    </ActionSheet>
  );
};
