import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackRenamedClip.meta';

export type UseTrackRenamedClipParams = {
  clip_id: string;
  new_clip_title: string | undefined;
};

export const useTrackRenamedClip = () => {
  const { track } = useAnalyticsUtilities();

  const trackRenamedClip = useCallback(
    (params: UseTrackRenamedClipParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackRenamedClip };
};
