import { memo } from 'react';

import { DateFilter } from '~/components/Filters/DateFilter/DateFilter';

import { CreatedDateFilterProps } from './CreatedDateFilter';

export type ModifiedDateFilterProps = CreatedDateFilterProps;

export const ModifiedDateFilter = memo(
  ({ startDate, endDate, dateType, onStartDateChange, onEndDateChange, onTypeChange }: ModifiedDateFilterProps) => {
    return (
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        selectedType={dateType}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
        onTypeChange={onTypeChange}
      />
    );
  },
);

ModifiedDateFilter.displayName = 'ModifiedDateFilter';
