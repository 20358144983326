import { memo, useCallback } from 'react';

import { SelectChipOptionContainer } from '~/components/Select/components/SelectChipOptionContainer';
import { Select, SelectProps } from '~/components/Select/Select';

export interface BaseStringSelectProps extends Omit<SelectProps, 'options' | 'selectedOptions' | 'onSelectionChange'> {
  selectedValues: string[];
  onSelectionChange: (newString: string[]) => void;
}

interface StringSelectProps extends BaseStringSelectProps {
  values: string[];
}

export const StringSelect = memo(
  ({ placeholder, values, selectedValues, onSelectionChange, ...props }: StringSelectProps) => {
    const options = values.map((val) => ({ value: val, label: val }));
    const selectedOptions = selectedValues.map((val) => ({ value: val, label: val, borderRadius: 4 }));

    const onValuesChange: SelectProps['onSelectionChange'] = useCallback(
      ({ chips }) => {
        onSelectionChange(chips.map((chip) => chip.value));
      },
      [onSelectionChange],
    );

    const listItemRenderer = useCallback<Required<SelectProps>['listItemRenderer']>(
      (option) => (
        <SelectChipOptionContainer className="flex justify-between py-2">
          <p className="text-14 text-grey-12">{option.label}</p>
        </SelectChipOptionContainer>
      ),
      [],
    );

    return (
      <Select
        listItemRenderer={listItemRenderer}
        maxDropdownHeight={300}
        options={options}
        onSelectionChange={onValuesChange}
        selectedOptions={selectedOptions}
        renderAsInput
        isSearchable
        alwaysShowClearItemButton
        isClearable={false}
        isSticky
        placeholder={placeholder}
        size="large"
        {...props}
      />
    );
  },
);

StringSelect.displayName = 'StringSelect';
