import { EntityLookupResponse } from '@air/api';
import { FilterLookupsResponse } from '@air/api/types';
import { useMemo } from 'react';
import isEqual from 'react-fast-compare';

import { selectedFiltersValuesSelector } from '~/store/filters/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

import { useCreateParamsFromSavedFilters } from './useCreateParamsFromSavedFilters';
import { useGetFilterParamsWithTypeFilter } from './useGetFilterParamsWithTypesFilter';

export const useDidFilterChange = ({
  options,
  entities,
}: {
  options?: FilterLookupsResponse;
  entities?: EntityLookupResponse;
}) => {
  const { createParamsFromSavedFilters } = useCreateParamsFromSavedFilters();
  const filterParams = useGetFilterParamsWithTypeFilter();
  const selectedFiltersInStore = useAirSelector(selectedFiltersValuesSelector);

  const isFilterChanged = useMemo(() => {
    if (!!filterParams && !!options && !!entities) {
      const filterParamsConverted = createParamsFromSavedFilters(filterParams, options, entities);
      return !isEqual(filterParamsConverted, selectedFiltersInStore);
    }
    return false;
  }, [options, entities, createParamsFromSavedFilters, selectedFiltersInStore, filterParams]);

  return { isFilterChanged };
};
