import { Clip, ShortUrlResponse } from '@air/api/types';
import { merge } from 'lodash';
import { useCallback } from 'react';

import { ShareUrlResponseWithSource } from '~/components/SharePrivateClipModal/types';
import { useRevalidatePublicClip } from '~/hooks/useRevalidatePublicClip';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { UpdateShortIdParams, useUpdateShortId } from '~/swr-hooks/shortUrl/useUpdateShortId';

interface UseUpdateShareClipLinkParams {
  clipId: Clip['id'];
  shortIdInfo: ShortUrlResponse;
}

export const useUpdateShareClipLink = ({ clipId, shortIdInfo }: UseUpdateShareClipLinkParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { updateShortId } = useUpdateShortId();
  const { revalidatePublicClip } = useRevalidatePublicClip();

  const onShortUrlUpdate = useCallback(
    async (
      props: DeepPartial<Omit<UpdateShortIdParams, 'id' | 'objectId' | 'boardContext' | 'shortId'>>,
    ): Promise<ShareUrlResponseWithSource> => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      const response = await updateShortId.mutateAsync({
        workspaceId,
        ...merge({}, shortIdInfo, props),
        objectId: clipId,
        shortId: shortIdInfo.id,
        boardContext: shortIdInfo.boardContext,
      });

      /**
       * Don't await since this can be slow
       * @see https://air-labs-team.slack.com/archives/CSU3B8B3R/p1699565392614499?thread_ts=1699564602.295969&cid=CSU3B8B3R
       */
      revalidatePublicClip({
        clipId,
        boardContext: shortIdInfo.boardContext,
      });

      return {
        ...response,
        objectId: clipId,
        objectType: 'clip',
      };
    },
    [currentWorkspace?.id, clipId, revalidatePublicClip, shortIdInfo, updateShortId],
  );

  return {
    onShortUrlUpdate,
    isLoading: updateShortId.isPending,
  };
};
