import { AirActionTrackingLocation } from '@air/analytics';
import { Clip, ShortUrlResponse } from '@air/api/types';
import React, { memo, useCallback, useMemo, useState } from 'react';

import { EditableClipSharePrivateLink } from '~/components/SharePrivateClipModal/EditableClipSharePrivateLink';
import { useUpdateShareClipLink } from '~/components/SharePrivateClipModal/hooks/useUpdateShareClipLink';
import { SharePrivateClipPDFPage } from '~/components/SharePrivateClipModal/SharePrivateClipPDFPage';
import {
  EnableSharedLinkToggle,
  EnableSharedLinkToggleProps,
} from '~/components/SharePrivateContentModal/EnableSharedLinkToggle';
import { EnableShareLinkDownloadToggle } from '~/components/SharePrivateContentModal/EnableShareLinkDownloadToggle';
import { SharePrivateContentExpirationDateDisplay } from '~/components/SharePrivateContentModal/SharePrivateContentExpirationDateDisplay';
import { isPdfRenderedExtension } from '~/utils/ClipUtils';

interface SharePrivateClipLinkInfoProps {
  shortIdInfo: ShortUrlResponse;
  clip: Pick<Clip, 'id' | 'assetVersionCount' | 'ext'>;
  trackLocation: AirActionTrackingLocation;
  goToSettings: () => void;
  boardContext: string | undefined;
}

export const SharePrivateClipLinkInfo = memo(
  ({ shortIdInfo, clip, trackLocation, goToSettings, boardContext }: SharePrivateClipLinkInfoProps) => {
    const [selectedPage, setSelectedPage] = useState<number | undefined>(undefined);
    const { onShortUrlUpdate, isLoading } = useUpdateShareClipLink({
      clipId: clip.id,
      shortIdInfo,
    });

    const onToggleEnableLink = useCallback(
      (enabled: boolean) =>
        onShortUrlUpdate({
          disabled: !enabled,
        }),
      [onShortUrlUpdate],
    );

    const onDownloadToggle = useCallback<EnableSharedLinkToggleProps['onToggle']>(
      (enabled) =>
        onShortUrlUpdate({
          permissions: {
            asset: {
              canViewOriginal: enabled,
            },
          },
        }),
      [onShortUrlUpdate],
    );

    const urlSearchParams = useMemo(() => {
      if (!selectedPage) return undefined;

      const searchParams = new URLSearchParams();

      searchParams.set('page', selectedPage.toString());

      return searchParams;
    }, [selectedPage]);

    return (
      <div className="flex flex-col gap-3">
        <EnableSharedLinkToggle enabled={!shortIdInfo.disabled} isLoading={isLoading} onToggle={onToggleEnableLink} />
        {!shortIdInfo.disabled && (
          <>
            <EnableShareLinkDownloadToggle
              enabled={shortIdInfo.permissions.asset.canViewOriginal}
              isLoading={isLoading}
              onToggle={onDownloadToggle}
              infoText="This asset or version can be downloaded or saved to other Air workspaces"
            />
            <EditableClipSharePrivateLink
              boardContext={boardContext}
              clip={clip}
              trackLocation={trackLocation}
              shortIdInfo={shortIdInfo}
              searchParams={urlSearchParams}
            />
            {!!shortIdInfo.expirationDate && (
              <SharePrivateContentExpirationDateDisplay
                expirationDate={shortIdInfo.expirationDate}
                goToAdvancedSettings={goToSettings}
              />
            )}
            {isPdfRenderedExtension(clip.ext) && <SharePrivateClipPDFPage onSelectPage={setSelectedPage} />}
          </>
        )}
      </div>
    );
  },
);

SharePrivateClipLinkInfo.displayName = 'SharePrivateClipLinkInfo';
