import { memo } from 'react';

import { BulkEditMultiSelectCustomFieldModal } from '~/components/Modals/BulkEditCustomFieldModal/BulkEditMultiSelectCustomFieldModal';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useGetPrivateCustomFieldOptions } from '~/swr-hooks/customFields/useGetPrivateCustomFieldOptions';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

import { BulkEditCustomFieldModalProps as BulkEditMultiSelectCustomFieldModalProps } from './types';

export type PrivateBulkEditMultiSelectCustomFieldModalProps = Omit<
  BulkEditMultiSelectCustomFieldModalProps,
  'onSave' | 'cfOptions'
>;

export const PrivateBulkEditMultiSelectCustomFieldModal = memo(
  (props: AirModalProps<PrivateBulkEditMultiSelectCustomFieldModalProps>) => {
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();

    const { data: cfOptions } = useGetPrivateCustomFieldOptions({
      customFieldId: props.field.id,
    });

    return (
      <BulkEditMultiSelectCustomFieldModal
        {...props}
        cfOptions={cfOptions}
        onSave={async (params) => {
          await updateItemsCustomFieldValue({
            ...params,
            trackingLocation: 'context-menu-bulk-edit',
            workspaceId,
          });
        }}
      />
    );
  },
);
PrivateBulkEditMultiSelectCustomFieldModal.displayName = 'PrivateBulkEditMultiSelectCustomFieldModal';
