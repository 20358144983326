import classNames from 'classnames';
import React, { memo, MouseEvent as ReactMouseEvent, ReactNode } from 'react';

import { TableCell } from '~/components/TableView/TableCell';
import { getTableColumnWidth } from '~/components/TableView/types';

export interface NameCellContentProps {
  disabled?: boolean;
  title: ReactNode;
  children?: ReactNode;
  image: ReactNode;
  imageSize?: number;
  onContainerClick?: (e: ReactMouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
  'data-testid'?: string;
}

const PREVIEW_SIZE = 36;

/**
 * This component is used to display data in name cell
 */
export const NameCellContent = memo(
  ({
    disabled,
    image,
    title,
    imageSize = PREVIEW_SIZE,
    children,
    onContainerClick,
    'data-testid': dataTestId,
  }: NameCellContentProps) => (
    <TableCell style={{ minWidth: getTableColumnWidth('title') }}>
      <div
        className={classNames('flex items-center', disabled ? 'pointer-events-none' : 'pointer-events-auto')}
        data-testid={dataTestId}
        role="button"
        onClick={onContainerClick}
      >
        <div className="relative" style={{ width: imageSize, height: imageSize, minWidth: imageSize }}>
          <div
            className="relative overflow-hidden rounded-sm p-0 [&>*]:h-full [&>*]:grow [&>img]:w-full [&>img]:object-cover"
            style={{
              width: imageSize,
              height: imageSize,
            }}
          >
            {image}
          </div>
        </div>
        {title}
        {!!children && (
          <div
            className="ml-auto flex shrink-0"
            onClick={(e: ReactMouseEvent) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onDoubleClick={(e: ReactMouseEvent) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div className="w-3" />
            {children}
          </div>
        )}
      </div>
    </TableCell>
  ),
);

NameCellContent.displayName = 'NameCellContent';
