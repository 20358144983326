import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';

import { MouseSelectionContainer, MouseSelectionContainerProps } from '~/components/MouseSelectionContainer';
import { TableView, TableViewProps } from '~/components/TableView/index';
import { AirTableRow } from '~/components/TableView/types';
import { useTableViewItemsPositions } from '~/components/TableView/useTableViewItemsPositions';

export interface SelectableTableViewProps extends Omit<TableViewProps, 'rows'> {
  rows: AirTableRow[];
  horizontalPadding: number;
  scrollElementRef: MouseSelectionContainerProps['scrollElementRef'];
}

export const SelectableTableView = ({ scrollElementRef, horizontalPadding, ...rest }: SelectableTableViewProps) => {
  const { width = 0, ref: widthRef } = useResizeDetector();

  const { rowPositions } = useTableViewItemsPositions({
    tableWidth: width,
    rows: rest.rows,
    horizontalPadding,
  });

  return (
    <div className="relative" ref={widthRef}>
      <MouseSelectionContainer
        containerRef={widthRef}
        itemsPositions={rowPositions}
        scrollElementRef={scrollElementRef}
      >
        <TableView scrollElementRef={scrollElementRef} {...rest} />
      </MouseSelectionContainer>
    </div>
  );
};
