import { Popover } from '@air/primitive-popover';
import { useBreakpointsContext } from '@air/provider-media-query';
import { memo, ReactNode, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MobileFiltersContainer } from '~/components/Filters/FiltersButton/MobileFiltersContainer';
import { FILTERS_DROPDOWN } from '~/constants/testIDs';
import { removeFiltersWithoutSelectionAction } from '~/store/filters/actions';

export interface FiltersPopoverTriggerProps {
  ref: RefObject<HTMLButtonElement>;
  onClick: () => void;
  isActive: boolean;
}

export interface FiltersDropdownTriggerProps {
  renderTrigger: (params: FiltersPopoverTriggerProps) => ReactNode;
  renderFiltersDropdown: (closePopover: { closePopover: () => void }) => ReactNode;
}

export const FiltersDropdownTrigger = memo(({ renderFiltersDropdown, renderTrigger }: FiltersDropdownTriggerProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { isAboveMediumScreen } = useBreakpointsContext();
  const dispatch = useDispatch();

  const toggleIsPopoverOpen = useCallback(async () => {
    setIsPopoverOpen((isOpen) => !isOpen);
  }, []);

  useEffect(() => {
    // TODO: strange bug with html overflow - when lots of filters are applied, html shows vertical scrollbar
    if (isPopoverOpen) {
      document.getElementsByTagName('html').item(0)!.style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('html').item(0)!.style.removeProperty('overflow');
      dispatch(removeFiltersWithoutSelectionAction());
    }
  }, [isPopoverOpen, dispatch]);

  const popoverContent = useMemo(
    () => (
      <div className="flex flex-1 flex-col" data-testid={FILTERS_DROPDOWN}>
        {renderFiltersDropdown({ closePopover: toggleIsPopoverOpen })}
      </div>
    ),
    [renderFiltersDropdown, toggleIsPopoverOpen],
  );

  const triggerProps: FiltersPopoverTriggerProps = useMemo(
    () => ({
      isActive: isPopoverOpen,
      ref: triggerRef,
      onClick: toggleIsPopoverOpen,
    }),
    [isPopoverOpen, toggleIsPopoverOpen],
  );

  return (
    <>
      {!isAboveMediumScreen ? (
        <>
          {renderTrigger(triggerProps)}
          {isPopoverOpen && <MobileFiltersContainer onClose={toggleIsPopoverOpen} content={popoverContent} />}
        </>
      ) : (
        <Popover
          className="flex max-h-[calc(100vh-72px)] w-[100vw-48px] min-w-[400px] max-w-[400px] overflow-hidden p-0 md:max-h-[70vh]"
          onOpenChange={setIsPopoverOpen}
          open={isPopoverOpen}
          trigger={renderTrigger(triggerProps)}
        >
          {popoverContent}
        </Popover>
      )}
    </>
  );
});

FiltersDropdownTrigger.displayName = 'FiltersTrigger';
