import React from 'react';

import TableLoaderRow from '~/components/TableView/TableViewLoaderRow';
import { AirTableRow } from '~/components/TableView/types';

export const TABLE_ROW_GAP = 4;
export const TABLE_ROW_HEIGHT = 56 + TABLE_ROW_GAP;
export const MOBILE_TABLE_ROW_HEIGHT = 72;
export const TABLE_LOADER_ROW_HEIGHT = 200;

export const getLoaderRow = (isLoading: boolean, loadMore: () => void): AirTableRow => ({
  height: TABLE_LOADER_ROW_HEIGHT,
  // eslint-disable-next-line react/display-name
  render: (rowStyle, key, isVisible) => (
    <TableLoaderRow loadMore={loadMore} isLoading={isLoading} isVisible={isVisible} style={rowStyle} key={key} />
  ),
});
