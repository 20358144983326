import { AirActionTrackingLocation, useTrackRegeneratedShareLink } from '@air/analytics';
import { ShortUrl } from '@air/api';
import { Board, Clip } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getShortIdInformationKey } from './useGetExistingShortIdInformation';

export const useRegenerateShortIdForClip = () => {
  const queryClient = useQueryClient();
  const { trackRegeneratedShareLink } = useTrackRegeneratedShareLink();

  const regenerateShortIdForClip = useMutation({
    mutationFn: ({
      ...rest
    }: Parameters<typeof ShortUrl.regenerateShortIdForClip>[0] & {
      clipId: Clip['id'];
      boardContext: Board['id'] | undefined;
      trackLocation: AirActionTrackingLocation;
    }) => ShortUrl.regenerateShortIdForClip(rest),
    onSuccess: (shortIdInfo, { clipId, trackLocation, boardContext }) => {
      queryClient.setQueryData(getShortIdInformationKey({ objectId: clipId, boardContext }), shortIdInfo);

      trackRegeneratedShareLink({ shortId: shortIdInfo.id, location: trackLocation });

      return shortIdInfo;
    },
    onError: (error, params) => {
      throw reportErrorToBugsnag({
        error,
        context: 'Failed to regenerate short id for clip',
        metadata: { data: params },
      });
    },
  });

  return {
    regenerateShortIdForClip,
  };
};
