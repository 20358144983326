import { type Clip } from '@air/api/types';
import { FormikField } from '@air/formik-field';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { useToasts } from '@air/provider-toast';
import { Form, Formik } from 'formik';

import { useUpdateAssetTitle } from '~/swr-hooks/clips/useUpdateAssetTitle';

export type RenameClipsModalProps = {
  clip: Clip;
};

export const RenameClipsModal = ({ clip, onClose }: AirModalProps<RenameClipsModalProps>) => {
  const { updateAssetTitle } = useUpdateAssetTitle();
  const { showToast } = useToasts();

  const currentClipTitle = clip.title ?? clip.importedName;

  return (
    <Modal isOpen onDismiss={onClose}>
      <header className="mb-3 flex items-center justify-between">
        <ModalTitle>Rename asset</ModalTitle>
        <ModalCloseButton className="shrink-0" onClick={onClose} />
      </header>

      <Formik
        initialValues={{
          name: currentClipTitle,
        }}
        onSubmit={({ name }) => {
          updateAssetTitle(clip.id, name, clip.assetId);
          showToast(`Renamed ${currentClipTitle} to ${name}`);
          onClose();
        }}
      >
        <Form>
          <FormikField component={<Input required size="large" />} id="name" label="Asset name" name="name" />

          <div className="-mx-6 -mb-6 flex justify-end gap-3 px-6 py-4">
            <Button appearance="ghost" color="grey" onClick={onClose} size="large">
              Cancel
            </Button>
            <Button appearance="filled" color="blue" size="large" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
