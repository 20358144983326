import { CustomFieldTypeName } from '@air/api/types';
import { parseAirDateToISO } from '@air/utilities';
import { format } from 'date-fns';
import { ReactNode, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CustomFieldListWithOverflow } from '~/components/CustomFields/CustomFieldListWithOverflow';
import { TABLE_CELL_H_PADDING } from '~/components/TableView/ui';
import { TABLE_CUSTOM_FIELD_CELL_BUTTON } from '~/constants/testIDs';
import { visibleCustomFieldsViewOptionsSelector } from '~/store/configViews/selectors';
import { AssignedCustomFieldValue } from '~/swr-hooks/customFields/types';

import { EditableTableCell } from '../../EditableTableCell/EditableTableCell';
import { getTableColumnWidth } from '../../types';

export interface BaseCustomFieldTableCellProps {
  customFieldName: string;
  assignedCustomFieldValue?: AssignedCustomFieldValue;
  renderEditableCustomField?: (closeEditor: () => void) => ReactNode;
}

export const BaseCustomFieldTableCell = ({
  customFieldName,
  assignedCustomFieldValue,
  renderEditableCustomField,
}: BaseCustomFieldTableCellProps) => {
  const visibleCustomFields = useSelector(visibleCustomFieldsViewOptionsSelector);
  const customFields = useMemo(
    () =>
      assignedCustomFieldValue
        ? [assignedCustomFieldValue].filter(({ id }) => visibleCustomFields.find((vcf) => vcf.customFieldId === id))
        : [],
    [assignedCustomFieldValue, visibleCustomFields],
  );

  const shouldRenderValue = useMemo(() => {
    return (
      !!assignedCustomFieldValue?.dateValue ||
      !!assignedCustomFieldValue?.plainTextValue ||
      !!assignedCustomFieldValue?.value ||
      !!assignedCustomFieldValue?.values?.length
    );
  }, [assignedCustomFieldValue]);

  const additionalPopperOffset = useMemo(() => {
    if (!assignedCustomFieldValue) return 0;
    switch (assignedCustomFieldValue?.type.title) {
      case CustomFieldTypeName.plainText: {
        return 4;
      }
    }
    return 0;
  }, [assignedCustomFieldValue]);
  const renderValue = useCallback(
    (toggleEditor: () => void) => {
      if (!assignedCustomFieldValue) return null;
      switch (assignedCustomFieldValue.type.title) {
        case CustomFieldTypeName.singleSelect:
        case CustomFieldTypeName.multiSelect: {
          return (
            <CustomFieldListWithOverflow
              className="p-1"
              onClick={toggleEditor}
              customFields={customFields}
              allowedWidth={getTableColumnWidth('customField') - TABLE_CELL_H_PADDING * 2}
              data-testid={`${TABLE_CUSTOM_FIELD_CELL_BUTTON}-${customFieldName}`}
            />
          );
        }
        case CustomFieldTypeName.plainText: {
          return (
            <button
              className="h-5 w-full text-left"
              data-testid="TABLE_CELL_CUSTOM_FIELD_PLAIN_TEXT"
              onClick={toggleEditor}
            >
              <div className="h-full truncate">{assignedCustomFieldValue.plainTextValue || ''}</div>
            </button>
          );
        }
        case CustomFieldTypeName.date: {
          const dateText = assignedCustomFieldValue.dateValue
            ? format(parseAirDateToISO(assignedCustomFieldValue.dateValue), 'MMM dd, yyyy')
            : '';
          return (
            <button className="h-5 w-full text-left" onClick={toggleEditor}>
              {dateText}
            </button>
          );
        }
      }
    },
    [customFields, customFieldName, assignedCustomFieldValue],
  );
  return (
    <EditableTableCell
      columnWidth={getTableColumnWidth('customField')}
      data-testid={`${TABLE_CUSTOM_FIELD_CELL_BUTTON}-${customFieldName}`}
      renderValue={shouldRenderValue && renderValue}
      renderEditor={renderEditableCustomField}
      additionalPopperOffset={additionalPopperOffset}
    />
  );
};
