import { FiltersTrackingLocation, useTrackFiltersApplied, useTrackFiltersRemoved } from '@air/analytics';
import { keyBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { CustomFieldFilterType, FilterType } from '~/components/FiltersMenu/types';
import { useApplyFilters } from '~/hooks/filters/useApplyFilters';
import { removeFiltersAction } from '~/store/filters/actions';
import { filtersValuesSelector, selectedFiltersSelector } from '~/store/filters/selectors';
import { filterParamsToTrackingType, getUrlParamsFromFilters } from '~/utils/filters/filtersToUrlParamsUtils';
import { useAirDeepEqualSelector, useAirStore } from '~/utils/ReduxUtils';

export const useFiltersDropdownController = ({
  availableFilters,
  doAfterApply,
  customFields = [],
  trackLocation,
}: {
  availableFilters: FilterType[];
  customFields?: CustomFieldFilterType[];
  doAfterApply?: () => void;
  trackLocation: FiltersTrackingLocation;
}) => {
  const dispatch = useDispatch();
  const { applyFilters } = useApplyFilters();
  const store = useAirStore();
  const allSelectedFilters = useAirDeepEqualSelector(selectedFiltersSelector);
  const { trackFiltersRemoved } = useTrackFiltersRemoved();
  const { trackFiltersApplied } = useTrackFiltersApplied();

  const selectedFilters = useMemo(() => {
    const customFieldsById = keyBy(customFields, 'id');
    return allSelectedFilters.filter((f) => {
      if (f.type === 'customField' && f.customField) {
        return !!customFieldsById[f.customField.id];
      } else {
        return availableFilters?.includes(f.type);
      }
    });
  }, [availableFilters, customFields, allSelectedFilters]);

  const applySelectedFilters = useCallback(async () => {
    const filters = filtersValuesSelector(store.getState());
    await applyFilters({ filters });

    const appliedFilters = getUrlParamsFromFilters(filters);
    const appliedTypes = filterParamsToTrackingType(appliedFilters);
    if (appliedTypes.length) {
      trackFiltersApplied({
        types: filterParamsToTrackingType(appliedFilters),
        location: trackLocation,
        builderType: 'filter-builder',
      });
    } else {
      trackFiltersRemoved({
        builderType: 'filter-builder',
        location: trackLocation,
        types: [],
      });
    }

    doAfterApply?.();
  }, [applyFilters, doAfterApply, store, trackLocation, trackFiltersRemoved, trackFiltersApplied]);

  const clearSelectedFilters = useCallback(async () => {
    dispatch(removeFiltersAction({ filters: availableFilters, customFields }));
    await applySelectedFilters();
  }, [applySelectedFilters, availableFilters, customFields, dispatch]);

  return {
    selectedFilters,
    applySelectedFilters,
    clearSelectedFilters,
  };
};
