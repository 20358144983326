import { Board, SearchSavedFiltersResponse } from '@air/api/types';
import { memo } from 'react';

import { BoardSuggestion } from '~/components/SearchBar/Suggestion/BoardSuggestion';
import { SavedFilterSuggestion } from '~/components/SearchBar/Suggestion/SavedFilterSuggestion';
import { SuggestionsSectionHeader } from '~/components/SearchBar/Suggestion/SuggestionsSectionHeader';
import { TextSuggestion } from '~/components/SearchBar/Suggestion/TextSuggestion';
import { BoardSuggestionType } from '~/components/SearchBar/types';

interface AssetsSearchSuggestionsProps {
  textSuggestions?: string[];
  boardSuggestions?: Pick<Board, 'id' | 'title' | 'ancestors'>[];
  onBoardClick: (board: BoardSuggestionType) => void;
  onSavedFilterClick: (savedFilter: SearchSavedFiltersResponse) => void;
  onTextClick: (text: string) => void;
  savedFilterSuggestions?: SearchSavedFiltersResponse[];
  boardsSectionTitle: string;
  savedFiltersSectionTitle: string;
}

export const AssetsSearchSuggestions = memo(
  ({
    textSuggestions = [],
    boardSuggestions = [],
    onBoardClick,
    onTextClick,
    boardsSectionTitle,
    savedFiltersSectionTitle,
    savedFilterSuggestions = [],
    onSavedFilterClick,
  }: AssetsSearchSuggestionsProps) => {
    return (
      <>
        {textSuggestions.map((text, index) => (
          <TextSuggestion index={index} value={text} key={`${text}${index}`} onClick={onTextClick} />
        ))}
        {!!savedFilterSuggestions?.length && (
          <SuggestionsSectionHeader className={textSuggestions.length ? 'mt-6' : 'mt-2'}>
            {savedFiltersSectionTitle}
          </SuggestionsSectionHeader>
        )}
        {savedFilterSuggestions?.map((board, index) => (
          <SavedFilterSuggestion
            key={board.id}
            savedFilter={board}
            onClick={onSavedFilterClick}
            index={index + textSuggestions.length}
          />
        ))}
        {!!boardSuggestions.length && (
          <SuggestionsSectionHeader className={savedFilterSuggestions.length ? 'mt-6' : 'mt-2'}>
            {boardsSectionTitle}
          </SuggestionsSectionHeader>
        )}
        {boardSuggestions?.map((board, index) => (
          <BoardSuggestion
            key={board.id}
            board={board}
            onClick={onBoardClick}
            index={index + textSuggestions.length + savedFilterSuggestions.length}
          />
        ))}
      </>
    );
  },
);

AssetsSearchSuggestions.displayName = 'AssetsSearchSuggestions';
