import { ClipsListResponse, Items, PublicClip, ShortUrl } from '@air/api';
import {
  Board,
  Clip,
  ClipAndBoardListItem,
  ClipAndBoardListOptions,
  ClipType,
  ListResponse,
  PublicBoard,
  SortDirection,
  ViewField,
} from '@air/api/types';

import { PageLimits } from '~/constants/PageLimits';
import { privateClipsFetcher, publicClipsFetcher } from '~/swr-hooks/gallery/galleryView/utils';
import { UseGalleryMixedData } from '~/swr-hooks/gallery/types';

const handleTableViewClipsData = <C extends Clip | PublicClip>({
  pagination,
  data,
}: ClipsListResponse<C>): ListResponse<ClipAndBoardListItem> => ({
  data: data.clips.map((clip) => ({
    id: clip.id,
    type: clip.type === ClipType.nonMedia ? 'file' : 'asset',
    data: clip,
  })),
  total: data.total,
  pagination,
});

export const privateTableViewClipsDataFetcher = async ({
  params,
  sortFieldName,
  sortDirection,
  cursor,
  workspaceId,
}: {
  cursor: string | null;
  params: Partial<ClipAndBoardListOptions>;
  sortFieldName?: ViewField['name'];
  sortDirection?: SortDirection;
  workspaceId: string;
}): Promise<ListResponse<ClipAndBoardListItem>> => {
  // @ts-ignore - mismatched type of sortBy, but ClipAndBoardListSortBy is subset of ClipsListSortBy
  const response = await privateClipsFetcher({ cursor, params, sortFieldName, sortDirection, workspaceId });
  return handleTableViewClipsData(response);
};

export const publicTableViewClipsDataFetcher = async ({
  shortId,
  parentId,
  params,
  sortFieldName,
  sortDirection,
  cursor,
}: {
  cursor: string | null;
  shortId: string;
  parentId: string;
  params: Partial<ClipAndBoardListOptions>;
  sortFieldName: ViewField['name'];
  sortDirection: SortDirection;
}): Promise<ListResponse<ClipAndBoardListItem>> => {
  const response = await publicClipsFetcher({
    shortId,
    parentId,
    // @ts-ignore - mismatched type of sortBy, but ClipAndBoardListSortBy is subset of ClipsListSortBy
    params,
    sortDirection,
    sortFieldName,
    cursor,
  });
  return handleTableViewClipsData(response);
};

export const publicTableViewDataFetcher = async ({
  shortId,
  params,
  sortFieldName,
  sortDirection,
  cursor,
}: {
  cursor?: string;
  shortId: string;
  params: Partial<ClipAndBoardListOptions>;
  sortFieldName?: ViewField['name'];
  sortDirection?: SortDirection;
}): Promise<ListResponse<ClipAndBoardListItem>> => {
  const response = await ShortUrl.searchClipsAndBoardsByShortId(shortId, {
    ...params,
    cursor,
    includeAncestors: true,
    limit: params.limit ? params.limit : !cursor ? PageLimits.files.init : PageLimits.files.more,
    sortField: {
      name: sortFieldName,
      direction: sortDirection || SortDirection.asc,
    },
  });

  return response;
};

export const privateTableViewDataFetcher = async ({
  params,
  sortFieldName,
  sortDirection,
  cursor,
  workspaceId,
}: {
  cursor: string | null;
  params: Partial<ClipAndBoardListOptions>;
  sortFieldName?: ViewField['name'];
  sortDirection?: SortDirection;
  workspaceId: string;
}): Promise<ListResponse<ClipAndBoardListItem>> => {
  const response = await Items.searchClipsAndBoards({
    workspaceId,
    options: {
      ...params,
      cursor,
      includeAncestors: true,
      limit: params.limit ? params.limit : !cursor ? PageLimits.files.init : PageLimits.files.more,
      sortField: {
        name: sortFieldName,
        direction: sortDirection || SortDirection.asc,
      },
    },
  });
  return response;
};

export const tableViewDataToUpdateMediaPlayer = <B extends Board | PublicBoard, C extends Clip | PublicClip>(
  itemsData: UseGalleryMixedData<B, C>,
) => ({
  itemsIds: itemsData.data.reduce(
    (acc, curr) => {
      if (curr.type === 'file' || curr.type === 'asset') {
        const { id, assetId } = curr.item;
        acc.push({ id, assetId });
      }
      return acc;
    },
    [] as { id: Clip['id']; assetId: Clip['assetId'] }[],
  ),
  loadMore: itemsData.loadNextPage,
  hasMore: itemsData.hasMore,
});
