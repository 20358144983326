import { ComponentType, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { BaseFilterCardProps, FilterCard } from '~/components/Filters/FilterCard';
import { FilterType } from '~/components/FiltersMenu/types';
import { BaseStringSelectProps } from '~/components/StringSelect';
import {
  clearStringArrayFilterValuesAction,
  removeFilterAction,
  setStringArrayFilterValuesAction,
} from '~/store/filters/actions';
import { selectedStringArrayFiltersSelector } from '~/store/filters/selectors';
import { SelectedFilter, StringArrayFilterType } from '~/store/filters/types';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface StringArrayFilterCardProps extends BaseFilterCardProps {
  StringSelect: ComponentType<BaseStringSelectProps>;
  filterCardType: FilterType;
  filter: StringArrayFilterType;
}

export const StringArrayFilterCard = memo(
  ({ StringSelect, filter, filterCardType, ...props }: StringArrayFilterCardProps) => {
    const dispatch = useDispatch();

    const clearValues = useCallback(() => {
      dispatch(clearStringArrayFilterValuesAction({ filter }));
    }, [dispatch, filter]);

    const selectedValues = useAirSelector((st) => selectedStringArrayFiltersSelector(st, filter));

    const onSelectedValuesChange = useCallback(
      (values: string[]) => dispatch(setStringArrayFilterValuesAction({ values, filter })),
      [dispatch, filter],
    );

    const onRemoveFilter = (filter: SelectedFilter) => {
      dispatch(removeFilterAction({ filter }));
      clearValues();
    };

    return (
      <FilterCard currentFilter={filterCardType} onClearClick={clearValues} onRemoveFilter={onRemoveFilter} {...props}>
        <StringSelect selectedValues={selectedValues} onSelectionChange={onSelectedValuesChange} />
      </FilterCard>
    );
  },
);

StringArrayFilterCard.displayName = 'StringArrayFilterCard';
