import { memo } from 'react';

import { CustomField } from '~/components/CustomField';
import { KANBAN_COLUMN_HEADER_ITEM_COUNT } from '~/constants/testIDs';

interface KanbanColumnHeaderDisplayProps {
  id: string;
  label: string;
  itemCount?: number;
  color?: string;
}
export const KanbanColumnHeaderDisplay = memo(({ id, label, itemCount, color }: KanbanColumnHeaderDisplayProps) => {
  return (
    <div className="flex h-8 items-center">
      <CustomField
        field={{
          color: {
            name: color || 'transparent',
          },
          id,
          value: label,
        }}
        size="medium"
      />
      <div className="ml-2 text-12 text-grey-8" data-testid={KANBAN_COLUMN_HEADER_ITEM_COUNT}>
        {itemCount}
      </div>
    </div>
  );
});

KanbanColumnHeaderDisplay.displayName = 'KanbanColumnHeaderDisplay';
