import { memo } from 'react';

import { FilterRowWithCheckbox } from '~/components/Filters/FilterRowWithCheckbox';
import { OtherFiltersType } from '~/store/filters/types';

const filtersLabels: { [key in OtherFiltersType]: string } = {
  'has-open-comments': 'Has open comments',
  'has-versions': 'Has versions',
  'is-favorited': 'Favorite assets or files',
  'is-on-boards': 'Is on boards',
  'is-untagged': 'Is untagged',
};

export const defaultOtherFilters: OtherFiltersType[] = [
  'is-favorited',
  'has-versions',
  'has-open-comments',
  'is-on-boards',
  'is-untagged',
];

export interface OtherFiltersProps {
  otherFilters?: OtherFiltersType[];
  onTypeSelectedChange: (filter: OtherFiltersType, isSelected: boolean) => void;
  selectedFilters: OtherFiltersType[];
}

export const OtherFilters = memo(
  ({ otherFilters = defaultOtherFilters, selectedFilters, onTypeSelectedChange }: OtherFiltersProps) => {
    return (
      <div>
        {otherFilters.map((type) => (
          <FilterRowWithCheckbox
            key={type}
            label={filtersLabels[type]}
            isSelected={selectedFilters.includes(type)}
            onChange={(isSelected) => onTypeSelectedChange(type, isSelected)}
          />
        ))}
      </div>
    );
  },
);

OtherFilters.displayName = 'OtherFilters';
