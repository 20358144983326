import { useCallback } from 'react';

import { Select, SelectProps } from '~/components/Select/Select';
import { convertTagToSelectOption, TagBase, TagSelectOption } from '~/components/TagsSelect/utils';
import { TAGS_SELECT } from '~/constants/testIDs';

export interface TagsSelectProps
  extends Omit<SelectProps<TagSelectOption>, 'options' | 'selectedOptions' | 'onSelectionChange' | 'placeholder'> {
  tags: TagBase[];
  selectedTags: TagSelectOption[];
  onTagsSelectionChange: (newTags: TagSelectOption[]) => void;
  placeholder?: string;
}

export const TagsSelect = ({
  tags,
  onTagsSelectionChange,
  selectedTags = [],
  placeholder = 'Search for tags',
  ...props
}: TagsSelectProps) => {
  const options = tags.map((tag) => convertTagToSelectOption(tag));

  const onTagsChange: SelectProps<TagSelectOption>['onSelectionChange'] = useCallback(
    ({ chips }) => {
      onTagsSelectionChange?.(chips);
    },
    [onTagsSelectionChange],
  );

  return (
    <Select
      data-testid={TAGS_SELECT}
      options={options}
      onSelectionChange={onTagsChange}
      selectedOptions={selectedTags}
      className="w-full"
      renderAsInput={true}
      isClearable={true}
      isSearchable={true}
      placeholder={placeholder}
      {...props}
    />
  );
};
