import { useMemo } from 'react';

import { MouseSelectableItem } from '~/components/MouseSelectionContainer';
import { ROW_TOP_OFFSET, TABLE_HEADER_HEIGHT } from '~/components/TableView/index';
import { TABLE_ROW_LEFT_PADDING } from '~/components/TableView/TableRow/DesktopTableRowContainer';
import { AirTableRow } from '~/components/TableView/types';
import { SelectableGalleryItem } from '~/store/selectedItems/types';

export const useTableViewItemsPositions = ({
  rows,
  tableWidth,
  horizontalPadding,
}: {
  rows: AirTableRow[];
  tableWidth: number;
  horizontalPadding: number;
}) => {
  const rowPositions = useMemo((): MouseSelectableItem[] => {
    if (tableWidth) {
      let rowsHeight = TABLE_HEADER_HEIGHT;

      return rows.map((row) => {
        // selectable row does not take all the space - there is vertical padding
        const newRowHeight = row.height - ROW_TOP_OFFSET;
        const itemMetadata = row.getMetadata?.();
        const rowWidth = tableWidth - 2 * horizontalPadding + TABLE_ROW_LEFT_PADDING;

        const newItem: MouseSelectableItem = {
          box: {
            top: rowsHeight,
            left: horizontalPadding - TABLE_ROW_LEFT_PADDING,
            width: rowWidth,
            height: newRowHeight,
          },
          selectableItem:
            itemMetadata?.isSelectable && itemMetadata?.item
              ? ({
                  id: itemMetadata.item.id,
                  type: itemMetadata.type,
                  item: itemMetadata.item,
                } as SelectableGalleryItem)
              : null,
        };

        // add vertical padding to get correct top position for the next row
        rowsHeight += newRowHeight + ROW_TOP_OFFSET;
        return newItem;
      });
    }
    return [];
  }, [horizontalPadding, rows, tableWidth]);

  return {
    rowPositions,
  };
};
