import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { mixedItemToSelectable } from '~/components/TableView/utils';
import { setSelectableGalleryItemsAction } from '~/store/selectedItems/actions';
import { SelectableGalleryItem } from '~/store/selectedItems/types';
import { UseGalleryMixedItem } from '~/swr-hooks/gallery/types';

export const useTableSelectableItems = (selectableData: UseGalleryMixedItem[]) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const selectableItems = selectableData.reduce<SelectableGalleryItem[]>((acc, curr) => {
      acc.push(mixedItemToSelectable(curr));
      return acc;
    }, []);

    dispatch(setSelectableGalleryItemsAction({ selectableItems }));
  }, [selectableData, dispatch]);
};
