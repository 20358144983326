import { useMemo } from 'react';

import { FilterParamNames } from '~/constants/search';
import { useFiltersContext } from '~/providers/FiltersProvider';

/**
 * This hook return true if user applied any filter except for type filter
 * Used to determine if we should show boards - if any filter is applied, boards should be hidden
 * The only exception is if user appliedt "Type" filter
 */
export const useHasAnyClipFilterApplied = () => {
  const { filters } = useFiltersContext();

  const onlyClipFilterNames = useMemo(
    () => Object.keys(filters).filter((value) => value !== FilterParamNames.type),
    [filters],
  );

  return {
    hasAnyClipFilterApplied: onlyClipFilterNames.length > 0,
  };
};
