import { useAirModal } from '@air/provider-modal';

import { EditBoardModal } from '~/components/EditPrivateBoardModal/EditBoardModal/EditBoardModal';

export const useEditBoard = () => {
  const [showEditBoardModal] = useAirModal(EditBoardModal);

  return {
    showEditBoardModal,
  };
};
