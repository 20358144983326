import { CustomFieldValue } from '@air/api/types';

import { DefaultChipType } from '~/components/Select/shared/types';

export interface CustomFieldValueFilterBase {
  id: CustomFieldValue['id'];
  value: CustomFieldValue['value'];
  color: CustomFieldValue['color'];
}

export const convertCustomFieldValueToFilterOption = (fieldValue: CustomFieldValueFilterBase): DefaultChipType => ({
  value: fieldValue.id,
  label: fieldValue.value,
  borderRadius: 4,
  backgroundHexColor: fieldValue.color.backgroundHex,
  fontHexColor: fieldValue.color.fontHex,
});
