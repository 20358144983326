import { FiltersTrackingLocation, useTrackFiltersRemoved } from '@air/analytics';
import React, { memo, ReactNode, useCallback } from 'react';

import { CollapsibleCard } from '~/components/CollapsibleCard';
import { FilterCardFooter } from '~/components/Filters/FilterCardFooter';
import { FilterSelect, FilterSelectProps } from '~/components/FiltersMenu/FilterSelect';
import { CustomFieldFilterType, FilterSelectType, FilterType } from '~/components/FiltersMenu/types';
import { FILTER_CARD } from '~/constants/testIDs';
import { SelectedFilter } from '~/store/filters/types';
import { filterTypeToTrackingType } from '~/store/filters/utils';

export interface BaseFilterCardProps {
  onFilterTypeChange?: FilterSelectType;
  getFiltersOptions: FilterSelectProps['getFiltersOptions'];
  trackLocation?: FiltersTrackingLocation;
}

export interface FilterCardProps extends BaseFilterCardProps {
  onClearClick?: () => void;
  children: ReactNode;
  currentFilter: FilterType;
  currentCustomField?: CustomFieldFilterType;
  testId?: string;
  onRemoveFilter: (filter: SelectedFilter) => void;
}

export const FilterCard = memo(
  ({
    children,
    onClearClick,
    currentFilter,
    onFilterTypeChange,
    currentCustomField,
    getFiltersOptions,
    trackLocation,
    onRemoveFilter,
  }: FilterCardProps) => {
    const { trackFiltersRemoved } = useTrackFiltersRemoved();

    const onFilterDelete = useCallback(() => {
      const filter = { type: currentFilter, customField: currentCustomField };
      onRemoveFilter(filter);

      trackFiltersRemoved({
        types: filterTypeToTrackingType(filter.type),
        location: trackLocation,
        builderType: 'filter-builder',
      });
    }, [currentCustomField, currentFilter, onRemoveFilter, trackLocation, trackFiltersRemoved]);

    return (
      <CollapsibleCard
        testId={
          currentCustomField?.id ? `${FILTER_CARD}-${currentCustomField.name}` : `${FILTER_CARD}-${currentFilter}`
        }
        header={
          <FilterSelect
            getFiltersOptions={getFiltersOptions}
            onFilterSelect={onFilterTypeChange}
            currentFilter={currentFilter}
            currentCustomField={currentCustomField}
          />
        }
        footer={<FilterCardFooter onClearClick={onClearClick} onDeleteClick={onFilterDelete} />}
      >
        <div className="p-1">{children}</div>
      </CollapsibleCard>
    );
  },
);

FilterCard.displayName = 'FilterCard';
