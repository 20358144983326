import { memo } from 'react';

import { SortableHeader } from '~/components/TableView/SortableHeader';
import { getTableColumnWidth } from '~/components/TableView/types';
import { NAME_HEADER } from '~/constants/testIDs';

export const NameHeader = memo(() => (
  <SortableHeader
    title="Name"
    sortingType="name"
    data-testid={NAME_HEADER}
    // add negative margin to align 'name' text in button with image in table row
    className="-ml-4"
    style={{
      minWidth: getTableColumnWidth('title'),
    }}
  />
));

NameHeader.displayName = 'NameHeader';
