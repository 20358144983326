import { useTrackSwitchedSortType } from '@air/analytics';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsSearchActive } from '~/hooks/search/useIsSearchActive';
import { centralizedBoardExistsSelector } from '~/store/centralizedBoard/selectors';
import { setSortingFieldAction } from '~/store/configViews/actions';
import {
  currentSortFieldDirectionSelector,
  currentSortFieldNameSelector,
  sortingConfigViewOptionsSelector,
} from '~/store/configViews/selectors';
import { getDefaultSortDirection } from '~/store/configViews/utils';

import { ViewSortingMenuComponent, ViewSortingMenuComponentProps } from './ViewSortingMenuComponent';

export const ViewSortingMenu = memo(() => {
  const dispatch = useDispatch();
  const isInBoardPage = useSelector(centralizedBoardExistsSelector);
  const { isSearchActive } = useIsSearchActive();
  const { trackSwitchedSortType } = useTrackSwitchedSortType();

  const sortingConfigViewOptions = useSelector(sortingConfigViewOptionsSelector);
  const currentSortFieldName = useSelector(currentSortFieldNameSelector);
  const currentSortFieldDirection = useSelector(currentSortFieldDirectionSelector);

  const availableSortOptions = useMemo(() => {
    const options = sortingConfigViewOptions
      .filter((option) => {
        return option.fieldName !== 'customSort';
      })
      .map((option) => option.fieldName);

    const allowCustomSorting = isInBoardPage && !isSearchActive;

    if (allowCustomSorting) {
      options.push('customSort');
    }

    return options;
  }, [isInBoardPage, isSearchActive, sortingConfigViewOptions]);

  const onSortChange: ViewSortingMenuComponentProps['onChange'] = useCallback(
    (sortingFieldName, direction) => {
      const newDirection =
        sortingFieldName === currentSortFieldName ? direction : getDefaultSortDirection(sortingFieldName);
      dispatch(setSortingFieldAction({ sortingFieldName, direction: newDirection }));
      trackSwitchedSortType({ sortingFieldName, direction: newDirection });
    },
    [currentSortFieldName, dispatch, trackSwitchedSortType],
  );

  if (!availableSortOptions.length) return null;
  const currentlySortedBy = currentSortFieldName || '';

  return (
    <ViewSortingMenuComponent
      sortDirection={currentSortFieldDirection}
      availableSortOptions={availableSortOptions}
      currentlySortedBy={currentlySortedBy}
      onChange={onSortChange}
    />
  );
});

ViewSortingMenu.displayName = 'ViewSortingMenu';
