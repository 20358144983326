import { Clip, ClipStatus } from '@air/api/types';

import { useHandleClipMediaUpdated } from '~/hooks/useHandleClipMediaUpdated';
import { useHandleClipUpdated } from '~/hooks/useHandleClipUpdated';

export interface UseRegisterPublicClipEventsParams {
  clips: Pick<Clip, 'id' | 'status'>[];
  workspaceId: string | undefined;
}

export const useRegisterClipEvents = ({ clips, workspaceId }: UseRegisterPublicClipEventsParams) => {
  /**
   * We only want to listen to clips which haven't been transcoded yet
   */
  const transcodingClips = clips.filter(({ status }) =>
    [ClipStatus.created, ClipStatus.uploaded, ClipStatus.transcoding].includes(status),
  );
  const transcodingClipIds = transcodingClips.map(({ id }) => id);
  const clipId = transcodingClipIds.length > 0 ? new RegExp(transcodingClipIds.join('|')) : undefined;

  useHandleClipUpdated({ clipId, workspaceId });
  useHandleClipMediaUpdated({ clipId, workspaceId });
};
